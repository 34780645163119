import styled from "styled-components";

export const Container = styled.div`
  * {
    font-family: "Poppins", Arial, sans-serif;
    box-sizing: border-box;
  }  
`;

export const Separator = styled.div`
  position: relative;
  background: #4d5ef6;
  padding-bottom: 40px;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -35px;
    width: 100%;
    height: 174px;
    background: url("/assets/banners/separator-stars.webp") no-repeat center;
    background-size: contain;
    z-index: 0;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    border-radius: 64px 64px 0 0;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: #fff;
  }

  .wrapper {
    position: relative;
    width: 100%;
    max-width: 1152px;
    margin: 0 auto;
    padding: 32px 16px;
    overflow: hidden;
    will-change: transform;
    z-index: 1;

    p {
      position: relative;
      color: #fff;
      font-size: 22px;
      line-height: 40px;
      max-width: 454px;
  
      &::after {
        content: "";
        position: absolute;
        top: -64px;
        left: calc(100% + 55px);
        width: 614px;
        height: 400px;
        background: url("/assets/banners/image-1.webp") no-repeat center;
        background-size: contain;
        z-index: 1;
      }
    }
  }

  @media all and (max-width: 991px) {
    padding-bottom: 24px;

    &::after {
      background: url("/assets/banners/separator-stars-mob.webp") no-repeat center;
      height: 100%;
      left: 25px;
      background-size: contain;
      background-position: 0 -26px;
    }

    &::before {
      border-radius: 24px 24px 0 0;
      height: 24px;
    }

    .wrapper {
      background: url("/assets/banners/image-2.webp") no-repeat center right;
      background-size: auto 120%;
      padding-bottom: 16px;
      padding-top: 16px;
  
      p {
        font-size: 16px;
        line-height: 24px;
        max-width: 264px;

        &::after {
          content: none;
        }
      }
    }
  }
`;

export const Main = styled.div`
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 0 16px;
  max-width: 1152px;
  margin: 0 auto 16px;
`;

export const Banner = styled.div`
  width: 100%;
  background: #222;
  position: relative;
  overflow: hidden;
  border-radius: 40px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 535px);
    height: 100%;
    background: url("/assets/banners/banner-img.webp") no-repeat center;
    background-size: cover;
  }

  @media all and (max-width: 991px) {
    &::after {
      top: auto;
      right: 0;
      bottom: 0;
      width: 100%;
      background: url("/assets/banners/banner-img-mob.webp") no-repeat center;
      background-size: cover;
    }
  }
`;

export const BannerContentContainer = styled.div`
  color: #fff;
  width: 535px;
  height: 100%;
  padding: 40px;
  padding-right: 0;
  
  h2 {
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  
  > p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 48px;
  }

  @media all and (max-width: 991px) {
    width: 100%;
    position: relative;
    z-index: 10;
    padding: 16px 16px 248px 16px;

    h2 {
      font-size: 24px;
      line-height: 1.66;
      margin-bottom: 16px;
    }

    > p {
      margin-bottom: 40px;
    }

    a {
      width: 100%;
      height: 48px;
      border-radius: 12px;
    }
  }
`;

export const AnimateWrapper = styled.div`
  /* @keyframes loadingAnimation {
    0% {
      max-height: 0;
      overflow: hidden;
    }
    99.9% {
      max-height: 5000px;
      overflow: hidden;
    }
    100% {
      max-height: max-content;
      overflow: unset;
    }
  }

  &.ready {
    animation: loadingAnimation 1.5s ease-in;
  } */
`;