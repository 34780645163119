module.exports = {
  "strings": {
    "try_another_photo": "別の写真を試す",
    "change_photo": "写真を変更",

    "landing__title_part_1": "ずっと夢見てたトゥーンキャラになりきって楽しもう！",
    "landing__title_part_2": "",
    "landing__subtitle": "写真をAIにおまかせ 😜",
    "landing__select_photo_button": "画像を選ぶ",
    "landing__get_the_app_button": "アプリを入手",
    "loading__uploading": "アップロード中",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "現在、特別試験にご参加いただけます。元のスタイルに戻すには、下からメインバージョンに切り替えてください。",
    "landing__labs_back_button": "ToonMe Classic に戻る",
    "landing__labs_choice": "または<span>ToonMe&nbsp;LABS</span>で実験的スタイルを試す",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "只今新しいスタイルを試験中で、いち早くご覧になれます！ いつでも元のスタイルに戻せます！",
    "result__labs_tab_button": "ぜひお試しください",
    "result__tab_classic_title": "これらの試験的なエフェクトをお気に入りいただけていますか？",
    "result__tab_classic_info": "このままご使用いただくか、ToonMe のメインバージョンに戻すことができます",
    "result__tab_classic_btn": "ToonMe Classic に戻る",
    "result_loader__title": "もうすぐで完成します・・・",

    "processing_text_1": "肌をイエローにしています... 🖌️",
    "processing_text_2": "顔をコミック化しています...",
    "processing_text_3": "まるでディズニープリンセスみたいですね。🙈",
    "processing_text_4": "ここはトゥーンキャラになる夢が叶う場所 🦄",
    "processing_text_5": "写真をトゥーン化中！",
    "processing_text_6": "トゥーン化を実行中です！",
    "processing_text_7": "ウォルト・ディズニーもきっと気にいるはず！✨",

    "collage__select_photo": "写真で試してみましょう",
    "collage__download": "HDでダウンロード",
    "collage__refresh": "更新",
    "more_styles": "その他のスタイル",

    "download_modal__title": "ここで終わりではありません！",
    "download_modal__message": "SNS でシェア。みんなの仲間入り！",
    "download_modal__message_wait": "HDで結果を作成しています…",
    "download_modal__message_ready": "HDバージョンの準備ができました。ダウンロードを開始しています…",
    "download_modal__dismiss_button": "了解",
    "download_modal__download_button": "再度ダウンロードする",

    "error__error": "エラー",

    "internal_error": "エラーが発生しました...",
    "error_overload_webview": "申し訳ありませんが、アプリのダウンロード量が増加しているためサーバーの負荷が増加しています。代わりにフィードタブの使用をお試しいただくか、しばらくしてから再度ご確認ください。現在問題を修正できるよう対応を行っています。",
    "error_overload_web": "申し訳ありませんが、アプリのダウンロード量が増加しているためサーバーの負荷が増加しています。しばらくしてから再度お試しください。現在問題を修正できるよう対応を行っています。",

    "rateapp_dialog__title": "ToonMeをお気に入りいただけましたか？",
    "rateapp_dialog__message-bold": "アプリを星評価して、他のユーザーにすばらしさを伝えましょう！",
    "rateapp_dialog__message": "また、評価していただくことでToonMeが人気になった際に発生したサーバーの問題による低評価スコアを修正することができます。ご協力をお願いいたします。 🙏",
    "rateapp_dialog__rate_button": "評価する！",
    "rateapp_dialog__skip_button": "スキップ",

    "btn_store__title":"<span class=\"btn-store-name\">{{market}}</span><span class=\"btn-store-title\">でダウンロード</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "キャンセルして標準クオリティで保存",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "お問い合わせ",
    "btn_back": "back",

    "vector_tab_refresh__title": "あとちょっと！",
    "vector_tab_refresh__subtitle": "ポートレートを選択",
    "vector_tab_refresh__subtitle2": "髪型を選択",

    "updated_at": "{{date}}に新しい エフェクトをアップデートした",

    "warning_clones": "警告！",
    "warning_clones_link": "安い偽物にご注意ください",

    "snap_dialog__title": "使用前・使用後？またはアニメ版のみ？",
    "snap_dialog__message": "スナップ方法を選択！",
    "snap_dialog__share_button": "シェア",
    "snap_dialog__back_button": "戻る",

    "title_contacts_form": "ダウンロード数 2 万 6 千回で現在も増加中の、世界をリードする iOS・Android 用写真アプリ、Photo Lab の開発者が開発",

    "toggle_watermark_tooltip__text": "ロゴをタップして削除",
    "toggle_watermark_on": "ロゴのオン/オフ",
    "toggle_watermark_off": "ロゴのオン/オフ",

    "remove_logo_modal__title": "そのままにするかしないか…",
    "remove_logo_modal__message": "ロゴがこのエフェクトのデザインの一部だと知ってましたか？そのままにしてもらったらすごくうれしいです。",
    "remove_logo_modal__btn_cancel": "ロゴをそのままにする",
    "remove_logo_modal__btn_remove": "でも削除する",

    "feed_page_title": "最も人気（そしてその他の）エフェクト",
    "feed_page_subtitle": "Toon Effects タブに移動します",
    "feed_page_step1": "フィードをスクロールします",
    "feed_page_step2": "お好きなプレビューをタップします",
    "feed_page_step3": "あなたの写真を選びます",
    "feed_page_btn_part1": "TOON EFFECTS",
    "feed_page_btn_part2": "を使おう！",
    "feed_page_info": "追伸：常に新しい機能をタブに追加しているので、毎日確認するようにしましょう。",

    // v2
    "landing__toonme_on_instagram": "Instagram: #ToonMe",

    "creative_failed_message": "すみません、このTOONで不具合が起きました。🤔 ほかのスタイルを選ぶかもう一度試してください。",
    "creative_failed_retry_btn": "再試行",

    "refresh_body__btn_body": "体型を選択",
    "refresh_body__btn_head": "髪型を選択",
    "button_choice_gender_male": "男性の",
    "button_choice_gender_female": "女性の",
    "button_choice_gender_text": "スタイル",

    "button_original_toggler__show": "オリジナルの写真を見せる",
    "button_original_toggler__hide": "オリジナルの写真を隠す",
  },
};
