module.exports = {
  "strings": {
    "try_another_photo": "कोई और फोटो आज़माएं",

    "landing__title_part_1": "वू हू!",
    "landing__title_part_2": "यह समय, अपने कार्टून सपने को कई बार जीने का है।",
    "landing__subtitle": "बस अपनी तस्वीर एआई को दें 😜",
    "landing__select_photo_button": "फोटो चुनिए ",
    "landing__get_the_app_button": "यह ऐप प्राप्त करें",
    "loading__uploading": "अपलोड हो रहा है",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "अब आप एक ख़ास एक्सपेरिमेंटल जगह पर हैं। असली स्टाइल के लिए नीचे दिए गए मुख्य संस्करण पर वापस जाएं।",
    "landing__labs_back_button": "ToonMe Classic पर वापस जाएं",
    "landing__labs_choice": "या <span>ToonMe&nbsp;LABS</span> पर हमारी प्रयोगात्मक शैलियों को आजमाएं",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "हम नई स्टाइल आजमा कर देख रहे हैं और आप चुपचाप झलक दिखाने के लिए हो! किसी भी क्षण असली स्टाइल पर वापस जाएं।",
    "result__labs_tab_button": "चलिए कोशिश करते हैं",
    "result__tab_classic_title": "एक्सपेरिमेंटल इफ़ेक्ट का आनंद ले रहे हैं?",
    "result__tab_classic_info": "यहीं ठहरें या ToonMe के मुख्य संस्करण पर वापस जाएं",
    "result__tab_classic_btn": "ToonMe Classic पर वापस जाएं",

    "processing_text_1": "आपकी त्वचा को पीला बनाना... 🖌️",
    "processing_text_2": "अपने चेहरे को कार्टून के रूप से प्रकट करना ... ",
    "processing_text_3": "आप एक डिज्नी राजकुमारी की तरह दिखती हैं। मजाक है। या नहीं?.. 🙈",
    "processing_text_4": "यह वह जगह है जहाँ सपने (कार्र)टून हो जाते हैं 🦄",
    "processing_text_5": "अपने फोटो को फ़ाइन-टून करना!",
    "processing_text_6": "टूनीफिकेशन का कार्य प्रगति पर है!",
    "processing_text_7": "वॉल्ट डिज्नी को यह पसंद आयेगा! ✨",

    "collage__select_photo": "अपनी तस्वीर के साथ यह करने का प्रयास करें",
    "collage__download": "एचडी में डाउनलोड करें",
    "collage__refresh": "रीफ्रेश करें",
    "more_styles": "और स्टाइल",

    "download_modal__title": "यहाँ रुकें नहीं!",
    "download_modal__message": "सोशल पर शेयर करें, भीड़ में शामिल हों!",
    "download_modal__message_wait": "एचडी में परिणाम बनाये जा रहे हैं ...",
    "download_modal__message_ready": "एचडी संस्करण तैयार है, डाउनलोड शुरू कर दिया…",
    "download_modal__dismiss_button": "समझ गया",
    "download_modal__download_button": "फिर से डाउनलोड करें",

    "error__error": "त्रुटि",

    "internal_error": "कोई त्रुटि उत्पन्न हुई...",
    "error_overload_webview": "हमें बहुत खेद है, लेकिन बहुत अधिक ऐप डाउनलोड के कारण हमें उच्च सर्वर भार का सामना करना पड़ रहा है। कृपया, इसके बजाय फ़ीड टैब का उपयोग करने का प्रयास करें या फिर थोड़ी देर बाद वापस जांच करें। हम जल्द ही सब कुछ ठीक करने के लिए कड़े प्रयास कर रहे हैं।",
    "error_overload_web": "हमें बहुत खेद है, लेकिन बहुत अधिक ऐप डाउनलोड के कारण हमें उच्च सर्वर भार का सामना करना पड़ रहा है। कृपया, थोड़ी देर बाद फिर से प्रयास करें। हम जल्द ही सब कुछ ठीक करने के लिए कड़े प्रयास कर रहे हैं।",

    "rateapp_dialog__title": "ToonMe पसंद है?",
    "rateapp_dialog__message-bold": "इस ऐप को स्टार्स देकर अन्य प्रयोक्ताओं की इसे ढूँढ़ने और पसंद भी करने में सहायता करें!",
    "rateapp_dialog__message": "PS. इससे हमें, ToonMe के वायरल होने के बाद सामने आई सर्वर संबंधी समस्याओं के कारण प्रभावित हुई, रेटिंग्स फिर से प्राप्त करने में भी सहायता मिलेगी। 🙏",
    "rateapp_dialog__rate_button": "रेट करें!",
    "rateapp_dialog__skip_button": "छोड़ें",

    "btn_store__title":"<span class=\"btn-store-name\">{{market}}</span><span class=\"btn-store-title\">पर डाऊनलोड करें</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "रद्द करें और साधारण गुणवत्ता में सहेजें",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "संपर्क करें",
    "btn_back": "back",

    "vector_tab_refresh__title": "बस पहुंच ही गए!",
    "vector_tab_refresh__subtitle": "कोई पोर्ट्रेट चुनें",
    "vector_tab_refresh__subtitle2": "हेड स्टाइल चुनें",

    "updated_at": "{{date}} को नए इफेक्ट्स के साथ अपडेट किया",

    "warning_clones": "चेतावनी!",
    "warning_clones_link": "घटिया प्रतिरूपों से सावधान रहें",

    "snap_dialog__title": "पहले-बाद का या केवल TOON चुनें?",
    "snap_dialog__message": "चुनें कि स्नैप कैसे करना है!",
    "snap_dialog__share_button": "शेयर",
    "snap_dialog__back_button": "पीछे जाएं",

    "title_contacts_form": "Photo Lab, 260M से अधिक डाउनलोड के साथ दुनिया के अग्रणी iOS और Android फोटोग्राफी ऐप, के डिवेलपर्स की ओर से...",

    "toggle_watermark_tooltip__text": "लोगो हटाने के लिए इसे टैप करें",
    "toggle_watermark_on": "लोगो रखें /हटाए",
    "toggle_watermark_off": "लोगो रखें /हटाए",

    "remove_logo_modal__title": "चयन करें या नहीं...",
    "remove_logo_modal__message": "अरे, क्या आप जानते हैं कि लोगो इस प्रभाव के डिजाइन का एक हिस्सा है? यदि आप इसका चयन करते हैं तो हमें ख़ुशी होगी |",
    "remove_logo_modal__btn_cancel": "लोगो रखें",
    "remove_logo_modal__btn_remove": "लोगो हटाओ",

    // v2
    "landing__toonme_on_instagram": "Instagram पर #ToonMe",

    "creative_failed_message": "उफ़, इस TOON में कुछ गलत हो गया। 🤔 कोई अन्य स्टाइल चुनें या फिर से प्रयास करें।",
    "creative_failed_retry_btn": "पुन: प्रयास करें",

    "refresh_body__btn_body": "बॉडी स्टाइल चुनें",
    "refresh_body__btn_head": "हेड स्टाइल चुनें",
    "button_choice_gender_male": "पुरुष",
    "button_choice_gender_female": "महिला",
    "button_choice_gender_text": "शैलियाँ",

    "button_original_toggler__show": "मूल फोटो दिखाएं",
    "button_original_toggler__hide": "मूल फोटो छिपाएं",
  },
};
