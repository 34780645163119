import React from "react";
import * as SIS from './SkeletonImages.styled'

export const SkeletonImages = ({mb}) => {

  return <SIS.Container mb={mb}>
    {[1,2,3].map((col, index) => (
      <SIS.Column key={index}>
        {[1,2].map((item, i) => {
          return <SIS.ItemLink
            key={item + 100}
            padding={index === 1 && i === 0 ? "100%" : "133%"}
          >
            <div className="line" />
          </SIS.ItemLink>
        })}
      </SIS.Column>
    ))}
  </SIS.Container>
}