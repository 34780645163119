import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import SimpleTab from "./SimpleTab";
import * as photolabApi from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import Processing from "../../photolab/Processing";

class VectorTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isRefreshView: false,
      task: null,
    };
  }

  handleRefreshButtonClick = () => {
    const processing = window.processingManager.processing;
    const task = processing.getExtra(Processing.EXTRA_VECTOR_MEDIATOR_TASK);
    if (task) {
      this.setState({isRefreshView: true, task});
      return
    }

    this.setState({isRefreshView: true});

    const taskConfig = new PhotolabTaskBuilder()
      .setLanguage(window.clientConfig.lang)
      .addMethod(new PhotolabTaskCollageMethod(3955))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .buildToJs();

    photolabApi.photolabAddTask(taskConfig)
      .then((addTaskResult) => photolabApi.photolabWaitTask(addTaskResult.requestId, 2000))
      .then((taskResult) => {
        processing.setExtra(Processing.EXTRA_VECTOR_MEDIATOR_TASK, taskResult);
        this.setState({task: taskResult});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleSelectTemplate = (templateId) => {
    this.setState({
      isRefreshView: false,
      task: null,
    }, () => this.props.onTemplateSelect(templateId));
  };

  render() {
    if (this.state.isRefreshView) {
      if (!this.state.task || this.state.task.status.toLowerCase() === "inprogress") {
        return <div className="creative-holder">
          <div className="creative-holder-placeholder" />
          <div className="loader-video">
            <span className="item-loader first-item-loader" />
            <span className="item-loader second-item-loader" />
            <span className="item-loader third-item-loader" />
          </div>
        </div>;
      }

      if (this.state.task.status.toLowerCase() === "ok") {
        return <div className="choose-page">
          <div className="container">
            {/*<h2>{i18n.t("vector_tab_refresh__title")}</h2>*/}
            <p>{i18n.t("vector_tab_refresh__subtitle2")}</p>
          </div>
          <div className="choose-items">
            {this.state.task.results.map((resultItem) => <div className="choose-item-container" key={resultItem.templateId}>
              <div className="choose-item">
                <img
                  src={resultItem.resultUrl}
                  width={100}
                  alt={resultItem.templateId}
                  onClick={() => this.handleSelectTemplate(resultItem.templateId)} />
              </div>
            </div>)}
          </div>
        </div>;
      }

      return <div>task failed</div>;
    }

    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  }
}

VectorTab.propTypes = {
  group: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  creatives: PropTypes.array.isRequired,
  withHD: PropTypes.bool,
  canBeRefreshed: PropTypes.bool,
  onImageLoaded: PropTypes.func.isRequired,
  onHideWatermarkButtonClick: PropTypes.func.isRequired,
  onShowWatermarkButtonClick: PropTypes.func.isRequired,
  onDownloadButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
};

export default VectorTab;