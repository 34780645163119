import React from "react";
import i18n from "../i18n";
import routes from "../routes";
import {Link} from "react-router-dom";
import {PHOTO_LAB_LINK} from "../utils/constants";
import {webviewOpenBrowser} from "../utils/webview";
import {AppButtons, themeKeys} from "./AppButtons";
import * as Icons from "../ui/svg";

export default class HeaderClassic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
    };
  }

  handleDropdownClick = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <header className="header">
      <div className="header-wrapper">
        <div className="header-main-content">
          <Link to={routes.INDEX} className="logo">toonme.com</Link>
          {/*<ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />*/}
        </div>
        <div className="header-main-content">
          <a href="mailto:contact@toonme.com"
            className="btn-contacts-form"
            dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />

          <AppButtons theme={themeKeys.dark} mob />
        </div>
      </div>
    </header>;
  }
}
