export const watermarkConfigDefaultWeb = {
  url: "/assets/images/watermarks/default-web.png",
  position: "bottom-right",
  x: 3, // %
  y: 2, // %
  percentage: 25,
}

export const watermarkConfigDefaultWebview = {
  url: "/assets/images/watermarks/default-webview.png",
  position: "bottom-right",
  x: 3, // %
  y: 2, // %
  percentage: 25,
}

export const watermarkConfigSubTabsVersionWebview = {
  url: "/assets/images/watermarks/default-webview.png",
  position: "bottom-right",
  x: 3, // %
  y: 6, // %
  percentage: 25,
}

export function canvasWatermark(sourceImage, watermarkImage, watermarkConfig) {
  const canvas = document.createElement("canvas");
  canvas.width = sourceImage.width;
  canvas.height = sourceImage.height;

  const canvasCtx = canvas.getContext("2d");
  canvasCtx.drawImage(sourceImage, 0, 0);

  const watermarkWidth = Math.round(canvas.width / 100 * watermarkConfig.percentage);
  const watermarkHeight = watermarkWidth * watermarkImage.height / watermarkImage.width;

  let watermarkPosX = Math.round(watermarkConfig.x * (canvas.width / 100));
  let watermarkPosY = Math.round(watermarkConfig.y * (canvas.height / 100));

  if (watermarkConfig.position === "bottom-right") {
    watermarkPosX = canvas.width - (watermarkWidth + Math.round(watermarkConfig.x * (canvas.width / 100)));
    watermarkPosY = canvas.height - (watermarkHeight + Math.round(watermarkConfig.y * (canvas.height / 100)));
  }

  canvasCtx.drawImage(watermarkImage, watermarkPosX, watermarkPosY, watermarkWidth, watermarkHeight);

  return canvas;
}

export class CreativeTimeoutError extends Error {
  constructor(message) {
    super();
    this.name = "CreativeTimeoutError";
    this.code = -1;
    this.message = message;
  }
}