import React from "react";
import BodyTabDefault from "../result/BodyTab";
import SimpleTab from "./SimpleTab";
import i18n from "../../i18n";

export default class BodyTab extends BodyTabDefault {

  renderWaiting = () => {
    return <div className="creative-holder-container">
      <div className="creative-holder">
        <div className="creative-holder-placeholder" />
        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
        <div className="square square-big" />
        <div className="square square-med" />
        <div className="square square-small" />
      </div>
    </div>;
  };

  renderResult = () => {
    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  };

  renderHeadChooser = () => {
    if (!this.state.headMediatorTask || this.state.headMediatorTask.status.toLowerCase() === "inprogress") {
      return <div className="creative-holder">
        <div className="creative-holder-placeholder" />
        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
      </div>;
    }

    if (this.state.headMediatorTask.status.toLowerCase() === "ok") {
      const results = this.state.headMediatorTask.results;

      return <div className="choose-page">
        <div className="container">
          {/*<h2>{i18n.t("vector_tab_refresh__title")}</h2>*/}
          <p>{i18n.t("vector_tab_refresh__subtitle2")}</p>
        </div>
        <div className="choose-items">
          {results.map((item) => <HeadItemView
            key={item.templateId}
            templateId={item.templateId}
            imageUrl={item.resultUrl}
            isSelected={this.state.headTemplateId === item.templateId}
            onHeadSelect={this.handleHeadSelectTemplate}
          />)}
        </div>
      </div>;
    }

    return <div>task failed</div>;
  };
}

function HeadItemView({templateId, imageUrl, isSelected, onHeadSelect}) {
  const itemClassNames = ["choose-item"];
  if (isSelected) {
    itemClassNames.push("selected");
  }

  return <div className="choose-item-container">
    <div className={itemClassNames.join(" ")}>
      <img src={imageUrl} alt={templateId} onClick={() => onHeadSelect(templateId)} />
    </div>
  </div>
}
