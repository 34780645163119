import React from "react";
import HeaderClassic from "../../components/HeaderClassic";
import * as HPS from "./HomePage.styled";
import i18n from "../../i18n";
import {AppButtons, themeKeys} from "../../components/AppButtons";
import {Footer} from "../../components/Footer";

export default class HomePage extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {

  }

  render() {
    return <HPS.Container>
      <HeaderClassic hidden={window.clientConfig.isWebview} />
      <HPS.Separator>
        <div className="wrapper">
          <p dangerouslySetInnerHTML={{__html: i18n.t("separator__text")}} />
        </div>
      </HPS.Separator>
      <HPS.Main>
        {this.props.children}
        <HPS.Banner>
          <HPS.BannerContentContainer>
            <h2 dangerouslySetInnerHTML={{__html: i18n.t("banner__title")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("banner__description")}} />
            <AppButtons theme={themeKeys.light} />
          </HPS.BannerContentContainer>
        </HPS.Banner>
      </HPS.Main>
      <Footer />
    </HPS.Container>
  }
}

