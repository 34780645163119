import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import LoadingV2 from "../components/LoadingV2";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";
import clientStorage from "../utils/client-storage";
import {prefixRouteForSite} from "../utils/etc";
import {fileToJson} from "../utils/webview";
import {promisifyImage} from "../utils/image";

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photo: null,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createFile = (file) => {
    if (window.clientConfig.isWebview) {
      const fileEntity = fileToJson(file);

      logEvent(userEvents.WEBVIEW_PHOTO_RECEIVED, {
        file_id: fileEntity.id,
        file_url: fileEntity.url,
      });

      this.handleFileUploaded(fileEntity);
      return;
    }

    // api.createFile(file)
    //   .then(this.handleFileUploaded)
    //   .catch((err) => {
    //     logEvent(userEvents.PHOTO_UPLOAD_FAILED);
    //     console.error(err);
    //     this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
    //   });

    const allowedTypes = [
      "image/jpeg",
      "image/png",
    ];

    if (!allowedTypes.includes(file.type.toLowerCase())) {
      this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site), {
        message: i18n.t("error__image_file_format"),
      });
      return;
    }

    promisifyFileReader(file)
      .then(promisifyImage)
      .then(prepareImage)
      .then((result) => {
        return api.tempImagesUploadFile(result, file.type.replace("image/"));
      })
      .then((fileUrl) => {
        this.handleFileUploaded(fileToJson(fileUrl));
      })
      .catch((error) => {
        this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site), {
          message: error.message,
        });
      });
  };

  handleFileUploaded = (file) => {
    hitEvent(hits.PHOTO_UPLOADED);
    logEvent(userEvents.PHOTO_UPLOADED, {
      file_id: file.id,
      uploaded_at: Date.now(),
    });

    window.processingManager.clear();

    this.redirectToNextPage(file);
  };

  redirectToNextPage = (file) => {
    const processingPath = prefixRouteForSite(generatePath(routes.PROCESSING), this.props.site);

    if (window.clientConfig.isWeb) {
      this.props.history.replace({
        pathname: processingPath,
        state: {file}
      });
      return;
    }

    // const vicmanId = window.clientConfig.queryParams["aid"]
    //   || window.clientConfig.queryParams["vicman_unified_id"];
    // const vicmanIdAsNumber = parseInt(vicmanId.substring(vicmanId.length - 2), 16);

    const isHiringJapan = false
        && window.clientConfig.isWebview
        && (window.clientConfig.locale.toLowerCase().indexOf("jp") >= 0 || window.clientConfig.lang === "ja") /*&& (vicmanIdAsNumber / 255 <= 0.03)*/;

    if (isHiringJapan) {
      clientStorage.incrementHiringJapanAmount();
    }

    if (isHiringJapan && clientStorage.getHiringJapanAmount() === 2) {
      this.props.history.replace(generatePath(routes.HIRING_JAPAN), {nextPath: processingPath});
    } else {
      //this.props.history.replace(processingPath);

      if (window.clientConfig.isWebviewIOS) {
        const processingUrl = new URL(window.location.href);
        processingUrl.pathname = prefixRouteForSite(generatePath(routes.PROCESSING, {hash: Date.now()}), this.props.site);
        processingUrl.searchParams.append("file_url", encodeURIComponent(file.url));

        Object.keys(window.clientConfig.queryParams).forEach((key) => {
          processingUrl.searchParams.append(key, window.clientConfig.queryParams[key]);
        });

        api.getBuildInfo()
          .then((result) => {
            if (parseInt(window.appConfig.build.version) !== parseInt(result.version)) {
              window.location.href = processingUrl.toString();
            } else {
              this.props.history.replace({
                pathname: processingPath,
                state: {file}
              });
            }
          })
          .catch((err) => {
            window.location.href = processingUrl.toString();
          });

        return;
      }

      this.props.history.replace({
        pathname: processingPath,
        state: {file}
      });
    }
  };

  render() {
    return <LoadingV2
      site={this.props.site}
      text={i18n.t("loading__uploading")}
    />;
  }
}

CreatePage.contextType = AppContext;

function promisifyFileReader(input) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = (e) => reject(e.target.error);
    fileReader.readAsDataURL(input);
  });
}

function prepareImage(image) {
  const maxSideSize = 1200;
  const shouldResize = image.width > maxSideSize || image.height > maxSideSize;

  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  if (shouldResize) {
    const ratio = (image.width > image.height)
      ? maxSideSize / image.width
      : maxSideSize / image.height;

    canvas.width = Math.round(image.width * ratio);
    canvas.height = Math.round(image.height * ratio);

    // eslint-disable-next-line valid-typeof
    if (typeof ctx.imageSmoothingQuality !== undefined) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // todo step by step resize to smooth
    }
  } else {
    ctx.drawImage(image, 0, 0);
  }

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 100));
}