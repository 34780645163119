import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import LoadingV2 from "../components/LoadingV2";
import AppContext from "../contexts/AppContext";
import {ApiResponseError} from "../utils/api";
import {fileToJson, webviewPreloadAd, webviewShowAd} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {prefixRouteForSite} from "../utils/etc";
import {
  getCreativesConfigsByGroup,
  getProcessingGroups,
  resolveCreativeConfigByGroup
} from "../photolab/config";
import {creativeGroups, systemGroups} from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import {generatePath} from "react-router";
import * as webviewUtils from "../utils/webview";
import {rolloutDate} from "../photolab/config/config-classic";
import {signalEvent, signals} from "../utils/signals";
import * as processingHelper from "../helpers/processing.helper";

export default class ProcessingPage extends React.Component {

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const fileUrl = (new URL(window.location.href)).searchParams.get("file_url");

    const processing = window.processingManager.restore();
    if (processing && processing.site === this.props.site) {
      window.processingManager.start(processing);
    } else if (fileUrl) {
      const fileEntity = fileToJson(decodeURIComponent(fileUrl));
      this.startProcessing(fileEntity);
    } else if (this.props.location.state && this.props.location.state.file) {
      this.startProcessing(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }

    if (window.clientConfig.isWebview && window.appConfig.nativeAds.isEnabled) {
      const isSkipAd = this.props.location.state && this.props.location.state.skipAd === true;

      const nativeAdsIsSupport = window.clientConfig.isWebviewAndroid
        ? (window.clientConfig.queryParams.version_code >= 153)
        : (window.clientConfig.queryParams.version_code >= 703);

      if (nativeAdsIsSupport && window.clientConfig.isPro === false && !isSkipAd) {
        hitEvent(hits.NATIVE_ADS_SHOW_REQUEST);
        webviewShowAd(this.handleWebviewAdShown, this.handleWebviewAdClosed);
      }
    }

    clientStorage.incrementProcessingsAmount();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  handleWebviewAdShown = (params) => {
    const isShown = parseInt(params) === 1;

    if (isShown) {
      hitEvent(hits.NATIVE_ADS_SHOWN);
      logEvent(userEvents.PROCESSING_ADS_SHOWN, {
        file_id: window.processingManager.processing.file.id,
      });
    }
  };

  handleWebviewAdClosed = () => {
    webviewPreloadAd();
  };

  updateCountProcessingInVariationConfig = () => {
    const [storedTime, storedCount] = clientStorage.getCountProcessingInVariationConfig();
    const processingNumber = Date.now() >= rolloutDate && rolloutDate !== storedTime ? 1 : (storedCount + 1);
    clientStorage.setCountProcessingInVariationConfig(rolloutDate, processingNumber);
  }

  startProcessing = (file) => {
    const groups = getProcessingGroups(this.props.site);

    const processing = new Processing();
    processing.setFile(file);
    processing.setSite(this.props.site);
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setWatermarkName(window.clientConfig.isWeb ? "default_web" : "default_webview");

    let selectedCreativeIsActivated = false;

    processing.groups.forEach((group, index) => {
      const selectedConfig = resolveCreativeConfigByGroup(this.props.site, group);
      if (!selectedConfig) {
        return;
      }

      const selectedCreative = new Creative()
        .configureByConfig(selectedConfig)
        .setAsSelected(true);

      if ([creativeGroups.GENDER, creativeGroups.COMMON, creativeGroups.CROP].indexOf(group) === -1) {
        selectedCreative.setExtra(Creative.EXTRA_KEEP_PENDING, true);
      }

      if (selectedCreative.hasExtra(Creative.EXTRA_KEEP_PENDING)
        && ![creativeGroups.GENDER, creativeGroups.COMMON, creativeGroups.CROP].includes(selectedCreative.group)
        && !selectedCreativeIsActivated
      ) {
        selectedCreativeIsActivated = true;
        selectedCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      }

      processing.addCreative(selectedCreative);

      getCreativesConfigsByGroup(this.props.site, group)
        .filter((c) => c.templateId !== selectedConfig.templateId)
        .forEach((creativeConfig) => {
            const creative = new Creative()
              .configureByConfig(creativeConfig)
              .setAsRefreshed(true)
              .setExtra(Creative.EXTRA_KEEP_PENDING, true);

            processing.addCreative(creative);
        });
    });

    this.updateCountProcessingInVariationConfig();

    window.processingManager.start(processing);
    signalEvent(signals.processingStarted);
  };

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedCreatives = processing.creatives.filter((c) => {
      return c.isSelected
        && c.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true
        && !c.inGroups(systemGroups)
    });

    const processedCreatives = selectedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    const genderCreative = processing.getSelectedCreativeInGroup(creativeGroups.GENDER);
    const genderCreativeIsFinished = genderCreative ? genderCreative.isFinished : true;

    if (processedCreatives.length > 0 && genderCreativeIsFinished) {
      processingHelper.runOnceByExtra(processing, "processing_processed", () => {
        hitEvent(hits.PROCESSING_PROCESSED);
        signalEvent(signals.processingProcessed);

        if (!clientStorage.getHasProcessedProcessing()) {
          clientStorage.setHasProcessedProcessing(true);
          hitEvent(hits.PROCESSING_PROCESSED_FIRST);
        }

        const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

        logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
        logProcessingsTimings(elapsedMs);
      });

      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
        state: {file: processing.file}
      });
    } else if (failedCreatives.length > 0 && failedCreatives.length === selectedCreatives.length) {
      const failedCreative = failedCreatives[0];
      const isPhotolab = failedCreative.error.type === "photolab";

      hitEvent(isPhotolab ? hits.PROCESSING_FAILED_PHOTOLAB : hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (isPhotolab) {
        signalEvent(signals.processingFailedPhotolab);
      } else {
        signalEvent(signals.processingFailed);
      }

      window.processingManager.stop();

      this.props.history.replace({
        pathname: prefixRouteForSite(routes.ERROR, this.props.site),
        state: {
          message: isPhotolab ? failedCreative.error.message : undefined,
          photolabCode: isPhotolab ? failedCreative.error.code : undefined,
        }
      });
    }
  };

  fetchFile = (fileId) => {
    const params = {
      site: this.props.site
    };

    api.fetchFile(fileId, params)
      .then(this.startProcessing)
      .catch((err) => {
        console.error(err);

        if (err instanceof ApiResponseError) {
          console.error(err);
          this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
        } else {
          this.fetchTimer = setTimeout(() => this.fetchFile(fileId), 2000);
        }
      });
  };

  render() {
    return <LoadingV2 withAds withProcessingText site={this.props.site} />;
  }
}

ProcessingPage.contextType = AppContext;