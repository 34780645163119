import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 40px;
  gap: 8px;

  @media all and (max-width: 991px) {
    margin-bottom: 24px;
  }
`;

export const PreviewStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1;

  @media(max-width: 540px) {
    gap: 16px;
  }

  @media(max-width: 380px) {
    flex-direction: column;
  }
`;

export const PreviewImageLink = styled(Link)`
  position: relative;
  width: 100%;
  max-width: 136px;
  min-width: 88px;
  background: #222;
  border-radius: 24px;
  overflow: hidden;
  will-change: transform;

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media(max-width: 991px) {
    border-radius: 16px;
  }
`;

export const PreviewUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .reposts_counter {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.5;
    color: #999;

    @media(max-width: 540px) {
      font-size: 16px;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UserPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media all and (max-width: 991px) {
    gap: 8px;
  }
`;

export const UserAvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  will-change: transform;
  border-radius: 50%;
  background: #222;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media all and (max-width: 991px) {
    width: 24px;
    height: 24px;
  }
`;

export const UserProfileItem = styled(Link)`
    display: flex;
    align-items: center;
    gap: 16px;
    background: none;
    transition: 0.3s;
    color: #222;

    p {
      font-size: 24px;
      line-height: 40px;
      font-weight: 700;
      text-align: left;
    }

    svg {
      flex-shrink: 0;
    }

    @media all and (min-width: 992px) {
      &:hover {
        ${props => props.as === Link && `
          color: #4d5ef6;

          svg path {
            fill: #4d5ef6;
            stroke: #4d5ef6;
          }
        `}
      }
    }

    @media all and (max-width: 991px) {
      gap: 8px;

      svg {
        width: 8px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
`;

export const TooltipText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #999;
  
  > span {
    color: #222;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media all and (max-width: 991px) {
    font-size: 10px;
  }
`;