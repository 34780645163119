import promiseRetry from "promise-retry";
import {hitEvent, hits, logEvent} from "./log";
import axios from "axios";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

const defaultRetriesConfig = {
  retries: 3,
  minTimeout: 1000,
  maxTimeout: 3000
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

export function checkResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

function paramsToStr(params = {}) {
  params.r = Math.random();

  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
}

function logRequestRetry(params) {
  logEvent("api_request_retry", params).catch(console.error);
}

function hitRequestFail(err, hitId) {
  hitEvent(hitId);
  throw err;
}

export function createFile(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  function requestFunc(retry, attempt) {
    if (attempt > 1) {
      logRequestRetry({type: "createFile", attempt: attempt - 1});
      hitEvent(hits.API_REQUEST_CREATE_FILE_ATTEMPT);
    }

    return window.axios.post(window.appConfig.paths.apiUpload + "/files/create", formData, {
      headers: {"Content-Type": "multipart/form-data"}
    }).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .catch((err) => hitRequestFail(err, hits.API_REQUEST_CREATE_FILE_FAIL))
    .then(checkResponse);
}

export function fetchFile(id, params = {}) {
  function requestFunc(retry, attempt) {
    if (attempt > 1) {
      logRequestRetry({type: "fetchFile", attempt: attempt - 1});
      hitEvent(hits.API_REQUEST_FETCH_FILE_ATTEMPT);
    }

    return window.axios.get(window.appConfig.paths.api + "/files/" + id + "?" + paramsToStr(params)).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .catch((err) => hitRequestFail(err, hits.API_REQUEST_FETCH_FILE_FAIL))
    .then(checkResponse);
}

export function contactUs(name, email) {
  return window.axios.post(window.appConfig.paths.api + "/contact/save", {name, email})
      .then(checkResponse);
}

export function createTask(creative, taskName, type, params) {
  const createTaskRequestAt = Date.now();

  window.creativesLog.log(creative.id, {
    templateId: taskName,
    taskName: taskName,
    event: "addtask_start",
    at: createTaskRequestAt,
  });

  function requestFunc(retry, attempt) {
    if (attempt > 1) {
      logRequestRetry({type: "createTask", attempt: attempt - 1});
      hitEvent(hits.API_REQUEST_CREATE_TASK_ATTEMPT);
    }

    return window.axios.post(window.appConfig.paths.commonBackendApi + "/tasks/create", {type, params}).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .catch((err) => hitRequestFail(err, hits.API_REQUEST_CREATE_TASK_FAIL))
    .then(checkResponse)
    .then((res) => {
      window.creativesLog.log(creative.id, {
        templateId: taskName,
        taskName: taskName,
        event: "addtask_finish",
      });

      res.createTaskRequestAt = createTaskRequestAt;

      return res;
    })
}

export function fetchTask(taskId) {
  function requestFunc(retry, attempt) {
    if (attempt > 1) {
      logRequestRetry({type: "fetchTask", attempt: attempt - 1});
      hitEvent(hits.API_REQUEST_FETCH_TASK_ATTEMPT);
    }

    return window.axios.get(window.appConfig.paths.commonBackendApi + "/tasks/" + taskId).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .catch((err) => hitRequestFail(err, hits.API_REQUEST_FETCH_TASK_FAIL))
    .then(checkResponse);
}

export function fetchTasks(tasksIds) {
  function requestFunc(retry, attempt) {
    if (attempt > 1) {
      logRequestRetry({type: "fetchTasks", attempt: attempt - 1});
      hitEvent(hits.API_REQUEST_FETCH_TASK_ATTEMPT);
    }

    return window.axios.get(window.appConfig.paths.commonBackendApi + "/tasks/batch", {
      params: {
        ids: tasksIds,
      }
    }).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .catch((err) => hitRequestFail(err, hits.API_REQUEST_FETCH_TASK_FAIL))
    .then(checkResponse);
}

export function tempImagesUploadFile(blob, type) {
  const formData = new FormData();
  formData.append("image", blob, "image." + type);

  return axios({
    method: "POST",
    url: window.appConfig.paths.tempImagesUploadEndpoint,
    data: formData,
  }).then((res) => {
    if (/^https?:\/\//i.test(res.data)) {
      return res.data.replace(/^http:/i, "https:");
    } else {
      throw new Error("Image upload contains an unexpected response");
    }
  });
}

// --

export function getBuildInfo() {
  return window.axios.get("/build.json")
    .then(checkResponse);
}

export function storeFile(blob, fileName) {
  return axios({
    method: "PUT",
    url: window.appConfig.paths.proxy + "/upload/toonme/creatives/" + fileName,
    headers: {
      "Content-Type": "image/jpeg",
    },
    data: blob
  });
}
