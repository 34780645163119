import React from "react";
import * as MHS from "./MainHeader.styled";
import * as Icons from "../../ui/svg"
import i18n from "../../i18n";
import {ShareButton} from "../ShareButton";
import {generatePath} from "react-router";
import routes from "../../routes";
import {Link} from "react-router-dom";

export const MainHeader = ({user, ending, isShowPreview, combo, isHeader}) => {
  const tooltipText = isShowPreview ? "<span>Original toons</span>" : ending ? "Toons" : "Toon";

  return <MHS.Container>
    <MHS.PreviewStyle>
      {isShowPreview && <MHS.PreviewImageLink to={generatePath(routes.COMBO_PAGE, {id: combo.id})}>
        <img src={combo.result_url} alt="toonme" />
      </MHS.PreviewImageLink>}

      <MHS.PreviewUserInfoContainer>
        {isShowPreview && <p className="reposts_counter" dangerouslySetInnerHTML={{__html: i18n.t("main_header_reposts__count", {count: combo.amount_children.toLocaleString()})}} />}

        <MHS.UserInfo>
          <MHS.TooltipText dangerouslySetInnerHTML={{__html: i18n.t( "main_header_tooltip__text", {tooltipText: tooltipText})}} />
          <MHS.UserPreview>
            <MHS.UserAvatarContainer>
              <img src={user.profile_picture} alt="toonme" />
            </MHS.UserAvatarContainer>
            <MHS.UserProfileItem
              as={isHeader ? "div": Link}
              to={generatePath(routes.USER_PAGE, {id: user.uid})}
            >
              <p>{user.name}</p>
              <Icons.SvgRightArrow />
            </MHS.UserProfileItem>
          </MHS.UserPreview>
        </MHS.UserInfo>

      </MHS.PreviewUserInfoContainer>
    </MHS.PreviewStyle>

    <ShareButton />
  </MHS.Container>
}