export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);

  return window.appDebounceTimers[name];
}

export function assetUrl(path, withSuffix = true) {
  path = path.replace(/^\/+/g, "");
  const hasQuestionMark = path.indexOf("?") > -1;
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ((hasQuestionMark ? "&" : "?") + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.assets}/${path}${withSuffix ? suffix : ""}`;
}

export function pwAssetUrl(path) {
  return `${window.appConfig.paths.pwAssets}/${path}`;
}

export function prefixRouteForSite(route, site) {
  return (site && site !== "classic") ? ("/" + site + route) : route;
}

export function when(key, map) {
  return map.hasOwnProperty(key) ? map[key] : undefined;
}