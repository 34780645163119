import React from "react";
import AppContext from "../contexts/AppContext";
import HeaderClassic from "../components/HeaderClassic";
import {assetUrl} from "../utils/etc";
import routes from "../routes";
import {webviewOpenBrowser} from "../utils/webview";

const buttonLink = "https://tooncoin.com/?utm_source=toonme";

export default class ToonCoinPage extends React.Component {

  handleButtonClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(buttonLink);
    }
  };

  render() {
    return <>
      <main className="main-page">
        <HeaderClassic hidden={window.clientConfig.isWebview} />
        <HomeButtonView hidden={window.clientConfig.isWeb} onClick={() => this.props.history.push(routes.INDEX)} />
        <div className="container">
          <div className="tooncoin">
            <div className="tooncoin__text">
              <h2 className="tooncoin__title">Something crypto is&nbsp;upon us<span>!</span></h2>
              <p className="tooncoin__subtitle">Get ready to meet
                new <span>ToOnCoin</span> cryptocurrency that is gonna make the
                world a better place.</p>
              <p className="tooncoin__paragraph">And while we are getting more
                and more excited about fantastic collaborations to come, feel
                free to take a look at what this meme coin is about.</p>
              <div className="tooncoin__wrapper">
                {/*<img className="tooncoin__mob-image" src={assetUrl("assets/img/tooncoin/tooncoin-image-mob.png")} alt="" />*/}
                <a className="tooncoin__button"
                   href={buttonLink}
                   target="_blank"
                   rel="noopener noreferrer"
                   onClick={this.handleButtonClick}>Learn more about <span>toOn</span>Coin</a>
              </div>
            </div>
            <img className="tooncoin__image" src={assetUrl("assets/img/tooncoin/tooncoin-image.png")} alt="" />
          </div>
        </div>
      </main>
    </>;
  }
}

ToonCoinPage.contextType = AppContext;

function HomeButtonView({hidden, onClick}) {
  if (hidden) {
    return null;
  }

  return <button className="tooncoin__button_back" onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path fill="#3E4552" stroke="#3E4552" strokeWidth=".3" d="M3.926 8.551l7.333 6.875c.319.299.83.299 1.148 0 .324-.303.324-.799 0-1.102L5.662 8l6.745-6.324c.324-.303.324-.799 0-1.102-.16-.15-.368-.224-.574-.224-.206 0-.414.074-.574.224L3.926 7.449c-.324.303-.324.799 0 1.102z"/>
    </svg>
    home
  </button>;
}