import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const Button = styled.button`
  &.button-load-more {
    box-sizing: border-box;
    display: ${props => props.hidden ? "none": "flex"};
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0 24px;
    background: #4d5ef6;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #fff;
    transition: 0.3s;

    @media all and (min-width: 992px) {
      &:hover {
        background: #0e1da6;
      }
    }
      
    p {
      text-transform: uppercase;
    }

    span {
      display: none;
      width: 40px;
      height: 40px;
      border: solid 2px rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      position: relative;
      animation: spinning 1s 0.25s linear infinite;

      &::after {
        content: "";
        display: block;
        border: solid 2px transparent;
        border-top-color: #fff;
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    @keyframes spinning {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @media all and (max-width: 560px) {
      width: 100%;
        height: 48px;
    }

    ${props => props.animation && `
      width: 56px;
      border-radius: 50%;
      padding: 0;
      pointer-events: none;

      p {
        display: none;
      }

      span {
        display: flex;
      }

      @media all and (max-width: 560px) {
        width: 56px;
      }
    `}
  }
`;