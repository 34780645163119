import React from "react";
import i18n from "../../i18n";
import * as LMS from "./LoadMoreButton.styled"

export const LoadMoreButton = ({animation, onClick, hidden}) => {
  return <LMS.Container>
    <LMS.Button
      animation={animation}
      className="button-load-more"
      onClick={onClick}
      hidden={hidden}
    >
      <span />
      <p dangerouslySetInnerHTML={{__html: i18n.t("button_load_more")}} />
    </LMS.Button>
  </LMS.Container>
}