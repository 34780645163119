import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import SimpleTabDefault from "../result/SimpleTab";
import CreativeView from "../result/CreativeView";
import clientStorage from "../../utils/client-storage";
import {SvgSprite} from "../../components/SvgSprite";
import * as creativeUtils from "../../utils/creative";
import FileChooseButton from "../../components/FileChooseButton";
import {getCurrentLocale} from "../../i18n";
import Creative from "../../photolab/Creative";
import {pwAssetUrl} from "../../utils/etc";
import {creativeGroups} from "../../photolab/config/groups";

class SimpleTab extends SimpleTabDefault {
  render() {
    const {creatives} = this.props;
    const canBeRefreshed = !!this.props.canBeRefreshed;
    const groupCreatives = creatives.filter((creative) => creative.group === this.props.group);
    const mainCreative = groupCreatives.first();

    const creative = groupCreatives.find((creative) => creative.is_selected);
    const creativeIsProcessing = !creative || creativeUtils.creativeIsProcessing(creative);
    const creativeIsFailed = creative && creativeUtils.creativeIsFailed(creative);
    // const creativeSubName = creative && creative._.getExtra(Creative.EXTRA_TEMPLATE_SUBNAME);

    const mainCreativeIsProcessing = creativeUtils.creativeIsProcessing(mainCreative)

    const previewCreative = groupCreatives.find((creative) => !creative.is_selected);
    const previewCreativeIsProcessed = previewCreative && creativeUtils.creativeIsProcessed(previewCreative);

    const isNarrow = creative
      && creative.file
      && creative.file.height > creative.file.width;

    const lang = getCurrentLocale().substring(0, 2).toLowerCase();

    const refreshButtonText = i18n.t("more_styles");
    const isVisibleBeforePhotoToggler = this.getOriginalPhotoTogglerIsVisible(creative);

    const isPhotolabError = creativeIsFailed && creative._.error.type === "photolab";

    let showOriginalButtonTextKey = "button_original_toggler__show";
    let hideOriginalButtonTextKey = "button_original_toggler__hide";
    if (creative._.getExtra("layout_type") === "toon") {
      showOriginalButtonTextKey = "button_toon_toggler__show";
      hideOriginalButtonTextKey = "button_toon_toggler__hide";
    }

    const versionWithSubTabs = window.clientConfig.features.showSubTabs;

    return <div className="creative-holder-container">
      <div className='creative-holder-flex'>
        <div className={`creative-holder ${isNarrow ? "creative-holder--narrow" : ""} ${versionWithSubTabs ? "creative-holder--subtabs" : ""}`}>
          {creativeIsProcessing && <div className="creative-holder-placeholder" />}

          {previewCreativeIsProcessed && <div className="wait-video">
            <img src={previewCreative.file.url} alt="Creative" />
          </div>}

          {creative && creative.file.url && <React.Fragment>
            <CreativeView
              creative={creative}
              onLoaded={this.props.onImageLoaded}
              onClick={this.props.onCreativeClick} />
            {window.clientConfig.isPro && <React.Fragment>
              <div className="watermark-container">
                <div hidden={clientStorage.getProWatermarkTooltipIsHidden()}>
                  <div className="tooltip">
                    <p dangerouslySetInnerHTML={{__html: i18n.t("toggle_watermark_tooltip__text")}} />
                  </div>
                  <div className="btn-remove-logo" hidden={clientStorage.getProWatermarkShouldBeRemoved()} onClick={this.props.onHideWatermarkButtonClick} />
                </div>
                <button
                  className={`btn-logo-on ${groupCreatives.length > 1 ? '' : 'without-tabs'}`}
                  hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
                  onClick={this.props.onShowWatermarkButtonClick}>
                  <span children={i18n.t("toggle_watermark_on")} />
                </button>
                <button
                  className={`btn-logo-on ${groupCreatives.length > 1 ? '' : 'without-tabs'}`}
                  hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
                  onClick={this.props.onHideWatermarkButtonClick}>
                  <span children={i18n.t("toggle_watermark_off")} />
                </button>
              </div>
              <div className="watermark-desktop-container">
                <button
                  className="btn-logo-on"
                  hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
                  onClick={this.props.onShowWatermarkButtonClick}>
                  <SvgSprite viewBox="0 0 32 32" icon="icon-arrow-logo-on" />
                  <span children={i18n.t("toggle_watermark_on_desktop")} />
                </button>
                <button
                  className="btn-logo-off"
                  hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
                  onClick={this.props.onHideWatermarkButtonClick}>
                  <SvgSprite viewBox="0 0 32 32" icon="icon-arrow-logo-off" />
                  <span children={i18n.t("toggle_watermark_off_desktop")} />
                </button>
              </div>
            </React.Fragment>}

            {/* <div className={"watermark-container watermark-container-ja " + (versionWithSubTabs ? "watermark-container-subtabs" : "")} hidden={isVisibleBeforePhotoToggler}>
              <button
                className="btn-logo-on"
                hidden={!creative.extra.creative_layout_is_hidden}
                onClick={this.props.onShowBeforePhotoButtonClick}>
                <span children={i18n.t(showOriginalButtonTextKey)} />
              </button>
              <button
                className="btn-logo-on"
                hidden={creative.extra.creative_layout_is_hidden}
                onClick={this.props.onHideBeforePhotoButtonClick}>
                <span children={i18n.t(hideOriginalButtonTextKey)} />
              </button>
            </div> */}

            <button
              className="btn-upload-foto-result"
              onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
              <SvgSprite className="download-icon" viewBox="0 0 228 228" icon={`btn-download-lang-${lang}`} />
              <div className="icon-container">
                <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
              </div>
            </button>
            {!versionWithSubTabs && <button
              hidden={!canBeRefreshed}
              className="btn-refresh-foto-desktop btn-refresh-foto-desktop-v1"
              onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
              <SvgSprite viewBox="0 0 24 24" icon="icon-refresh" />
              <span children={refreshButtonText} />
            </button>}
          </React.Fragment>}

          {creative && creativeIsFailed && !isPhotolabError && <div className="creative-error-message-container">
            <div className="creative-error-message">
              <p>{i18n.t("creative_failed_message")}</p>

              <button
                className="btn-retry"
                onClick={() => this.props.onRetryButtonClick(creative)}>
                {i18n.t("creative_failed_retry_btn")}
              </button>
            </div>
          </div>}

          {creative && creativeIsFailed && isPhotolabError && <div className="creative-error-message-container">
            <div className="creative-error-message">
              <p>{i18n.t("creative_failed_message")}</p>
            </div>
          </div>}

          <div className="loader-roller-wrapper">
            <div className="loader-roller loader-roller1">
              <div className="roller" />
              <div className="roller" />
            </div>
            <div className="loader-roller loader-roller2">
              <div className="roller" />
              <div className="roller" />
            </div>
            <div className="loader-roller loader-roller3">
              <div className="roller" />
              <div className="roller" />
            </div>
          </div>
          <div className="square square-big" />
          <div className="square square-med" />
          <div className="square square-small" />
          {versionWithSubTabs && canBeRefreshed && !mainCreativeIsProcessing && this.props.group !== creativeGroups.CARTOON_VECTOR_BODY &&
            <div className="subtabs-container">
              <div className="subtabs">
                {groupCreatives.map((c, i) => <SubTabView
                  key={i}
                  creative={c}
                  onClick={() => this.props.onSubTabButtonClick(this.props.group, c)} />)}
              </div>
            </div>}
        </div>
      </div>

      {versionWithSubTabs && <div className={`btns-container btns-container-subtabs ${!canBeRefreshed ? "btns-container-subtabs-empty" : ""} ${window.clientConfig.isPro ? "isPro" : ""}`}>
        <FileChooseButton
          style={{visibility: (creativeIsProcessing ? "hidden" : "visible")}}
          onFileSelected={this.props.onFileSelected}
          className="btn-update btn-update-big">
          <SvgSprite viewBox="0 0 24 24" icon="icon-magic" />
          <span children={i18n.t("change_photo")} />
        </FileChooseButton>
        <button
          style={{visibility: (creativeIsProcessing || creativeIsFailed ? "hidden" : "visible")}}
          className="btn-upload-foto"
          children={i18n.t("collage__download")}
          onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
          <div className="icon-container">
            <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
          </div>
          <span children={i18n.t("collage__download")} />
        </button>
      </div>}

      {!versionWithSubTabs && <div className="btns-container">
        <FileChooseButton
          style={{visibility: (creativeIsProcessing ? "hidden" : "visible")}}
          onFileSelected={this.props.onFileSelected}
          className="btn-update btn-update-big">
          <SvgSprite viewBox="0 0 24 24" icon="icon-magic" />
          <span children={i18n.t("try_another_photo")} />
        </FileChooseButton>
        <button
          style={{visibility: (creativeIsProcessing || creativeIsFailed ? "hidden" : "visible")}}
          className="btn-refresh-foto-desktop btn-refresh-foto-desktop-v2"
          disabled={!canBeRefreshed}
          onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
          <SvgSprite viewBox="0 0 24 24" icon="icon-refresh" />
          <span children={refreshButtonText} />
        </button>
        <button
          style={{visibility: (creativeIsProcessing || creativeIsFailed ? "hidden" : "visible")}}
          className="btn-upload-foto"
          children={i18n.t("collage__download")}
          onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
          <div className="icon-container">
            <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
          </div>
          <span children={i18n.t("collage__download")} />
        </button>
        {canBeRefreshed ? <button
          style={{visibility: (creativeIsProcessing || creativeIsFailed ? "hidden" : "visible")}}
          className="btn-update btn-refresh-foto"
          onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
          <SvgSprite viewBox="0 0 24 24" icon="icon-refresh-v2" />
          <span children={refreshButtonText} />
        </button> : <div className="btn-spacer" />}
        <SvgSprite className="line-result-mob" viewBox="0 0 414 390" icon="line-result-mob" />
      </div>}
    </div>;
  }
}

SimpleTab.propTypes = {
  ...SimpleTab.propTypes,
  onFileSelected: PropTypes.func.isRequired,
};

export default SimpleTab;

class SubTabView extends React.Component {

  render() {
    const creative = this.props.creative;
    const isNewCreative = false;

    const classNames = ["subtab"];

    creative && creative._.isSelected && classNames.push("active");
    this.props.className && classNames.push(this.props.className);

    const style = {};

    let preview = undefined;

    if (creative && creativeUtils.creativeIsProcessed(creative)) {
      preview = creative._.getFile("template") || creative._.getFile("result");
    }
    else if (creative && creative._.hasExtra(Creative.EXTRA_PREVIEW_URL)) {
      preview = creative._.getExtra(Creative.EXTRA_PREVIEW_URL);
    }
    else if (creative && creative._.hasExtra(Creative.EXTRA_COMBO_STEPS)) {
      let steps = creative._.getExtra(Creative.EXTRA_COMBO_STEPS).map((s) => s.id).join("_");

      // 1 - это https://assets.photo-cdn.net/previews/source/1.jpg
      preview = pwAssetUrl(`previews/256x256/1/${steps}.jpg`);
    }

    if (preview) {
      style.backgroundImage = `url(${preview})`;
    }

    return <button
      className={classNames.join(" ")}
      style={style}
      onClick={this.props.onClick}>
      {isNewCreative && <span className="label" dangerouslySetInnerHTML={{__html: i18n.t("label_new")}} />}
    </button>;
  }
}