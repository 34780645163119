import React from 'react';
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {webviewOpenBrowser} from "../utils/webview";
import FeedBannerView from "./FeedBannerView";

export default class FeedBannerPage extends React.Component {

  componentDidMount() {
    this.webviewTabSelected$ = window.webviewEventsListeners.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.PAGE_FEED_BANNER);
        logEvent(userEvents.PAGE_FEED_BANNER);
      }
    }, true);
  }

  componentWillUnmount() {
    this.webviewTabSelected$ && this.webviewTabSelected$.unsubscribe();
  }

  handleGoToFeedClick =() => {
    hitEvent(hits.GO_TO_FEED_CLICK);
    logEvent(userEvents.GO_TO_FEED_CLICK);

    const deeplink = "toonme://navigate/tab?id=1000";

    if (window.clientConfig.isWebviewIOS) {
      webviewOpenBrowser(deeplink);
    } else {
      window.location.href = deeplink;
    }
  };

  render() {
    return <FeedBannerView handleGoToFeedClick={this.handleGoToFeedClick} />;
  }
}

FeedBannerPage.contextType = AppContext;
