import configClassic from "./config-classic";
import configLabs from "./config-labs";

export function getProcessingConfig(site) {
  const config = site === "labs"
    ? configLabs
    : configClassic;

  return config;
}

export function getProcessingGroups(site) {
  return getProcessingConfig(site).getGroups();
}

export function getCreativesConfigs(site) {
  return getProcessingConfig(site).getCreatives();
}

export function getCreativesConfigsByGroup(site, group) {
  return getCreativesConfigs(site).filter((config) => config.group === group);
}

export function resolveCreativeConfigByGroup(site, group) {
  const configs = getCreativesConfigsByGroup(site, group);
  let selectedConfig = configs.find((config) => config.isSelected);
  if (!selectedConfig) {
    const sum = configs.reduce((value, config) => value + config.chanceToSelect, 0);
    if (sum === 0) {
      selectedConfig = configs[0];
    } else {
      let chance = Math.random() * sum;
      for (let i = 0; i < configs.length; i++) {
        chance -= configs[i].chanceToSelect;
        if (chance <= 0) {
          selectedConfig = configs[i];
          break;
        }
      }

      if (!selectedConfig) {
        selectedConfig = configs[0];
      }
    }
  }

  return selectedConfig;
}
