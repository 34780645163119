export default {
  INDEX: "/",
  ERROR: "/error",
  CREATE: "/create",
  HIRING_JAPAN: "/hiring-ja",
  PROCESSING: "/processing/:hash?",
  RESULT: "/result",
  FEED_BANNER: "/feedbanner",

  PRIVACY: "/privacy",
  OFFICIAL: "/official.html",
  CLONEFIGHTERS: "/clonefighters",
  INSTAGRAM: "/instagram",
  TEMPLATES_PREVIEW: "/templates-preview",
  TOONCOIN: "/tooncoin",
  WOOHOO: "/woohoo",

  LABS_INDEX: "/labs",

  HOMEPAGE: "/",
  REPOSTS: "/c/:id",
  USER_PAGE: "/u/:id",
  COMBO_PAGE: "/d/:id",

  NOT_FOUND_PAGE: "/404",
};
