import React from "react";
import * as ILS from  "./ItemLink.styled"

export default class ItemLink extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImageRender: false,
    }
  }

  loadResultImage = () => {
    this.setState({isImageRender: true})
  }

  render() {
    const {padding, to, item} = this.props;
    const {isImageRender} = this.state;
    return <ILS.ItemLink
      padding={padding}
      to={to}
    >
      <div className={isImageRender ? "": "line"} />
      <img className="original" src={item.content_preview.url} alt="" />
      <img className="result" src={item.result_url} alt="" onLoad={() => this.loadResultImage()} />
    </ILS.ItemLink>
  }
}