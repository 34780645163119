import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import CreativeView from "./CreativeView";
import {SvgRefreshIcon} from "../../ui/svg";
import clientStorage from "../../utils/client-storage";
import * as creativeUtils from "../../utils/creative";
import {creativeGroups} from "../../photolab/config/groups";

class SimpleTab extends React.Component {

  getOriginalPhotoTogglerIsVisible = (creative) => {
    const excludeGroups = [
      creativeGroups.VECTOR,
      creativeGroups.CARTOON_VECTOR_BODY,
      creativeGroups.CELEBRITY,
    ];

    if (this.props.site === "classic" && !window.clientConfig.features.showOriginalButton) {
      return false;
    }

    if (!creative._.getFile("layout")) {
      return false;
    }

    return this.props.site === "classic"
        && excludeGroups.indexOf(this.props.group) === -1;
  };

  render() {
    const { creatives } = this.props;
    const canBeRefreshed = !!this.props.canBeRefreshed;
    const group = creatives.filter((creative) => creative.group === this.props.group);

    const creative = group.find((creative) => creative.is_selected);
    const creativeIsProcessing = !creative || creativeUtils.creativeIsProcessing(creative);
    const creativeIsProcessed = creative && creativeUtils.creativeIsProcessed(creative);
    const creativeIsFailed = creative && creativeUtils.creativeIsFailed(creative);

    const previewCreative = group.find((creative) => !creative.is_selected);
    const previewCreativeIsProcessed = previewCreative && creativeUtils.creativeIsProcessed(previewCreative);

    const refreshButtonText = i18n.t("more_styles");
    const isVisibleBeforePhotoToggler = this.getOriginalPhotoTogglerIsVisible(creative);
    const isVisibleWatermarkToggler = window.clientConfig.isPro && creativeIsProcessed;

    return <div>
      <div className="creative-holder">
        {(creativeIsProcessing || creativeIsFailed) && <div className="creative-holder-placeholder" />}

        {previewCreativeIsProcessed && <div className="wait-video">
          <img src={previewCreative.file.url} alt="Creative" />
        </div>}

        {creativeIsProcessed && <CreativeView creative={creative} onLoaded={this.props.onImageLoaded} />}

        <div className="watermark-container watermark-container-ja" hidden={!isVisibleBeforePhotoToggler}>
          <button
            className="btn-logo-on"
            hidden={!creative.extra.creative_layout_is_hidden}
            onClick={this.props.onShowBeforePhotoButtonClick}>
            <span children={i18n.t("button_original_toggler__show")} />
          </button>
          <button
            className="btn-logo-on"
            hidden={creative.extra.creative_layout_is_hidden}
            onClick={this.props.onHideBeforePhotoButtonClick}>
            <span children={i18n.t("button_original_toggler__hide")} />
          </button>
        </div>

        <div className="watermark-container" hidden={!isVisibleWatermarkToggler}>
          <div hidden={clientStorage.getProWatermarkTooltipIsHidden()}>
            <div className="tooltip">
              <p dangerouslySetInnerHTML={{__html: i18n.t("toggle_watermark_tooltip__text")}} />
            </div>
            <div className="btn-remove-logo" hidden={clientStorage.getProWatermarkShouldBeRemoved()} onClick={this.props.onHideWatermarkButtonClick} />
          </div>
          <button
            className="btn-logo-on"
            hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
            onClick={this.props.onShowWatermarkButtonClick}>
            <span children={i18n.t("toggle_watermark_on")} />
          </button>
          <button
            className="btn-logo-on"
            hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
            onClick={this.props.onHideWatermarkButtonClick}>
            <span children={i18n.t("toggle_watermark_off")} />
          </button>
        </div>

        <div className="loader-video">
          <span className="item-loader first-item-loader" />
          <span className="item-loader second-item-loader" />
          <span className="item-loader third-item-loader" />
        </div>
        <div className="square square-big" />
        <div className="square square-med" />
        <div className="square square-small" />
      </div>

      <div className="btns-container" hidden={creativeIsProcessing || creativeIsFailed}>
        <button
          className="btn-upload-foto"
          children={i18n.t("collage__download")}
          onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)} />
        <button
          className="btn-update btn-refresh-foto"
          hidden={!canBeRefreshed}
          onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
          <SvgRefreshIcon />
          <span children={refreshButtonText} />
        </button>
      </div>
    </div>;
  }
}

SimpleTab.propTypes = {
  group: PropTypes.string.isRequired,
  creatives: PropTypes.array.isRequired,
  withHD: PropTypes.bool,
  canBeRefreshed: PropTypes.bool,
  onImageLoaded: PropTypes.func.isRequired,
  onHideWatermarkButtonClick: PropTypes.func.isRequired,
  onShowWatermarkButtonClick: PropTypes.func.isRequired,
  onHideBeforePhotoButtonClick: PropTypes.func,
  onShowBeforePhotoButtonClick: PropTypes.func,
  onDownloadButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
};

export default SimpleTab;