import React from "react";
import photolabSocialApiHelper from "../../../helpers/photolab-social-api.helper";
import {MainHeader} from "../../../components/MainHeader/MainHeader";
import * as CPS from "./CompoPage.styled";
import {AppButtons, getCurrentDevice, themeKeys} from "../../../components/AppButtons";
import i18n from "../../../i18n";
import * as Icons from "../../../ui/svg";
import routes from "../../../routes";
import { AnimateWrapper } from "../HomePage.styled";
import { getDeepLink } from "../../../utils/webview";
import { Loader } from "../../../components/Loader/Loader";
import {generatePath, Link} from "react-router-dom";
import {SkeletonHeader} from "../../../components/Skeleton/SkeletonHeader";
import {SkeletonImages} from "../../../components/Skeleton/SkeletonImages";

export default class ComboPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      combo: null,
      isReady: false
    }

    this.previewFilling = React.createRef();
  }
  
  componentDidMount() {
    this.loadCombo();
  }

  loadCombo = async () => {
    const comboId = this.props.match.params.id;
    try {
      const combo = await photolabSocialApiHelper.fetchCombo(comboId);

      this.setState({
        combo,
        isReady: true,
      });
    } catch (e) {
      console.error(e);
      window.location.href = routes.NOT_FOUND_PAGE;
    }
  }

  calculatePreviewsSize = (combo) => {
    const previewsBlockWidth = this.previewFilling.getBoundingClientRect().width;
    let originalWidth = combo.content_preview.original_size.width;
    let originalHeight = combo.content_preview.original_size.height;
    let resultWidth = combo.result_size.width;
    let resultHeight = combo.result_size.height;
    const maxHeight = Math.max(originalHeight, resultHeight);

    const originalRatio = maxHeight / originalHeight;
    const resultRatio = maxHeight / resultHeight;

    originalHeight = originalHeight * originalRatio;
    originalWidth = originalWidth * originalRatio;
    resultHeight = resultHeight * resultRatio;
    resultWidth = resultWidth * resultRatio;

    do {
      originalHeight = originalHeight * 1.1;
      originalWidth = originalWidth * 1.1;
      resultHeight = resultHeight * 1.1;
      resultWidth = resultWidth * 1.1;
    } while (originalWidth + resultWidth < previewsBlockWidth);
    
    return [originalWidth, resultWidth];
  }

  render() {
    const {combo, isReady} = this.state;

    let originalWidth, resultWidth;

    if (isReady) {
      [originalWidth, resultWidth] = this.calculatePreviewsSize(combo);
    }

    return <AnimateWrapper className={`${isReady ? "ready" : ""}`} ref={(ref) => this.previewFilling = ref}>
       {!isReady
        ? <React.Fragment>
           <SkeletonHeader ending={1} />
           <SkeletonImages mb={40}/>
          </React.Fragment>
        : <React.Fragment>
          <MainHeader user={combo.user} />
          <CPS.PreviewContainer>
            <CPS.OriginalPreview width={originalWidth}>
              <CPS.TitlePreview dangerouslySetInnerHTML={{__html: i18n.t("combo_page__title_original_preview")}} />
              <img src={combo.content_preview.url} alt="toonme" />
            </CPS.OriginalPreview>
            <CPS.ResultPreview width={resultWidth}>
              <CPS.TitlePreview dangerouslySetInnerHTML={{__html: i18n.t("combo_page__title_result_preview")}} />
              <img src={combo.result_url} alt="toonme" />
              <CPS.StatisticBlock>
                <button>
                  <Icons.LikeHeart />
                  {combo.likes}
                </button>
                <Link to={generatePath(routes.REPOSTS, {id: this.props.match.params.id})}>
                  <Icons.Reposts />
                  {combo.amount_children}
                </Link>
              </CPS.StatisticBlock>
            </CPS.ResultPreview>
          </CPS.PreviewContainer>
         </React.Fragment>}
          <CPS.Text dangerouslySetInnerHTML={{__html: i18n.t("combo_page__text")}} />
          {getCurrentDevice() ?
            <CPS.ComboLink
              href={getDeepLink(getCurrentDevice(), combo ? combo.id : "")}>
              {i18n.t("combo_page__apply_combo_btn")}
              <Icons.SvgRightArrow />
            </CPS.ComboLink>
            : 
            <AppButtons theme={themeKeys.primary} center={1} />}
          <CPS.RedirectLink to={generatePath(routes.USER_PAGE, {id: combo ? combo.user.uid : "/"})}>
            <Icons.Cards />
            <p dangerouslySetInnerHTML={{__html: i18n.t("combo_page__redirect_button", {name: combo ? combo.user.name: ""})}} />
          </CPS.RedirectLink>
    </AnimateWrapper>;
  }
}