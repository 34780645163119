import {css} from "styled-components"

export const skeletonAnimation = css`
  .line {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, white, transparent);
    position: absolute;
    left: -100%;
    top: 0;
    animation: move 1s infinite;
  }
  @keyframes move {
    from {
      left: -100%;
    }
    to {
      left: 100%;
    }
  }
`;