import React from "react";
import Modal from "./Modal";
import {copyTextFromElement} from "../utils/text";
import {SvgSprite} from "./SvgSprite";

const UPDATE_STATE_INTERVAL = 1000;

export default class CreativeDumpModal extends Modal {
  state = {
    isCopied: false,
    data: [],
  };

  constructor(props) {
    super(props);
    this.className = ""; // todo
    this.fieldRef = React.createRef();
  }

  componentDidMount() {
    this.updateStateTimer = setInterval(this.updateState, UPDATE_STATE_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateStateTimer);
  }

  updateState = () => {
    this.setState({
      data: window.creativesLog._log[this.props.creative.id] || []
    });
  }

  handleCopyClick = (e) => {
    e.preventDefault();

    if (this.fieldRef.current && copyTextFromElement(this.fieldRef.current)) {
      this.setState({
        isCopied: true
      });
    }
  };

  renderModal() {
    const data = this.state.data;
    const dateNow = Date.now();
    const tasks = {};

    data.sort((a, b) => a.at > b.at).forEach((task) => {
      if (!task.taskName) {
        return;
      }

      let key = task.taskName;

      if (task.taskName !== task.templateId) {
        key += `/${task.templateId}`;
      }

      if (!tasks[key]) {
        tasks[key] = {
          requestId: "",
          addtask: {
            start: {},
            finish: {},
          },
          waittask: {
            start: {},
            finish: {},
          },
        };
      }

      const event = task.event.split("_");

      tasks[key][event[0]][event[1]] = task;

      if (task.requestId) {
        tasks[key]["requestId"] = task.requestId;
      }
    });

    const start = data.length && data.reduce((prev, curr) => prev.at < curr.at ? prev : curr);
    const finish = data.find((item) => item.event === "finish");

    const processingTime = start && finish ? (finish.at - start.at) / 1000 : null;

    const imageLoadStartArray = data.filter((item) => item.event === "image_load_start");
    const imageLoadStartMin = imageLoadStartArray.length
        ? imageLoadStartArray.reduce((prev, curr) => prev.at < curr.at ? prev : curr)
        : null;

    const imageLoadFinishArray = data.filter((item) => item.event === "image_load_finish");
    const imageLoadFinishMin = imageLoadFinishArray.length
        ? imageLoadFinishArray.reduce((prev, curr) => prev.at < curr.at ? prev : curr)
        : null;

    const imageLoadSec = imageLoadStartMin && imageLoadFinishMin
        ? (imageLoadFinishMin.at - imageLoadStartMin.at) / 1000
        : null;

    const imageLoadStartSecAgo = imageLoadStartMin ? (dateNow - imageLoadStartMin.at) / 1000 : null;

    const imageLoadPreviewStartArray = data.filter((item) => item.event === "image_preview_load_start");
    const imageLoadPreviewStartMin = imageLoadPreviewStartArray.length
        ? imageLoadPreviewStartArray.reduce((prev, curr) => prev.at < curr.at ? prev : curr)
        : null;

    const imageLoadPreviewFinishArray = data.filter((item) => item.event === "image_preview_load_finish");
    const imageLoadPreviewFinishMin = imageLoadPreviewFinishArray.length
        ? imageLoadPreviewFinishArray.reduce((prev, curr) => prev.at < curr.at ? prev : curr)
        : null;

    const imageLoadPreviewSec = imageLoadPreviewStartMin && imageLoadPreviewFinishMin
        ? (imageLoadPreviewFinishMin.at - imageLoadPreviewStartMin.at) / 1000
        : null;

    const imageLoadPreviewStartSecAgo = imageLoadPreviewStartMin ? (dateNow - imageLoadPreviewStartMin.at) / 1000 : null;

    const imageUrl = this.props.creative._.isProcessed ? this.props.creative.file.url : null;

    const info = [];

    info.push({text: `id: ${this.props.creative.template.id}`});
    info.push({text: `group: ${this.props.creative.group}`});

    if (processingTime) {
      info.push({text: `processing time: ${processingTime}s`});
    }

    const imageInfo = {};
    if (imageLoadSec) {
      imageInfo.text = `image load: ${imageLoadSec}s`;
    }
    if (imageLoadStartSecAgo) {
      imageInfo.subtexts = [`add: ${imageLoadStartSecAgo}s`];
    }
    if (Object.keys(imageInfo).length !== 0) {
      info.push(imageInfo);
    }

    const imagePreviewInfo = {};
    if (imageLoadPreviewSec) {
      imagePreviewInfo.text = `image preview load: ${imageLoadPreviewSec}s`;
    }
    if (imageLoadPreviewStartSecAgo) {
      imagePreviewInfo.subtexts = [`add: ${imageLoadPreviewStartSecAgo}s`];
    }
    if (Object.keys(imagePreviewInfo).length !== 0) {
      info.push(imagePreviewInfo);
    }

    if (imageUrl) {
      info.push({text: "image url:", subtexts: [imageUrl]});
    }

    Object.keys(tasks).forEach((key) => {
      const task = tasks[key];
      const requestId = task.requestId || null;

      const addTaskSec = task.addtask.start.at && task.addtask.finish.at ? (task.addtask.finish.at - task.addtask.start.at) / 1000 : null;
      const addTaskSecAgo = task.addtask.start.at ? (dateNow - task.addtask.start.at) / 1000 : null;
      const addTaskStr = ""
          + (addTaskSec ? `add: ${addTaskSec}s` : "")
          + (!this.props.creative._.isFinished && addTaskSecAgo ? `; ${addTaskSecAgo}s ago` : "");

      const waitTaskSec = task.waittask.start.at && task.waittask.finish.at ? (task.waittask.finish.at - task.waittask.start.at) / 1000 : null;
      const waitTaskStr = ""
          + (waitTaskSec ? `wait: ${waitTaskSec}s` : "")
          + (task.waittask.finish.getResultRequestsAmount ? ` ; requests amount: ${task.waittask.finish.getResultRequestsAmount}` : "");

      const taskInfo = {text: `${key}:`, subtexts: []};

      if (requestId) {
        taskInfo.subtexts.push(`request id: ${requestId}`);
      }

      if (!!addTaskStr) {
        taskInfo.subtexts.push(addTaskStr);
      }

      if (!!waitTaskStr) {
        taskInfo.subtexts.push(waitTaskStr);
      }

      info.push(taskInfo);
    });

    let copyText = "";

    info.forEach((item) => {
      if (item.text) {
        copyText += `${item.text}\n`;
      }

      if (item.subtexts) {
        item.subtexts.forEach((subtext) => {
          copyText += `- ${subtext}\n`;
        })
      }
    });

    copyText += "\nlog: " + JSON.stringify(data, null, 2);

    return <div className="modal-container modal-container-body-select modal-develop">
      <div className="modal">
        {info.map((item, index) =>
          <div key={index} className="info-block">
            {item.text && <p className="main-text" dangerouslySetInnerHTML={{__html: item.text}} />}
            {item.subtexts && item.subtexts.map((subtext, index) =>
              <p key={index} className="additional-text" dangerouslySetInnerHTML={{__html: subtext}} />
            )}
          </div>
        )}

        <textarea
          ref={this.fieldRef}
          value={copyText}
          readOnly />
        <button 
          onClick={() => this.dismiss("button")} 
          className="btn-cancel">
          <SvgSprite viewBox="0 0 16 16" icon="icon-close" />
        </button>
        <div className="btns-container">
          <button 
            disabled={this.state.isCopied} 
            onClick={this.handleCopyClick}
            className="btn-copy">
            {this.state.isCopied ? "COPIED" : "COPY"}
          </button>
        </div>
      </div>
    </div>;
  }
}
