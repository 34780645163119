import styled from "styled-components";
import {Link} from "react-router-dom";
import {skeletonAnimation} from "../../Skeleton/mixins";

export const ItemLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
  will-change: transform;
  background: #eee;
  padding-top: ${props => props.padding};
  
  ${skeletonAnimation};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .2s;
    z-index: 1;

    &.original {
      object-fit: contain;
      z-index: 0;
    }
  }

  @media all and (min-width: 992px) {
    &:hover {
      .result {
        opacity: 0;
      }
    }
  }

  @media all and (max-width: 991px) {
    border-radius: 16px;
  }
`;