import React from "react";
import i18n from "../i18n";

export default class HomeButton extends React.Component {

  handleClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <button className="btn-back" onClick={this.handleClick}>
      <svg viewBox="0 0 32 54">
        <g fill="none" fillRule="evenodd">
          <g fill="#000">
            <g>
              <path d="M31.725 4.725L27 0 0 27 27 54 31.725 49.275 9.085 26.993z" transform="translate(-48 -234) translate(48 234)"/>
            </g>
          </g>
        </g>
      </svg>
      {this.props.text || i18n.t("try_another_photo")}
    </button>;
  }
}
