import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/flat-map";
import "core-js/features/string/starts-with";
import "core-js/features/object/assign";
import "core-js/features/object/keys";
import axios from "axios";

// eslint-disable-next-line no-extend-native
Array.prototype.randomIndex = function() {
  return Math.floor(this.length * Math.random());
};

// eslint-disable-next-line no-extend-native
Array.prototype.random = function() {
  return this[this.randomIndex()];
};

// eslint-disable-next-line no-extend-native
Array.prototype.filterLength = function(predicate) {
  return this.filter(predicate).length;
};

// eslint-disable-next-line no-extend-native
Array.prototype.first = function() {
  return this.length > 0 ? this[0] : null;
};

// eslint-disable-next-line no-extend-native
Array.prototype.last = function() {
  return this.length > 0 ? this[this.length - 1] : null;
};

// eslint-disable-next-line no-extend-native
Array.prototype.shuffle = function() {
  const source = this.slice();
  const dest = [];

  while (source.length > 0) {
    const items = source.splice(source.randomIndex(), 1);
    dest.push(items[0]);
  }

  return dest;
};

// eslint-disable-next-line no-extend-native
Array.prototype.isEmpty = function() {
  return this.length === 0;
};

// eslint-disable-next-line no-extend-native
Array.prototype.isNotEmpty = function() {
  return !this.isEmpty();
};

// eslint-disable-next-line no-extend-native
String.prototype.random = function() {
  return this[Math.floor(this.length * Math.random())];
};

require("./config");
require("./sentry");
require("./utils/ga");
require("./webview");

window.axios = axios.create();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
window.axios.defaults.headers.common["X-Project-Key"] = window.appConfig.project.key;

window.axios.interceptors.response.use(function(response) {
  window.clientConfig.country = !window.appConfig.isDebug && response.headers["x-client-geoip-country"] && response.headers["x-client-geoip-country"].length > 0
      ? response.headers["x-client-geoip-country"]
      : window.clientConfig.country;

  return response;
});

const ProcessingManager = require("./photolab/ProcessingManager");
window.processingManager = new ProcessingManager.default();

// global cache
window.appGlobalCache = {};

require("./app");