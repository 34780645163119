import {getHandlerByName} from "./handlers";
import Processing from "./Processing";
import {logCreativeResult, logEvent, userEvents} from "../utils/log";
import Creative from "./Creative";
import {creativeGroups} from "./config/groups";
import {genders} from "./config/bodies";
import {getCreativesConfigs, resolveCreativeConfigByGroup} from "./config";

const localStorageKey = "tmc:processing";

export default class ProcessingManager {

  _listeners = [];
  _processing = null;
  _handlers = [];

  /** @return {Processing} */
  get processing() {
    return this._processing;
  }

  /** @param {Processing} processing */
  start = (processing) => {
    this.stop();

    this._processing = processing;

    if (!processing.hasExtra(Processing.EXTRA_STARTED_AT)) {
      processing.setExtra(Processing.EXTRA_STARTED_AT, Date.now());
    }

    window.localStorage.setItem(localStorageKey, processing.toJSON());

    this.tick();
    this.update();

    // this.removeOnProcessingChangeHandler(this.handleStartGenderSwapOnGenderFinished);
    // this.addOnProcessingChangeHandler(this.handleStartGenderSwapOnGenderFinished);
    // this.removeOnProcessingChangeHandler(this.handleStartNnAnimeGanOnGenderFinished);
    // this.addOnProcessingChangeHandler(this.handleStartNnAnimeGanOnGenderFinished);
  };

  update = () => {
    this.processing.creatives.forEach((creative) => {
      if (creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) === true) {
        return;
      }

      if (creative.isFinished) {
        return;
      }

      const isActive = this._handlers.findIndex((item) => item.id === creative.id) > -1;
      if (isActive) {
        return;
      }

      const handler = getHandlerByName(creative.handler);
      if (handler === null) {
        throw new Error("Unrecognized handler name: " + creative.handler);
      }

      if (!creative.hasExtra(Creative.EXTRA_STARTED_AT)) {
        creative.setExtra(Creative.EXTRA_STARTED_AT, Date.now());

        if (window.appConfig.analytics.tasksIsEnabled) {
          logEvent(userEvents.CREATIVE_STARTED, {
            group: creative.group,
            template_id: creative.templateId,
          });
        }
      }

      const handlerPromise = handler(this.processing, creative)
        .then((creative) => {
          this.tick();

          if (window.appConfig.analytics.tasksIsEnabled) {
            logEvent(userEvents.CREATIVE_PROCESSED, {
              group: creative.group,
              template_id: creative.templateId,
              processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
            });
          }

          if (![creativeGroups.GENDER, creativeGroups.COMMON].includes(creative.group)) {
            logCreativeResult(
              creative.templateId,
              [{
                url: this.processing.file.url,
                skeleton_data: this.processing.getSkeleton(),
              }],
              [{url: creative.getFile("template") || creative.getFile("result")}],
              false,
              {
                gender: this.processing.getGender(),
              }
            );
          }
        })
        .catch((creative) => {
          this.tick();

          if (![creativeGroups.GENDER, creativeGroups.COMMON].includes(creative.group)) {
            logCreativeResult(
              creative.templateId,
              [{
                url: this.processing.file.url,
                skeleton_data: this.processing.getSkeleton(),
              }],
              [{error: creative.error}],
              false,
              {
                gender: this.processing.getGender(),
              }
            );
          }

          if (window.appConfig.analytics.tasksIsEnabled) {
            logEvent(userEvents.CREATIVE_FAILED, {
              group: creative.group,
              template_id: creative.templateId,
              processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
              reason: creative.error ? creative.error.type : "unknown",
              reason_message: creative.error ? creative.error.message : "unknown",
              reason_code: creative.error ? creative.error.code : "unknown",
            });
          }
        });

      this._handlers.push({
        id: creative.id,
        handler: handlerPromise,
      });
    });

    this.tick();
  };

  restore = () => {
    const storedValue = window.localStorage.getItem(localStorageKey);
    if (storedValue != null) {
      const processing = new Processing();
      processing.fromObject(JSON.parse(storedValue));

      return processing;
    }

    return null;
  };

  stop = () => {
    this._processing = null;
    // todo cancel promises
  };

  clear = () => {
    window.localStorage.removeItem(localStorageKey);
    this.stop();
  };

  tick = () => {
    if (!this.processing) {
      return;
    }

    this.commit(this.processing.toJSON());

    this._listeners.forEach((listener) => {
      listener.call(null, this.processing);
    });
  };

  commit = (data) => {
    window.localStorage.setItem(localStorageKey, data);
  };

  commitProcessing = () => {
    this.processing && this.commit(this.processing.toJSON());
  };

  addOnProcessingChangeHandler = (listener) => {
    this._listeners.push(listener);
  };

  removeOnProcessingChangeHandler = (listener) => {
    const pos = this._listeners.indexOf(listener);
    if (pos >= 0) {
      this._listeners.splice(pos, 1);
    }
  };

  handleStartGenderSwapOnGenderFinished = () => {
    if (!this.processing || this.processing.site !== "labs") {
      return;
    }

    const hasGroup = this.processing.groups.find((group) => {
      return [creativeGroups.GENDERSWAP_MALE, creativeGroups.GENDERSWAP_FEMALE].indexOf(group) > -1;
    });

    if (hasGroup) {
      return;
    }

    const genderCreative = this.processing.getSelectedCreativeInGroup(creativeGroups.GENDER);
    if (genderCreative && genderCreative.isProcessed) {
      const selectedGroup = this.processing.getGender() === genders.male
        ? creativeGroups.GENDERSWAP_MALE
        : creativeGroups.GENDERSWAP_FEMALE;

      const newGroups = this.processing.groups.slice();
      newGroups.push(selectedGroup);

      const selectedConfig = resolveCreativeConfigByGroup(this.processing.site, selectedGroup);

      this.processing.setGroups(newGroups);
      this.processing.addCreative(new Creative()
        .configureByConfig(selectedConfig)
        .setAsSelected(true));

      window.processingManager.update();
    }
  };

  handleStartNnAnimeGanOnGenderFinished = () => {
    if (!this.processing || this.processing.site !== "classic") {
      return;
    }

    if (this.processing.groups.includes(creativeGroups.NN_ANIME_GAN)) {
      return;
    }

    const genderCreative = this.processing.getSelectedCreativeInGroup(creativeGroups.GENDER);
    if (genderCreative && genderCreative.isProcessed) {
      if (this.processing.getGender() !== genders.female) {
        return;
      }

      const selectedConfig = resolveCreativeConfigByGroup(this.processing.site, creativeGroups.NN_ANIME_GAN);

      const selectedCreative = new Creative()
        .configureByConfig(selectedConfig)
        .setAsSelected(true);

      this.processing.addCreative(selectedCreative);

      getCreativesConfigs(this.processing.site).filter((config) => config.group === creativeGroups.NN_ANIME_GAN)
        .filter((c) => c.templateId !== selectedConfig.templateId)
        .forEach((creativeConfig) => {
          const creative = new Creative()
            .configureByConfig(creativeConfig)
            .setAsRefreshed(true)
            .setExtra(Creative.EXTRA_KEEP_PENDING, true);

          this.processing.addCreative(creative);
        });

      const groups = this.processing.groups.slice();
      groups.splice(8, 0, creativeGroups.NN_ANIME_GAN);

      this.processing.setGroups(groups);

      window.processingManager.update();
    }
  };
}