/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import {creativeGroups} from "./groups";
import {combo, stepWithFallback, setCreativesGroup, comboWithLayout, skipOnMultifaceError, whenSplitInRange} from "./helpers";
import {pwAssetUrl, when} from "../../utils/etc";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";
import clientStorage from "../../utils/client-storage";
import {isWebviewCountriesOf} from "../../utils/config.utils";

const aiConfigPosition = {
  skin: {
    name: 'skin',
    canvasId: 8661,
    maskId: 8662,
  },
  head: {
    name: 'head',
    canvasId: 8663,
    maskId: 8664,
  },
  face: {
    name: 'face',
    canvasId: 8659,
    maskId: 8660,
  },
};

const aiConfigStrength = 0.3;

export const rolloutDate = window.appConfig.isTestInstance
  ? Date.now()
  : new Date("2023-06-23T21:00:00+03:00").getTime();

const [storedTime, storedCount] = clientStorage.getCountProcessingInVariationConfig();
const isSelected = window.clientConfig.isNew
  && Date.now() <= rolloutDate + 86_400_000
  && (Date.now() >= rolloutDate && (storedTime < rolloutDate || storedCount < 5));

function getGroups() {
  const list = [
    creativeGroups.CELEBRITY,
    creativeGroups.MIDJORNEY,
    creativeGroups.GLOSS,
    creativeGroups.TAS3,
    creativeGroups.CARTOON_VECTOR_BODY,
    creativeGroups.TAB_210622,
  ];

  // // celeb никогда не ставим на первое место
  // if (list.indexOf(creativeGroups.CELEB) === 0) {
  //   const target = list.shift();
  //   const randomIndex = 1 + Math.floor((list.length - 1) * Math.random())
  //   list.splice(randomIndex, 0, target);
  // }

  return [
    // creativeGroups.CROP,
    creativeGroups.GENDER, // используется в аналитике "photo_gender"
    creativeGroups.COMMON,
    ...list
  ];
}

function getCommonCreatives() {
  const list = [
    new CreativeConfig(creativeGroups.CROP, 4021, handlersNames.TEMPLATE),
    new CreativeConfig(creativeGroups.GENDER, "gender_classifier", handlersNames.TEMPLATE),
  ]

  if (window.appConfig.processings.processSkeleton.isEnabled) {
    const countries = window.appConfig.processings.processSkeleton.countries;
    if (isWebviewCountriesOf(countries)) {
      list.push(new CreativeConfig(creativeGroups.COMMON, "skeleton_process", handlersNames.TEMPLATE));
    }
  }

  return list;
}

function getCreatives() {
  return [
    ...[
      new CreativeConfig(creativeGroups.CARTOON_VECTOR_BODY, -1, handlersNames.BODY),
    ],

    ...setCreativesGroup(creativeGroups.CELEBRITY, [
      combo([7088]).setSelected(),
      combo([7333, 7116]),
      combo([6756, 3690]),
      combo([6756, 6936]),
      combo([6756, 2691, 4265, 2345]),
      combo([6756, 2310, 2122, 950]),
    ].shuffle()),

    ...setCreativesGroup(creativeGroups.MIDJORNEY, [
      combo([stepWithFallback(7516, 7539), 7518]),
      combo([stepWithFallback(7516, 7539), 7519]),
      combo([stepWithFallback(7516, 7539), 7520]),
    ].shuffle()),

    ...setCreativesGroup(creativeGroups.GLOSS, [
      ...(window.clientConfig.isIndiaRegionUser
        ? [comboWithLayout([stepWithFallback(7083, 7084), 7186]).setSelected()] : []),
      comboWithLayout([stepWithFallback(7083, 7084), 7137])
        .setSelected(!window.clientConfig.isIndiaRegionUser && isSelected),
      comboWithLayout([
        stepWithFallback(7083, 7084),
        {id: 7031, images: [{useAltBody: true}, {src: "https://storage.ws.pho.to/s2/d7fd9df26262214fb5563a80001e064efef928dc.png"}]},
        1111,
        2345
      ]),
      comboWithLayout([stepWithFallback(7083, 7084), 6908]),
      comboWithLayout([stepWithFallback(7083, 7084), 6941]),
      comboWithLayout([stepWithFallback(7083, 7084), 7120]),
    ].shuffle()),

    ...setCreativesGroup(creativeGroups.TAS3, [
      comboWithLayout([6611]),
      comboWithLayout([6032]),
    ].shuffle()),

    ...setCreativesGroup(creativeGroups.TAB_210622, [
      comboWithLayout([6151]),
      comboWithLayout([5303]),
      comboWithLayout([5302]),
      comboWithLayout([5289]),
    ].shuffle()),

    ...setCreativesGroup(creativeGroups.AI_202304, [
      // creativeGroups.AI_20230421_13
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230421_13",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230421_13 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230421_13_${aiConfigPosition.head.name}_7088_8681_str0.45.jpg`)),
      // creativeGroups.AI_20230421_25
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230421_25",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230421_25 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230421_25_${aiConfigPosition.head.name}_7088_8681_str0.45.jpg`)),
      // creativeGroups.AI_20230427_32
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230427_32",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230427_32 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230427_32_${aiConfigPosition.head.name}_7088_str0.3.jpg`)),
      // creativeGroups.AI_20230427_33
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230427_33",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230427_33 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230427_33_${aiConfigPosition.head.name}_7088_str0.3.jpg`)),
      // creativeGroups.AI_20230427_34
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230427_34",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230427_34 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230427_34_${aiConfigPosition.head.name}_7088_str0.3.jpg`)),
      // creativeGroups.AI_20230427_35
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230427_35",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "FALSE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230427_35 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230427_35_${aiConfigPosition.head.name}_7088_str0.3.jpg`)),
      // creativeGroups.AI_20230427_36
      combo([
        {id: "gender_classifier"},
        {id: 7088, images: [{src: "@"}]},
        {
          id: 8681,
          templateParams: {
            "@configId": "toonme_ai_20230427_36",
            "@transformationId": "base2",
            canvas_template_name: aiConfigPosition.head.canvasId,
            mask_template_name: aiConfigPosition.head.maskId,
            gender: "#0",
            param_s: aiConfigStrength,
            param_ipfl: 1,
            param_gm: "TRUE",
          },
        },
      ]).setTemplateId(creativeGroups.AI_20230427_36 + "_7088_8681_" + aiConfigPosition.head.name + "_str" + aiConfigStrength)
        .setExtra(Creative.EXTRA_KEEP_PENDING, true)
        .setExtra(Creative.EXTRA_PREVIEW_URL, pwAssetUrl(`templates-previews/toonme20230426/ai_20230427_36_${aiConfigPosition.head.name}_7088_str0.3.jpg`)),
    ].shuffle()),
  ];
}

export default {
  getGroups: function () {
    return getGroups();
  },
  getCreatives: function () {
    let list = [
      ...getCommonCreatives(),
      ...getCreatives(),
    ];

    return list;
  },
};