import React from "react";
import ReactDOM from "react-dom/client";
import {generatePath, BrowserRouter, Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import routes from "./routes";
import IndexPageDefault from "./pages/IndexPage";
import IndexPageV2 from "./pages/IndexPageV2";
import ErrorPage from "./pages/ErrorPage";
import CreatePage from "./pages/CreatePage";
import ResultPageDefault from "./pages/result/ResultPage";
import ResultPageV2 from "./pages/result_v2/ResultPageV2";
import OfficialPage from "./pages/OfficialPage";
import InstagramPage from "./pages/InstagramPage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import ProcessingPage from "./pages/ProcessingPage";
import PrivacyPage from "./pages/PrivacyPage";
import LoadingV2 from "./components/LoadingV2";
import Loading from "./components/Loading";
import HiringPage from "./pages/HiringPage";
import FeedBannerPage from "./pages/FeedBannerPage";
import Processing from "./photolab/Processing";
import {systemGroups} from "./photolab/config/groups";
import CelebritiesPage from "./pages/CelebritiesPage";
import ToonCoinPage from "./pages/ToonCoinPage";
import WoohooPage from "./pages/WoohooPage";
import {signalEvent, signals} from "./utils/signals";
import Routes from "./routes";
import HomePage from "./pages/homepage/HomePage";
import RepostsPage from "./pages/homepage/reposts/RepostsPage";
import ComboPage from "./pages/homepage/result_page/ComboPage";
import UsersPage from "./pages/homepage/user_page/UserPage";
import NotFoundPage from "./pages/homepage/not_found_page/NotFoundPage";

window.onerror = function(message, file, line, col, error) {
  hitEvent(hits.jsGlobalError);
  signalEvent(signals.jsGlobalError, 1, true);
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showTestInstanceAlert: false,
    };
  }

  componentDidMount() {
    if (window.clientConfig.isWebviewMock) {
      hitEvent(hits.WEBVIEW_NOT_RECOGNIZED);
      logEvent(userEvents.CDN_HOST_WITHOUT_GET_PARAMS);
    }

    this.checkJsonSuspended();
    this.checkIsTestInstance();
    this.updateBodyClasses();

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.WEBVIEW_TAB_FOCUS);
        logEvent(userEvents.WEBVIEW_TAB_FOCUS, {
          os: window.clientConfig.isWebviewAndroid
            ? "android"
            : (window.clientConfig.isWebviewIOS ? "ios" : "other")
        });
      }
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();
    }

    this.checkLatestProcessing();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  checkLatestProcessing = () => {
    return; // disable for all

    // eslint-disable-next-line no-unreachable
    if (!window.clientConfig.isWebview) {
      return;
    }

    const processing = window.processingManager.restore();
    if (!processing) {
      return;
    }

    const allowTime = window.clientConfig.splitGroupId === 6 ? 600_000 : 3_600_000;
    const startedAt = processing.getExtra(Processing.EXTRA_STARTED_AT);
    if ((Date.now() - startedAt) > allowTime) {
      return;
    }

    window.clientConfig.isReturned = true;

    const selectedCreatives = processing.creatives.filter((c) => c.isSelected && !c.inGroups(systemGroups));
    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    if (failedCreatives.length === selectedCreatives.length) {
      return;
    }

    if (window.clientConfig.isReturned && window.clientConfig.splitGroupId > 5) {
      if (window.clientConfig.splitGroupId > 7 && window.clientConfig.lang === "en") {
        this.context.setIsShowReturningModal(true);
      }

      this.props.history.push(generatePath(routes.PROCESSING), {
        skipAd: true,
      });
    }
  };

  checkJsonSuspended = () => {
    if (window.clientConfig.isWeb) {
      this.setState({isLoading: false});
      return;
    }

    window.axios.get("/webview-suspended.json?r=" + Math.random()).then((res) => {
      const clientId = window.clientConfig.queryParams["aid"]
        || window.clientConfig.queryParams["vicman_unified_id"];

      const letterIsMatch = res.data.letters
        && res.data.letters.indexOf(clientId.substring(0, 1).toUpperCase()) >= 0;

      const countryIsMatch = res.data.countries
        && res.data.countries.indexOf((window.clientConfig.queryParams["country"] || "").toUpperCase()) >= 0;

      if (countryIsMatch && letterIsMatch) {
        const redirectUrl = new URL(res.data.url[window.clientConfig.isWebviewIOS ? "ios" : "android"]);
        redirectUrl.searchParams.append("retry", window.location.hostname);

        Object.keys(window.clientConfig.queryParams).forEach((key) => {
          redirectUrl.searchParams.append(key, window.clientConfig.queryParams[key]);
        });

        window.location.href = redirectUrl.toString();
      } else {
        this.setState({isLoading: false});
      }
    }).catch((err) => {
      console.error(err);
      this.setState({isLoading: false});
    });

    // fallback timeout
    setTimeout(() => this.setState({isLoading: false}), 1000);
  };

  checkIsTestInstance = () => {
    if (window.clientConfig.isWeb) {
      return;
    }

    if (!window.appConfig.isTestInstance) {
      return;
    }

    this.setState({showTestInstanceAlert: true}, () => {
      setTimeout(() => this.setState({showTestInstanceAlert: false}), 5000);
    });
  };

  updateBodyClasses = () => {
    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-locale--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    if (window.clientConfig.isWebviewIOS) {
      const osVersion = window.clientConfig.queryParams.os_version;

      document.body.classList.add("ios" + osVersion.split(".").first());
    }

    if (window.appConfig.webAds.isEnabled) {
      document.body.classList.add("app-webads-enabled");
    }

    toggleBodyClass("app-webview--ios", window.clientConfig.isWebviewIOS);
    toggleBodyClass("app-webview--android", window.clientConfig.isWebviewAndroid);

    if (window.location.pathname === "/labs"
      || window.location.pathname === "/labs/"
      || window.location.pathname.indexOf("/labs/result") === 0
    ) {
      document.body.classList.add("app-design-v1");
      document.body.classList.remove("app-design-v2");
    } else {
      document.body.classList.add("app-design-v2");
      document.body.classList.remove("app-design-v1");

      if (window.clientConfig.isWebDesktop) {
        document.body.classList.add("app-design-v2--result-v3");
      }
    }
  };

  onRouteChanged = () => {
    window.scrollTo(0, 0);
    this.updateBodyClasses();
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingV2 />;
    }

    const IndexPageSplit = IndexPageV2;
    const ResultPageSplit = ResultPageV2;

    return <div className={`${window.clientConfig.locale}`}>
      <TestToast
        hidden={!this.state.showTestInstanceAlert}
        onClick={() => this.setState({showTestInstanceAlert: false})}
        children={new URL(window.location.href).hostname} />

      <Switch>
        <Route exact path={routes.INDEX} render={props => <IndexPageSplit {...props} site={"classic"} />} />
        <Route exact path={routes.ERROR} render={props => <ErrorPage {...props} site={"classic"} />} />
        <Route exact path={routes.CREATE} render={props => <CreatePage {...props} site={"classic"} />} />
        <Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} site={"classic"} />} />
        <Route exact path={routes.PRIVACY} render={props => <PrivacyPage {...props} site={"classic"} />} />
        <Route exact path={routes.OFFICIAL} render={props => <OfficialPage {...props} site={"classic"} />} />
        <Route exact path={routes.INSTAGRAM} render={props => <InstagramPage {...props} site={"classic"} />} />
        <Route exact path={routes.HIRING_JAPAN} render={props => <HiringPage {...props} site={"classic"} />} />
        <Route exact path={routes.FEED_BANNER} render={props => <FeedBannerPage {...props} site={"classic"} />} />
        <Route exact path={"/somesecretkeyurlvicman/celebrities"} render={props => <CelebritiesPage {...props} />} />
        <Route path={routes.RESULT} render={props => <ResultPageSplit {...props} site={"classic"} />} />
        <Route path={routes.TOONCOIN} render={props => <ToonCoinPage {...props} site={"classic"} />} />
        <Route path={routes.WOOHOO} render={props => <WoohooPage {...props} site={"classic"} />} />

        <Route path="/labs" render={() => <div>
          <Route exact path={"/labs" + routes.INDEX} render={props => <IndexPageDefault {...props} site="labs" />} />
          <Route exact path={"/labs" + routes.ERROR} render={props => <ErrorPage {...props} site="labs" />} />
          <Route exact path={"/labs" + routes.CREATE} render={props => <CreatePage {...props} site="labs" />} />
          <Route exact path={"/labs" + routes.PROCESSING} render={props => <ProcessingPage {...props} site="labs" />} />
          <Route path={"/labs" + routes.RESULT} render={props => <ResultPageDefault {...props} site="labs" />} />
        </div>} />

        <Route path={routes.NOT_FOUND_PAGE} render={props => <NotFoundPage {...props} />} />

        <Route path={routes.HOMEPAGE} render={props => <HomePage {...props}>
          <Route path={routes.REPOSTS} render={props => <RepostsPage {...props} />} />
          <Route path={routes.COMBO_PAGE} render={props => <ComboPage {...props} />} />
          <Route path={routes.USER_PAGE} render={props => <UsersPage {...props} />} />
        </HomePage>}>
        </Route>
      </Switch>
      <ModalWrapper />
    </div>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<BrowserRouter><AppContextProvider><AppWithRouter /></AppContextProvider></BrowserRouter>);

function TestToast({hidden, children, onClick}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="app-toast-top" onClick={onClick}>{children}</div>;
}

function toggleBodyClass(className, flag) {
  if (flag) {
    document.body.classList.add(className);
  } else {
    document.body.classList.remove(className);
  }
}