module.exports = {
  "strings": {
    "try_another_photo": "Tentar outra foto",
    "change_photo": "Alterar foto",

    "landing__title_part_1": "É isso aí!",
    "landing__title_part_2": "É hora de viver seu sonho de desenho animado, quantas vezes você quiser.",
    "landing__subtitle": "É só entregar sua foto para a IA 😜",
    "landing__select_photo_button": "Escolha uma foto",
    "landing__get_the_app_button": "Baixe o app",
    "loading__uploading": "Carregando...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Agora, você está num local experimental especial. Para os estilos originais, volte para a versão primária abaixo.",
    "landing__labs_back_button": "Volte para ToonMe Classic",
    "landing__labs_choice": "Ou tente nossos estilos experimentais no <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "Estamos testando novos estilos e você está convidado a dar uma espiada! Volte para os estilos originais quando quiser.",
    "result__labs_tab_button": "Vamos experimentar",
    "result__tab_classic_title": "Gostou dos efeitos experimentais?",
    "result__tab_classic_info": "Fique aqui ou volte para a versão primária do ToonMe",
    "result__tab_classic_btn": "Volte para ToonMe Classic",
    "result_loader__title": "Está quase pronto...",

    "processing_text_1": "Amarelando sua pele... 🖌️",
    "processing_text_2": "Cartunizando suas características faciais...",
    "processing_text_3": "Você parece até uma princesa da Disney. Brincadeirinha. Ou será que não?...🙈",
    "processing_text_4": "Aqui é onde os sonhos viram CARTUNIDADE",
    "processing_text_5": "RequinTUNIZANDO a sua foto!",
    "processing_text_6": "A cartunização está em andamento!",
    "processing_text_7": "Walt Disney teria gostado! ✨",

    "collage__select_photo": "Experimente com a sua foto",
    "collage__download": "Baixar em HD",
    "collage__refresh": "Atualizar",
    "more_styles": "Mais estilos",

    "download_modal__title": "Não pare aqui!",
    "download_modal__message": "Compartilhe nas redes, junte-se à multidão!",
    "download_modal__message_wait": "Criando resultado em HD…",
    "download_modal__message_ready": "A versão em HD está pronta, download iniciado...",
    "download_modal__dismiss_button": "Entendi",
    "download_modal__download_button": "Baixe novamente",

    "error__error": "Erro",

    "internal_error": "Ocorreu um erro...",
    "error_overload_webview": "Desculpe, nosso servidor está sobrecarregado devido ao grande número de downloads do app. Tente usar a guia de Toon Effects ou volte daqui a pouco. Estamos trabalhando muito para consertar tudo em breve.",
    "error_overload_web": "Desculpe, nosso servidor está sobrecarregado devido ao grande número de downloads do app. Por favor, tente novamente daqui a pouco. Estamos trabalhando muito para consertar tudo em breve.",

    "rateapp_dialog__title": "Ama o ToonMe?",
    "rateapp_dialog__message-bold": "Dê estrelas para o app e ajude outros usuários a descobrirem e amarem o app também!",
    "rateapp_dialog__message": "PS. Também vai nos ajudar a recuperar as classificações, que foram afetadas por problemas no servidor depois que o ToonMe viralizou. 🙏",
    "rateapp_dialog__rate_button": "Avalie!",
    "rateapp_dialog__skip_button": "Ignorar",

    "btn_store__title":"<span class=\"btn-store-title\">Baixar na</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Cancelar e salvar<br />em qualidade padrão",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Fale conosco",
    "btn_back": "back",

    "vector_tab_refresh__title": "Quase pronto!",
    "vector_tab_refresh__subtitle": "Escolha um retrato",
    "vector_tab_refresh__subtitle2": "Escolher estilo da cabeça",

    "updated_at": "Atualizado com novos efeitos em <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "ALERTA!",
    "warning_clones_link": "CUIDADO COM CLONES BARATOS",

    "snap_dialog__title": "Antes-depois ou apenas TOON?",
    "snap_dialog__message": "Escolha como você compartilha!",
    "snap_dialog__share_button": "Compartilhar",
    "snap_dialog__back_button": "Voltar",

    "title_contacts_form": "Dos desenvolvedores de Photo Lab, o principal app de fotografia do mundo para iOS e Android, com 260M de downloads – até agora…",

    "toggle_watermark_tooltip__text": "Clique no logotipo para removê-lo",
    "toggle_watermark_on": "Logo ativado",
    "toggle_watermark_off": "Logo desativado",

    "remove_logo_modal__title": "Manter ou não manter…",
    "remove_logo_modal__message": "Ei, sabia que o logo faz parte do design deste efeito? Vai nos deixar felizes se você decidir mantê-lo.",
    "remove_logo_modal__btn_cancel": "Manter o logo",
    "remove_logo_modal__btn_remove": "Removê-lo assim mesmo",

    // v2
    "landing__toonme_on_instagram": "#ToonMe no Instagram",

    "creative_failed_message": "Opa, ocorreu algum erro com este TOON. 🤔 Escolha qualquer outro estilo ou tente outra vez.",
    "creative_failed_retry_btn": "Tentar novamente",

    "refresh_body__btn_body": "Escolher estilo do corpo",
    "refresh_body__btn_head": "Escolher estilo da cabeça",
    "button_choice_gender_male": "masculinos",
    "button_choice_gender_female": "femininos",
    "button_choice_gender_text": "estilos",

    "button_original_toggler__show": "Mostrar foto original",
    "button_original_toggler__hide": "Ocultar foto original",
  },
};
