import React from 'react';
import {generatePath} from "react-router";
import routes from "../../routes";
import Loading from "../../components/Loading";
import HomeButton from "../../components/HomeButton";
import AppContext from "../../contexts/AppContext";
import * as creativeUtils from "../../utils/creative";
import {getLocationQueryObject} from "../../utils/text";
import {hitEvent, hits, logCreativeResult, logEvent, userEvents} from "../../utils/log";
import * as webviewUtils from "../../utils/webview";
import {
  debounce,
  prefixRouteForSite,
  pwAssetUrl
} from "../../utils/etc";
import clientStorage from "../../utils/client-storage";
import SimpleTab from "./SimpleTab";
import LabsTab from "./LabsTab";
import UniteTab from "./UniteTab";
import ClassicTab from "./ClassicTab";
import VectorTab from "./VectorTab";
import RemoveLogoModal from "./RemoveLogoModal";
import DownloadModal from "./DownloadModal";
import LoadingV2 from "../../components/LoadingV2";
import {creativeGroups, systemGroups} from "../../photolab/config/groups";
import Creative from "../../photolab/Creative";
import CreativeConfig from "../../photolab/CreativeConfig";
import {mapNewCreativesToOld, transformToDownloadUrl} from "../../utils/creative";
import BodyTab from "./BodyTab";
import {webviewOpenBrowser} from "../../utils/webview";
import {UNITE_COM_TAB_LINK} from "../../utils/constants";
import {genders, getBodiesListByGroup} from "../../photolab/config/bodies";
import ReturningModal from "./ReturningModal";
import CreativeDumpModal from "../../components/CreativeDumpModal";
import TabItemView from "./TabItemView";
import {saveAs} from "file-saver";
import {getCreativesConfigs} from "../../photolab/config";
import {signalEvent, signals} from "../../utils/signals";

const SHARE_PROVIDER_SNAPCHAT = "snapchat";
const SHARE_PROVIDER_FACEBOOK = "facebook";
const SHARE_PROVIDER_INSTAGRAM = "instagram";

export default class ResultPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      file: null,
      fileGender: "unspecified",
      tabs: [],
      creatives: null,
      tab: "",
      defaultTab: "",
    };

    this.hitProcessingFailedOnResultPageIsTriggered = false;

    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      const choiceAt = clientStorage.getProWatermarkRemoveChoiceAt();
      if (Date.now() - choiceAt >= 86400000) {
        clientStorage.setProWatermarkShouldBeRemoved(false);
        clientStorage.setProWatermarkTooltipIsHidden(false);
      }
    }

    this.pageContainerRef = null;
    this.pageContainerRefObserver = new MutationObserver(() => {
      if (this.pageContainerRef) {
        this.pageContainerRef.style.height = "";
      }
    });
  }

  componentDidMount() {
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (window.processingManager.processing === null) {
      const restoredProcessing = window.processingManager.restore();
      if (restoredProcessing && restoredProcessing.site === this.props.site) {
        window.processingManager.start(restoredProcessing);
      } else {
        this.props.history.replace(routes.INDEX);
        return;
      }
    }

    this.handleProcessingChanged();

    if (this.context.isShowReturningModal) {
      this.context.pushModal(<ReturningModal
        key="ResultPage_ReturningModal"
        onFileSelected={this.handleFileSelected}
      />);
    }
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handlePageContainerRefChange = (node) => {
    this.pageContainerRef = node;

    if (this.pageContainerRef) {
      this.pageContainerRefObserver.observe(this.pageContainerRef, {
        attributes: true,
        attributeFilter: ["style"]
      });
    }
  };

  handleProcessingChangedDebug = () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(window.processingManager.processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }
  };

  handleProcessingChanged = () => debounce("ResultPage_handleProcessingChanged", 100, () => {
    if (window.processingManager.processing.site !== this.props.site) {
      this.props.history.replace(routes.INDEX);
      return;
    }

    const query = getLocationQueryObject();
    const isNewResult = this.state.file === null;
    const nextState = {
      isLoading: false,
      tabs: window.processingManager.processing.groups.filter((group) => systemGroups.indexOf(group) === -1),
      file: window.processingManager.processing.file,
      fileGender: window.processingManager.processing.getGender(),
      creatives: mapNewCreativesToOld(window.processingManager.processing.creatives),
    };

    if (isNewResult) {
      const tab = (nextState.tabs.indexOf(query.t) > -1)
        ? query.t
        : nextState.tabs[0];

      nextState.defaultTab = tab;
      nextState.tab = tab;

      logEvent(userEvents.PAGE_RESULT, {
        ...getShareEventProps(nextState),
        group: tab,
      });

      const creativesInGroup = nextState.creatives.filter((creative) => creative.group === tab);
      const selectedCreative = creativesInGroup.find((creative) => creative.is_selected);
      const _selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(tab);
      if (_selectedCreative && _selectedCreative.hasExtra(Creative.EXTRA_KEEP_PENDING)) {
        _selectedCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);
        window.processingManager.update();
      }

      logEvent(userEvents.TAB_SELECT, {
        ...getShareEventProps(nextState, selectedCreative),
        is_finished: !!(selectedCreative && creativeUtils.creativeIsFinished(selectedCreative)),
        is_processed: !!(selectedCreative && creativeUtils.creativeIsProcessed(selectedCreative)),
        group: tab,
        group_is_empty: creativesInGroup.length === 0,
        prev_group: this.state.tab,
        is_leading: true,
      });

      this.hitProcessingFailedOnResultPageIsTriggered = false;

      if (window.clientConfig.isWebview) {
        this.props.history.replace({
          pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
          search: `?t=${nextState.tab}`
        });
      }
    }
    else {
      const groupCreatives = nextState.creatives.filter((c) => c.group === this.state.tab);
      const failedGroupCreative = groupCreatives.find(creativeUtils.creativeIsFailed);

      if (groupCreatives.length === 1 && failedGroupCreative) {
        logEvent(userEvents.TAB_FAILED, {
          group: this.state.tab,
          file_id: nextState.file.id,
          template_id: failedGroupCreative.template.id,
        });
      }
    }

    const failedCreatives = nextState.creatives.filter(creativeUtils.creativeIsFailed);
    if (failedCreatives.length > 0) {
      if (!this.hitProcessingFailedOnResultPageIsTriggered) {
        this.hitProcessingFailedOnResultPageIsTriggered = true;
        hitEvent(hits.PROCESSING_FAILED_ON_RESULT_PAGE);
      }
    }

    this.setState(nextState);
  });

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {
      page: "result",
      is_returned: window.clientConfig.isReturned,
    });

    this.props.history.push(prefixRouteForSite(routes.CREATE, this.props.site), {file});
  };

  handleImageLoaded = (creative) => {
    logEvent(userEvents.CREATIVE_VIEW, getShareEventProps(this.state, creative));
  };

  handleShare = (provider, sdCreative, hdCreative, props) => {
    props = props || {};

    switch (provider) {
      case SHARE_PROVIDER_SNAPCHAT: {
        hitEvent(hits.SHARE_CLICK_SNAPCHAT);
        break;
      }
      case SHARE_PROVIDER_FACEBOOK: {
        hitEvent(hits.SHARE_CLICK_FACEBOOK);
        break;
      }
      case SHARE_PROVIDER_INSTAGRAM: {
        hitEvent(hits.SHARE_CLICK_INSTAGRAM);
        break;
      }
      default:
        break;
    }

    logEvent(userEvents.SHARE, {
      provider,
      ...props,
      ...getShareEventProps(this.state, sdCreative, hdCreative),
    });
  };

  handleDownload = (creative, hdCreative) => {
    logEvent(userEvents.DOWNLOAD_START, getShareEventProps(this.state, creative, hdCreative));

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(hdCreative.file.url);
      } else {
        const fileParts = hdCreative.file.url.split(".");
        const fileName = "toonmecom_"
            + creative.id.substr(0, 6)
            + "." + fileParts.last();

        saveAs(transformToDownloadUrl(hdCreative.file.url), fileName);
      }

      if (!window.processingManager.processing.hasExtra("signal_download_is_sent")) {
        window.processingManager.processing.setExtra("signal_download_is_sent", true);
        signalEvent(signals.download);
      }
    }, 100);
  };

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPage-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      file={this.state.file}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  handleDownloadButtonClick = (e, creative, withHD) => {
    logEvent(userEvents.DOWNLOAD, getShareEventProps(this.state, creative));
    hitEvent(hits.DOWNLOAD);

    const processing = window.processingManager.processing;

    logCreativeResult(
      creative.template.id,
      [{
        url: processing.file.url,
        skeleton_data: processing.getSkeleton(),
      }],
      [{url: (creative.file && creative.file.url) || ""}],
      true,
      {
        gender: processing.getGender(),
      }
    );

    this.showDownloadModal(creative, withHD);
  };

  handleRefreshButtonClick = (group) => debounce("ResultPage_handleRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {
      ...getShareEventProps(this.state),
      group,
    });

    const processing = window.processingManager.processing;

    const currentSelectedCreative = processing.getSelectedCreativeInGroup(group);
    currentSelectedCreative.setAsSelected(false);

    const groupCreatives = processing.creatives.filter((creative) => creative.group === group);
    const nonStartedCreatives = groupCreatives.filter((c) => c.getExtra(Creative.EXTRA_STARTED_AT, 0) === 0);

    if (nonStartedCreatives.length > 0) {
      const nextCreative = nonStartedCreatives.random();

      nextCreative
        .setAsSelected(true)
        .removeExtra(Creative.EXTRA_KEEP_PENDING);

      window.processingManager.update();
    }
    else {
      const currentIndex = groupCreatives.findIndex((creative) => creative.id === currentSelectedCreative.id);
      const nextCreative = groupCreatives.find((_, index) => index > currentIndex);
      if (nextCreative) {
        nextCreative.setAsSelected(true);
      } else {
        groupCreatives[0].setAsSelected(true);
      }

      this.handleProcessingChanged();
    }
  });

  handleSubTabButtonClick = (group, creative) => debounce("ResultPage_handleSubTabButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {
      ...getShareEventProps(this.state),
      group,
    });

    const processing = window.processingManager.processing;

    const currentSelectedCreative = processing.getSelectedCreativeInGroup(group);
    currentSelectedCreative.setAsSelected(false);

    creative._.setAsSelected(true)
      .removeExtra(Creative.EXTRA_KEEP_PENDING);

    window.processingManager.update();
    this.handleProcessingChanged();
  });

  handleRetryButtonClick = (creative) => debounce("ResultPage_handleRetryButtonClick", 100, () => {
    let config = getCreativesConfigs(this.props.site).find((config) => config.templateId === creative.template.id);

    if (creative.group === creativeGroups.CARTOON_VECTOR_BODY) {
      config = getCreativesConfigs(this.props.site).find((config) => config.group === creativeGroups.CARTOON_VECTOR_BODY);
    }

    window.processingManager.processing.removeCreative(creative);

    window.processingManager.processing.addCreative(new Creative()
      .configureByConfig(config)
      .setAsSelected(true));

    window.processingManager.update();
  });

  handleVectorRefreshButtonClick = (templateId) => debounce("ResultPage_handleVectorRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {
      ...getShareEventProps(this.state),
      group: "vector",
      template_id: templateId
    });

    const groupCreatives = window.processingManager.processing.creatives
      .filter((creative) => creative.group === creativeGroups.VECTOR);

    const selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(creativeGroups.VECTOR);
    selectedCreative.setAsSelected(false);

    const creativeWithSameTemplateId = groupCreatives.find((creative) => creative.templateId === templateId);
    if (creativeWithSameTemplateId) {
      creativeWithSameTemplateId.setAsSelected(true);
      this.handleProcessingChanged();
    } else {
      const creativeConfig = new CreativeConfig(selectedCreative.group, templateId, selectedCreative.handler);
      const creative = new Creative()
        .configureByConfig(creativeConfig)
        .setAsSelected(true)
        .setAsRefreshed(true);

      window.processingManager.processing.addCreative(creative);
      window.processingManager.update();
    }
  });

  handleBodyRefreshTemplateSelect = (group, data) => {
    const processing = window.processingManager.processing;

    const selectedCreative = processing.getSelectedCreativeInGroup(group);
    if (!selectedCreative) {
      return;
    }

    selectedCreative.setAsSelected(false);

    const nextCreative = new Creative()
      .configureByConfig(new CreativeConfig(
        selectedCreative.group,
        data.headTemplateId,
        selectedCreative.handler
      ))
      .setExtra(Creative.EXTRA_TEMPLATE_NAME, selectedCreative.getExtra(Creative.EXTRA_TEMPLATE_NAME))
      .setExtra(Creative.EXTRA_BODY_TEMPLATE_ID, data.bodyTemplateId)
      .setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, data.headTemplateId)
      .setTask("head", data.headTaskResult)
      .setTask("body", data.bodyTaskResult)
      .setAsSelected(true)
      .setAsRefreshed(true);

    window.processingManager.processing.addCreative(nextCreative);
    window.processingManager.update();
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPage-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  handleToggleWatermak = () => debounce("ResultPage_handleToggleWatermak", 100, () => {
    clientStorage.setProWatermarkTooltipIsHidden(true);
    this.showRemoveLogoModal();
  });

  handleHideBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(true);
    this.handleProcessingChanged();
  };

  handleShowBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(false);
    this.handleProcessingChanged();
  };

  handleLabsButtonClick = () => debounce("ResultPage_handleLabsButtonClick", 100, () => {
    this.props.history.push(prefixRouteForSite(generatePath(routes.PROCESSING), "labs"), {file: this.state.file});
  });

  handleClassicButtonClick = () => debounce("ResultPage_handleClassicButtonClick", 100, () => {
    this.props.history.push(prefixRouteForSite(generatePath(routes.PROCESSING), "classic"), {file: this.state.file});
  });

  handleUniteButtonClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(UNITE_COM_TAB_LINK + "&utm_content=webview");
    }
  };

  handleShowWatermak = () => {
    clientStorage.setProWatermarkShouldBeRemoved(false);
    this.handleProcessingChanged();
  };

  handleHideWatermak = () => {
    clientStorage.setProWatermarkRemoveChoiceAt(Date.now());
    clientStorage.setProWatermarkShouldBeRemoved(true);
    this.handleProcessingChanged();
  };

  handleTabClick = (e, group) => {
    const creativesInGroup = this.state.creatives.filter((creative) => creative.group === group);
    const selectedCreative = creativesInGroup.find((creative) => creative.is_selected);
    const _selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(group);

    if (_selectedCreative.hasExtra(Creative.EXTRA_KEEP_PENDING)) {
      _selectedCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      window.processingManager.update();
    }

    const isCtrlKey = window.clientConfig.isWebDesktop ? e.ctrlKey : true;
    if (isCtrlKey) {
      this.devModalClicks = this.devModalClicks || [];
      if (this.devModalLatestTabClick !== group) {
        this.devModalClicks = [];
      }

      this.devModalClicks.push(Date.now());
      this.devModalClicks = this.devModalClicks.filter((ts) => {
        return Date.now() - ts <= 10_000;
      });

      this.devModalLatestTabClick = group;

      if (this.devModalClicks.length >= 10) {
        this.devModalClicks = [];
        this.context.pushModal(<CreativeDumpModal
          key="ResultPage_CreativeDumpModal"
          creative={selectedCreative}
        />);
      }
    }

    if (this.state.tab === group) {
      return;
    }

    if (window.clientConfig.isWebview) {
      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
        search: `?t=${group}`
      });
    }

    logEvent(userEvents.TAB_SELECT, {
      ...getShareEventProps(this.state, selectedCreative),
      is_finished: !!(selectedCreative && creativeUtils.creativeIsFinished(selectedCreative)),
      is_processed: !!(selectedCreative && creativeUtils.creativeIsProcessed(selectedCreative)),
      group,
      group_is_empty: creativesInGroup.length === 0,
      prev_group: this.state.tab,
    });

    this.setState({tab: group});
  };

  handleCreativeClick = (e) => {
    // this.__creativeClicks = this.__creativeClicks || 0;
    // this.__creativeClicks++;
    //
    // if (!window.clientConfig.isWebview) {
    //   return;
    // }
    //
    // if (!window.appConfig.webview.testerClientsIds.includes(window.clientConfig.webviewClientId.toLowerCase())) {
    //   return;
    // }
    //
    // if (this.__creativeClicks >= 5) {
    //   this.context.pushModal(<DeveloperModal key="ResultPage_DeveloperModal" />);
    // }
  };

  handleHomeButtonClick = (e) => {
    logEvent(userEvents.HOME_BUTTON_CLICK, {page: "result"});

    if (this.props.site === "labs") {
      this.props.history.replace(routes.LABS_INDEX);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  };

  renderTab = (group) => {
    const creative = this.state.creatives.find((creative) => creative.group === group && creative.is_selected);
    let previewUrl = undefined;

    if (creative && creative._.hasExtra(Creative.EXTRA_PREVIEW_URL)) {
      previewUrl = creative._.getExtra(Creative.EXTRA_PREVIEW_URL);
    }

    if (creative && creativeUtils.creativeIsProcessed(creative)) {
      previewUrl = creative._.getFile("template") || creative._.getFile("result");

      if (!creative.extra.creative_layout_is_hidden && creative._.getFile("layout")) {
        previewUrl = creative._.getFile("layout");
      }
    }

    if (group === creativeGroups.CARTOON_ANIM) {
      previewUrl = undefined;
    }

    if (group === creativeGroups.CARTOON_VECTOR_BODY) {
      if (creative) {
        if (!creativeUtils.creativeIsFinished(creative)) {
          previewUrl = undefined;
          const bodyId = creative._.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID, 0);
          if (bodyId > 0) {
            const gender = genders[this.state.fileGender] || genders.male;
            const body = getBodiesListByGroup(group).find((t) => t.id === bodyId && t.gender === gender);
            if (body) {
              previewUrl = pwAssetUrl(`templates-previews/${body.id}${gender[0]}.png`);
            }
          }
        }
      }
    }

    return <TabItemView
      key={group}
      group={group}
      isActive={this.state.tab === group}
      previewUrl={previewUrl}
      creative={creative}
      onClick={this.handleTabClick}
      onPreviewImageLoadStart={() => {
        window.creativesLog.log(creative.id, {event: "image_preview_load_start"});
      }}
      onPreviewImageLoaded={() => {
        window.creativesLog.log(creative.id, {event: "image_preview_load_finish"});
      }}
    />;
  };

  renderTabContainer = (commonTabSettings) => {
    commonTabSettings.group = this.state.tab;
    commonTabSettings.withHD = [
      creativeGroups.DISNEY,
      creativeGroups.DISNEY_2D,
      creativeGroups.ARCHER,
      creativeGroups.STROKES,
    ].indexOf(commonTabSettings.group) > -1;

    commonTabSettings.canBeRefreshed = getCreativesConfigs(this.props.site)
      .filter((config) => config.group === commonTabSettings.group).length > 1;

    commonTabSettings.groupPosition = this.state.tabs.indexOf(commonTabSettings.group) + 1;

    switch (this.state.tab) {
      case creativeGroups.VECTOR:
        return this.renderVectorTab(commonTabSettings);
      case creativeGroups.CARTOON_VECTOR_BODY:
        return this.renderBodyTab(commonTabSettings);
      case "labs":
        return this.renderLabsTab({});
      case "unite":
        return this.renderUniteTab({});
      case "classic":
        return this.renderClassicTab({});
      default: {
        return this.renderSimpleTab(commonTabSettings);
      }
    }
  };

  renderSimpleTab = (props) => <SimpleTab {...props} />;
  renderVectorTab = (props) => <VectorTab {...props} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
  renderBodyTab = (props) => <BodyTab {...props} onTemplateSelect={this.handleBodyRefreshTemplateSelect} />;
  renderLabsTab = (props) => <LabsTab {...props} onButtonClick={this.handleLabsButtonClick} />;
  renderClassicTab = (props) => <ClassicTab {...props} onButtonClick={this.handleClassicButtonClick} />;
  renderUniteTab = (props) => <UniteTab {...props} onButtonClick={this.handleUniteButtonClick} />;

  getViewParams = () => {
    const commonTabSettings = {
      file: this.state.file,
      creatives: this.state.creatives,
      onImageLoaded: this.handleImageLoaded,
      onCreativeClick: this.handleCreativeClick,
      onDownloadButtonClick: (e, creative, withHD) => this.handleDownloadButtonClick(e, creative, withHD),
      onRefreshButtonClick: this.handleRefreshButtonClick,
      onShowWatermarkButtonClick: this.handleShowWatermak,
      onHideWatermarkButtonClick: this.handleToggleWatermak,
      onHideBeforePhotoButtonClick: this.handleHideBeforePhotoButtonClick,
      onShowBeforePhotoButtonClick: this.handleShowBeforePhotoButtonClick,
      onRetryButtonClick: this.handleRetryButtonClick,
      canBeRefreshed: true,
      onFileSelected: this.handleFileSelected,
      site: this.props.site,
      onSubTabButtonClick: this.handleSubTabButtonClick,
    };

    const showTabs = this.state.tabs.slice();

    if (this.props.site === "labs") {
      showTabs.splice(0, 0, "classic");
    } else if (this.props.site === "classic") {
      //showTabs.push("unite");
      // showTabs.push("labs");
    }

    return {commonTabSettings, showTabs};
  };

  renderView = ({commonTabSettings, showTabs}) => {
    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <div className="container">
        <HomeButton onClick={this.handleHomeButtonClick} />
      </div>
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingV2 site={this.props.site} />;
    }

    const params = this.getViewParams();

    return this.renderView(params);
  }
}

ResultPage.contextType = AppContext;

export function getShareEventProps(state, creative, hdCreative) {
  const props = {
    file_id: state.file.id,
    group: state.tab,
    default_tab_group: state.defaultTab,
    position: state.tabs.indexOf(state.tab) + 1,
    photo_gender: state.fileGender,
  };

  if (creative) {
    props.template_id = creative.template.id;
    props.alias = creative.alias;
    props.is_refresh = creative._.isRefreshed;
    props.creative_url = (creative.file && creative.file.url) || "";
    props.original_is_hide = !!creative.extra.creative_layout_is_hidden;

    if (creative.group === creativeGroups.CARTOON_VECTOR_BODY) {
      const bodyTemplateId = creative._.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID);
      const bodyConfig = getBodiesListByGroup(creative.group).find((body) => body.id === bodyTemplateId);

      props.body_template_id = bodyTemplateId;
      if (bodyConfig) {
        props.body_is_hidden = !!bodyConfig.isHidden;
      }
    }
  }

  if (hdCreative) {
    props.sr_template_id = hdCreative.template.id;
  }

  return props;
}