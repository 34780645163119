import styled from "styled-components";
import {skeletonAnimation} from "./mixins";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
  max-height: 560px;
  height: 100%;
  overflow: hidden;
  margin-bottom: ${props => props.mb}px;

  @media all and (max-width: 991px) {
    gap: 8px;
  }

  @media (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);

    >div:last-child {
      display: none;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  @media all and (max-width: 991px) {
    gap: 8px;
  }
`;

export const ItemLink = styled.div`
  position: relative;
  display: block;
  max-width: 362px;
  min-width: 175px;
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
  will-change: transform;
  background: #eee;
  padding-top: ${props => props.padding};
  
  ${skeletonAnimation};

  @media all and (max-width: 991px) {
    border-radius: 16px;
  }
`;