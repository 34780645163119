import React from 'react';
import AppContext from "../contexts/AppContext";
import {resolveCreativeConfigByGroup} from "../photolab/config";
import {creativeGroups} from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import {getHandlerByName} from "../photolab/handlers";

const celebsList = [
  "000",
  "001",
  "006",
  "007",
  "008",
  "011",
  "015",
  "016",
  "019",
  "020",
  "021",
  "030",
  "031",
  "033",
  "036",
  "038",
  "040",
  "041",
  "048",
  "049",
  "051",
  "057",
  "060",
  "062",
  "063",
  "065",
  "066",
  "068",
  "071",
  "073",
  "074",
  "075",
  "076",
  "079",
  "080",
  "081",
  "082",
  "083",
  "084",
  "085",
  "086",
  "087",
  "089",
  "091",
  "092",
  "093",
  "094",
  "095",
  "097",
  "098",
  "100",
  "101",
  "102",
  "103",
  "105",
  "107",
  "108",
  "109",
  "110",
  "112",
  "114",
  "115",
  "116",
  "117",
  "119",
  "120",
  "121",
  "123",
  "124",
  "126",
  "128",
  "129",
  "130",
  "133",
  "136",
  "141",
  "142",
  "146",
  "147",
  "151",
  "152",
  "153",
  "154",
  "155",
  "156",
  "157",
  "158",
  "159",
  "160",
  "161",
  "166",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "175",
  "176",
  "178",
  "179",
  "181",
  "184",
  "191",
  "192",
  "194",
  "195",
  "196",
  "197",
  "199",
  "200",
  "202",
  "207",
  "211",
  "218",
  "219",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "227",
  "228",
  "230",
  "232",
  "237",
  "238",
  "239",
  "247",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "260",
  "263",
  "275",
  "276",
  "277",
  "278",
  "279",
  "280",
  "282",
  "283",
  "284",
  "285",
  "286",
  "287",
  "288",
  "289",
  "290",
  "292",
  "293",
  "294",
  "296",
  "298",
  "299",
  "314",
  "317",
  "320",
  "323",
  "325",
  "326",
  "327",
  "330",
  "331",
  "332",
  "334",
  "335",
  "338",
  "339",
  "343",
  "344",
  "345",
  "351",
  "357",
  "358",
  "362",
  "363",
  "371",
  "373",
  "387",
  "388",
  "389",
  "390",
  "391",
  "392",
  "393",
  "395",
  "396",
  "397",
  "400",
  "406",
  "414",
  "415",
  "416",
  "417",
  "418",
  "419",
  "420",
  "422",
  "423",
  "424",
  "429",
  "439",
  "440",
  "451",
  "453",
  "454",
  "456",
  "457",
  "458",
  "459",
  "461",
  "462",
  "463",
  "464",
  "465",
  "466",
  "468",
  "469",
  "470",
  "471",
  "472",
  "475",
  "479",
  "481",
  "485",
  "486",
  "487",
  "492",
  "493",
  "494",
  "495",
  "497",
  "498",
  "499",
  "500",
  "501",
  "502",
  "504",
  "505",
  "506",
  "510",
  "511",
  "512",
  "513",
  "514",
  "515",
  "516",
  "517",
  "518",
  "520",
  "521",
  "522",
  "524",
  "525",
  "529",
  "531",
  "532",
  "533",
  "535",
  "537",
  "538",
  "540",
  "541",
  "542",
  "543",
  "544",
  "546",
  "548",
  "549",
  "551",
  "552",
  "553",
  "555",
  "556",
  "557",
  "559",
  "560",
  "562",
  "565",
  "566",
  "568",
  "569",
  "570",
  "576",
  "578",
  "579",
  "580",
  "589",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "600",
  "601",
  "602",
  "603",
  "604",
  "605",
  "607",
  "609",
  "610",
  "611",
  "613",
  "617",
  "618",
  "619",
  "622",
  "623",
  "624",
  "626",
  "628",
  "629",
  "630",
  "635",
  "637",
  "638",
  "639",
  "641",
  "643",
  "646",
  "647",
  "648",
  "650",
  "651",
  "652",
  "653",
  "654",
  "655",
  "659",
  "663",
  "664",
  "665",
  "667",
  "668",
  "670",
  "675",
  "676",
  "680",
  "681",
  "684",
  "685",
  "686",
  "687",
  "689",
  "690",
  "691",
  "692",
  "693",
  "695",
  "697",
  "698",
  "699",
  "701",
  "702",
  "707",
  "708",
  "709",
  "712",
  "713",
  "714",
  "715",
  "717",
  "720",
  "721",
  "722",
  "723",
  "724",
  "725",
  "726",
  "727",
  "728",
  "732",
  "733",
  "737",
  "739",
  "742",
  "744",
  "745",
  "747",
  "750",
  "752",
  "753",
  "756",
  "757",
  "758",
  "759",
  "760",
  "762",
  "763",
  "764",
  "765",
  "766",
  "768",
  "771",
  "773",
  "775",
  "776",
  "777",
  "779",
  "780",
  "782",
  "783",
  "784",
  "785",
  "786",
  "787",
  "788",
  "789",
  "790",
  "791",
  "792",
  "793",
  "794",
  "795",
  "796",
  "798",
  "799",
  "800",
  "801",
  "803",
  "804",
  "805",
  "806",
  "816",
  "817",
  "818",
  "821",
  "822",
  "823",
  "827",
  "828",
  "830",
  "831",
  "832",
  "833",
  "837",
  "838",
  "839",
  "840",
  "841",
  "842",
  "843",
  "844",
  "846",
  "847",
  "848",
  "849",
  "850",
  "852",
  "854",
  "856",
  "857",
  "858",
  "863",
  "864",
  "865",
  "866",
  "868",
  "869",
  "871",
  "872",
  "874",
  "876",
  "879",
  "881",
  "883",
  "884",
  "885",
  "886",
  "887",
  "889",
  "890",
  "893",
  "894",
  "895",
  "896",
  "897",
  "898",
  "899",
  "901",
  "902",
  "903",
  "904",
  "905",
  "907",
  "908",
  "909",
  "911",
  "912",
  "914",
  "915",
  "916",
  "917",
  "918",
  "919",
  "920",
  "921",
  "922",
  "923",
  "929",
  "930",
  "935",
  "938",
  "939",
  "940",
  "941",
  "945",
  "950",
  "954",
  "955",
  "957",
  "958",
  "959",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "969",
  "970",
  "972",
  "974",
  "977",
  "978",
  "979",
  "980",
  "981",
  "982",
  "984",
  "985",
  "987",
  "988",
  "989",
  "990",
  "993",
  "994",
  "995",
  "998",
];

export default class CelebritiesPage extends React.Component {

  state = {
    group: null,
    processings: [],
    resultsUrls: [],
  }

  createProcessing = (fileUrl) => {
    const processing = new Processing();

    processing.setFile({id: Date.now(), url: fileUrl});
    processing.setSite("classic");
    processing.setGroups([creativeGroups.CROP, creativeGroups.GENDER, this.state.group]);
    processing.setLanguage("en");
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setWatermarkName("default_webview");
    processing.groups.forEach((group) => {
      processing
        .addCreative(new Creative()
          .configureByConfig(resolveCreativeConfigByGroup("classic", group))
          .setAsSelected(true));
    });

    processing.creatives.forEach((creative) => {
      const handler = getHandlerByName(creative.handler);
      if (handler === null) {
        throw new Error("Unrecognized handler name: " + creative.handler);
      }

      if (!creative.hasExtra(Creative.EXTRA_STARTED_AT)) {
        creative.setExtra(Creative.EXTRA_STARTED_AT, Date.now());
      }

      handler(processing, creative)
        .then(this.handleCreativeFinish)
        .catch(this.handleCreativeFinish);
    });

    return processing;
  };

  handleCreativeFinish = (creative) => {
    if (creative.group === this.state.group) {
      this.setState({processings: this.state.processings.slice()});
      this.startNextProcessing();

      if (creative.isProcessed) {
        const resultsUrls = this.state.resultsUrls.slice();
        resultsUrls.push(creative.resultUrl);
        this.setState({resultsUrls});
      }
    }
  };

  startNextProcessing = (amount = 1) => {
    const pendingProcessingItems = this.state.processings
      .filter((item) => !item.processing)
      .slice(0, amount);

    if (pendingProcessingItems.length > 0) {
      pendingProcessingItems.forEach((item) => {
        item.processing = this.createProcessing(item.fileUrl);
      });
      this.setState({_: Date.now()});
    }
  };

  handleStartClick = () => {
    this.setState({
      processings: celebsList.map((fileName) => {
        return {
          fileUrl: `https://storage-gocreepy.photo-cdn.net/toonme/static/samples/originals/${fileName}.jpg`,
          processing: null
        };
      }),
    }, () => {
      this.startNextProcessing(10);
    });
  };

  render() {
    if (this.state.processings.length === 0) {
      return <div>
        <select onChange={(e) => this.setState({group: e.target.value})} defaultValue={this.state.group}>
          <option />
          {Object.values(creativeGroups).map((g) => <option key={g} value={g} children={g} />)}
        </select>
        <button onClick={this.handleStartClick}>Start</button>
      </div>;
    }

    const totalProcessings = this.state.processings.length;
    const startedProcessings = this.state.processings.filter((i) => !!i.processing);
    const finishedProcessings = startedProcessings.filter((i) => {
      const c = i.processing.getSelectedCreativeInGroup(this.state.group);
      return c && c.isFinished;
    })

    return <div>
      <p>STARTED processings: {startedProcessings.length}/{totalProcessings}</p>
      <p>FINISHED processings: {finishedProcessings.length}/{startedProcessings.length}</p>
      <hr />
      <textarea
        cols={160}
        rows={20}
        readOnly
        value={this.state.resultsUrls.join("\n")}
      />
    </div>;
  }
}

CelebritiesPage.contextType = AppContext;