import styled from "styled-components";
import {skeletonAnimation} from "./mixins";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 40px;
  gap: 8px;

  @media all and (max-width: 991px) {
    margin-bottom: 24px;
  }
`;

export const PreviewStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1;
  position: relative;

  @media(max-width: 540px) {
    gap: 16px;
  }

  @media(max-width: 380px) {
    flex-direction: column;
  }
`;

export const PreviewImageLink = styled.div`
  position: relative;
  width: 100%;
  max-width: 136px;
  min-width: 88px;
  background: #eee;
  border-radius: 24px;
  overflow: hidden;
  will-change: transform;
  z-index: 1;
  
  ${skeletonAnimation};
  
  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @media(max-width: 991px) {
    border-radius: 16px;
  }
`;

export const PreviewUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .reposts_counter {
    position: relative;
    max-width: 224px;
    min-width: 112px;
    width: 100%;
    height: 48px;
    background: #eee;

    ${skeletonAnimation};

    @media (max-width: 991px) {
      height: 24px;
    };

    @media (max-width: 540px) {
      width: 112px;
    };
  };
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UserPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  
  ${skeletonAnimation};
  
  @media all and (max-width: 991px) {
    gap: 8px;
  }
`;

export const UserAvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  will-change: transform;
  border-radius: 50%;
  background: #eee;

  @media all and (max-width: 991px) {
    width: 24px;
    height: 24px;
  }
`;

export const UserProfileItem = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    background: none;
    transition: 0.3s;
    background: #eee;

    p {
      max-width: 224px;
      min-width: 112px;
      width: 100%;
      height: 24px;
    }

    @media all and (max-width: 991px) {
      gap: 8px;

      svg {
        width: 8px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
`;

export const TooltipText = styled.div`
  position: relative;
  max-width: 224px;
  min-width: ${props => !props.ending ? 112 : 60}px;
  width: ${props => !props.ending ? "100%": "60px"};
  height: ${props => !props.ending ? 24 : 12}px;
  background: #eee;
  
  ${skeletonAnimation};
  
  @media(max-width: 540px) {
    width: ${props => !props.ending ? 112 : 60}px;
  }
`;

export const ShareButton = styled.div`
  background: #eee;
  width: 100%;
  max-width: 186px;
  min-width: 94px;
  height: 56px;
  position: relative;
  border-radius: 16px;
  will-change: transform;

  ${skeletonAnimation};
  
  @media(max-width: 991px) {
    height: 48px;
    width: 94px;
  }
`;