import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin-bottom: 40px;
`;

export const Loader = styled.div`
  &.loader-content {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 56px;
    background: #4d5ef6;
    border-radius: 50%;
    padding: 0;

    span {
      display: flex;
      width: 40px;
      height: 40px;
      border: solid 2px rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      position: relative;
      animation: spinning 1s 0.25s linear infinite;

      &::after {
        content: "";
        display: block;
        border: solid 2px transparent;
        border-top-color: #fff;
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    @keyframes spinning {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;