module.exports = {
  "strings": {
    "try_another_photo": "ลองรูปอื่น",

    "landing__title_part_1": "วู้ ฮู้!",
    "landing__title_part_2": "ถึงเวลาสานฝันในโลกการ์ตูนของคุณได้หลาย ๆ ครั้งแล้ว",
    "landing__subtitle": "เพียงมอบภาพของคุณให้ AI 😜",
    "landing__select_photo_button": "เลือกรูป",
    "landing__get_the_app_button": "รับแอป",
    "loading__uploading": "กำลังอัพโหลด",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "ตอนนี้คุณกำลังอยู่ในช่วงการทดลองพิเศษ สำหรับรูปแบบเดิม โปรดเปลี่ยนกลับไปยังเวอร์ชันหลักด้านล่างนี้",
    "landing__labs_back_button": "กลับไปยัง ToonMe Classic",
    "landing__labs_choice": "หรือลองใช้รูปแบบทดลองของเราที่ <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "เรากำลังทดสอบรูปแบบใหม่ ๆ และขอชวนให้คุณมาลองใช้ดูก่อน! เปลี่ยนกลับไปเป็นรูปแบบเดิมได้ตลอดเวลา",
    "result__labs_tab_button": "ลองดู",
    "result__tab_classic_title": "สนุกไปกับเอฟเฟกต์ทดลองต่าง ๆ?",
    "result__tab_classic_info": "อยู่ที่นี่หรือเปลี่ยนกลับไปยังเวอร์ชันหลักของ ToonMe",
    "result__tab_classic_btn": "กลับไปยัง ToonMe Classic",
    "result_loader__title": "เกือบเสร็จแล้ว",

    "processing_text_1": "ทำให้ผิวของคุณเป็นสีเหลือง... 🖌️",
    "processing_text_2": "สร้างการ์ตูนจากคุณสมบัติใบหน้าของคุณ...",
    "processing_text_3": "คุณดูเหมือนเจ้าหญิง Disney เลย ล้อเล่นน่า หรือยังไง?.. 🙈",
    "processing_text_4": "นี่คือที่ที่ความฝันกลายเป็นการ์ตูน 🦄",
    "processing_text_5": "สร้างภาพของคุณเป็นภาพการ์ตูน!",
    "processing_text_6": "การสร้างภาพการ์ตูนกำลังดำเนินการ!",
    "processing_text_7": "Walt Disney จะชอบ! ✨",

    "collage__select_photo": "ลองทำกับภาพถ่ายของคุณ",
    "collage__download": "ดาวน์โหลดแบบ HD",
    "collage__refresh": "รีเฟรช",
    "more_styles": "รูปแบบที่มากขึ้น",

    "download_modal__title": "อย่าหยุดเพียงเท่านี้!",
    "download_modal__message": "แชร์บนสังคมออนไลน์ ร่วมกับฝูงชน!",
    "download_modal__message_wait": "กำลังสร้างผลลัพธ์แบบ HD...",
    "download_modal__message_ready": "เวอร์ชัน HD พร้อมแล้ว เริ่มการดาวน์โหลด...",
    "download_modal__dismiss_button": "เข้าใจแล้ว",
    "download_modal__download_button": "ดาวน์โหลดอีกครั้ง",

    "error__error": "ข้อผิดพลาด",

    "internal_error": "เกิดข้อผิดพลาด...",
    "error_overload_webview": "เราขออภัยเป็นอย่างยิ่ง เราประสบปัญหาในการโหลดของเซิร์ฟเวอร์ที่ค่อนข้างสูงเนื่องจากการดาวน์โหลดแอปที่เพิ่มขึ้น โปรดลองใช้แถบฟีดแทน หรือตรวจสอบอีกครั้งในภายหลัง เรากำลังทำงานอย่างหนักเพื่อแก้ไขปัญหาทุกอย่างให้แล้วเสร็จในไม่ช้า",
    "error_overload_web": "เราขออภัยเป็นอย่างยิ่ง เราประสบปัญหาในการโหลดของเซิร์ฟเวอร์ที่ค่อนข้างสูงเนื่องจากการดาวน์โหลดแอปที่เพิ่มขึ้น โปรดตรวจสอบอีกครั้งในภายหลัง เรากำลังทำงานอย่างหนักเพื่อแก้ไขปัญหาทุกอย่างให้แล้วเสร็จในไม่ช้า",

    "rateapp_dialog__title": "ชอบ ToonMe หรือไม่?",
    "rateapp_dialog__message-bold": "ให้ดาวกับแอปและช่วยผู้ใช้คนอื่น ๆ ค้นพบและชอบแอปเหมือนกัน!",
    "rateapp_dialog__message": "ป.ล. สิ่งนี้จะยังช่วยให้เราสามารถกู้คืนคะแนนที่ได้รับผลกระทบจากปัญหาเซิร์ฟเวอร์หลังจากที่มีการกระจายข่าวเกี่ยวกับ ToonMe 🙏",
    "rateapp_dialog__rate_button": "ให้คะแนน!",
    "rateapp_dialog__skip_button": "ข้าม",

    "btn_store__title":"<span class=\"btn-store-title\">ดาวน์โหลดบน</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "ยกเลิก & บันทึกด้วยคุณภาพระดับมาตรฐาน",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "ติดต่อเรา",
    "btn_back": "back",

    "vector_tab_refresh__title": "ใกล้เสร็จแล้ว!",
    "vector_tab_refresh__subtitle": "เลือกภาพถ่าย",
    "vector_tab_refresh__subtitle2": "เลือกสไตล์ส่วนหัว",

    "updated_at": "อัปเดตพร้อมกับเอฟเฟกต์ใหม่ในวันที่ <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "คำเตือน!",
    "warning_clones_link": "ระวังแอปปลอม",

    "snap_dialog__title": "ก่อน-หลัง หรือแค่ TOON?",
    "snap_dialog__message": "เลือกว่าจะแชร์บนสแนปแชตอย่างไร!",
    "snap_dialog__share_button": "แชร์",
    "snap_dialog__back_button": "ย้อนกลับ",

    "title_contacts_form": "จากนักพัฒนาซอฟต์แวร์ของ Photo Lab แอปถ่ายภาพสำหรับ iOS และ Android ชั้นนำที่มียอดดาวน์โหลด 260ล้าน – และยังมีจำนวนเพิ่มขึ้นเรื่อย ๆ…",

    "toggle_watermark_tooltip__text": "แตะที่โลโก้เพื่อเอาออกไป",
    "toggle_watermark_on": "เปิด/ปิดโลโก้",
    "toggle_watermark_off": "เปิด/ปิดโลโก้",

    "remove_logo_modal__title": "จะเก็บหรือไม่เก็บ...",
    "remove_logo_modal__message": "เฮ้ คุณทราบหรือไม่ว่าโลโก้คือส่วนหนึ่งของการออกแบบเอฟเฟกต์นี้? ซึ่งเราจะขอบคุณเป็นอย่างยิ่งหากคุณเลือกที่จะเก็บไว้",
    "remove_logo_modal__btn_cancel": "เก็บโลโก้",
    "remove_logo_modal__btn_remove": "เอาออก",

    // v2
    "landing__toonme_on_instagram": "#ToonMe บน Instagram",

    "creative_failed_message": "ขออภัย มีบางอย่างผิดปกติกับ TOON นี้ เลือกรูปแบบอื่นหรือลองอีกครั้ง",
    "creative_failed_retry_btn": "ลองใหม่",

    "refresh_body__btn_body": "เลือกสไตล์รูปร่าง",
    "refresh_body__btn_head": "เลือกสไตล์ส่วนหัว",
    "button_choice_gender_male": "สำหรับผู้ชาย",
    "button_choice_gender_female": "สำหรับผู้หญิง",
    "button_choice_gender_text": "รูปแบบ",

    "button_original_toggler__show": "แสดงภาพต้นฉบับ",
    "button_original_toggler__hide": "ซ่อนภาพต้นฉบับ",
  },
};
