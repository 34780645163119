import React from "react";
import {MainHeader} from "../../../components/MainHeader/MainHeader";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import photolabSocialApiHelper from "../../../helpers/photolab-social-api.helper";
import * as UPS from "./UserPage.styled"
import i18n from "../../../i18n";
import {AppButtons, themeKeys} from "../../../components/AppButtons";
import { AnimateWrapper } from "../HomePage.styled";
import routes from "../../../routes";
import {LoadMoreButton} from "../../../components/LoadMoreButton/LoadMoreButton";
import {SkeletonHeader} from "../../../components/Skeleton/SkeletonHeader";
import {SkeletonImages} from "../../../components/Skeleton/SkeletonImages";

export default class UsersPage extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isReady: false,
      feed: [],
      feedNext: [],
      feedIsLoading: false,
      user: null,
    };
  }

  componentDidMount() {
    this.handleLoadUser();
  }

  handleLoadUser = async () => {
    const userId = this.props.match.params.id;

    try {
      const data = await photolabSocialApiHelper.fetchUser(userId);
      const offsetId = data.feed.last()?.id;
      const feedNext = offsetId
        ? await photolabSocialApiHelper.fetchUserFeed(userId, offsetId).catch((e) => [])
        : [];

      this.setState({
        isReady: true,
        user: data.user,
        feed: data.feed,
        feedNext: feedNext,
      });
    } catch (e) {
      console.error(e)
      window.location.href = routes.NOT_FOUND_PAGE;
    }
  }

  handleLoadFeed = async () => {
    this.setState({
      feedIsLoading: true,
    });

    const userId = this.props.match.params.id;
    const offsetId = this.state.feedNext.last()?.id

    const feedNext = await photolabSocialApiHelper.fetchUserFeed(userId, offsetId).catch((e) => []);

    this.setState({
      feed: [...this.state.feed, ...(this.state.feedNext || [])],
      feedNext: feedNext,
      feedIsLoading: false,
    });
  }

  render() {
    const {feed, feedIsLoading, feedNext, user, isReady} = this.state;

    return <AnimateWrapper className={`${isReady ? "ready" : ""}`}>
      {!isReady ?
        <React.Fragment>
          <SkeletonHeader ending={1} />
          <SkeletonImages />
        </React.Fragment> :
        <React.Fragment>
          <MainHeader user={user} ending={1} isHeader={1}/>
          <ImageGallery combos={feed} page={routes.COMBO_PAGE} isReady={isReady}/>
        </React.Fragment>}
          <LoadMoreButton
            animation={feedIsLoading ? 1 : 0}
            onClick={this.handleLoadFeed}
            hidden={feed.length === 0 || feedNext.length === 0}
          />
          <UPS.Text dangerouslySetInnerHTML={{__html: i18n.t("user_page__text")}} />
          <AppButtons theme={themeKeys.dark} center={1} mb={160} />
    </AnimateWrapper>;
  }
}