module.exports = {
  "strings": {
    "try_another_photo": "try with another photo",
    "change_photo": "change photo",

    "landing__title_part_1": "Woo Hoo!",
    "landing__title_part_2": "It’s time to live your <span>cartoon</span> dream, many times.",
    "landing__subtitle": "Let our AI cartoonize your photos 😜",
    "landing__select_photo_button": "Choose photo",
    "landing__get_the_app_button": "Get the app",
    "loading__uploading": "Uploading...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "You are at a special experimental spot now. For original styles switch back to the main version below.",
    "landing__labs_back_button": "Back to ToonMe Classic",
    "landing__labs_choice": "Or try our experimental styles on <span>ToonMe&nbsp;LABS</span>",

    "landing__unite_choice": "It’s time to <span>unite.com</span> 😍 Get your first online group selfie",
    "landing__unite_button": "go to <span>unite.com</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "We’re experimenting with new styles and you are in for a sneak peek! Switch back to the original styles at any moment. ;)",
    "result__labs_tab_button": "Let’s try",
    "result__unite_tab_title": "Toonify group of people with our new unite.com",
    "result__unite_tab_button": "go to <span>unite.com</span>",
    "result__tab_classic_title": "Enjoying experimental effects?",
    "result__tab_classic_info": "Stay here or switch back to the classic version of ToonMe",
    "result__tab_classic_btn": "Back to ToonMe Classic",
    "result_loader__title": "We’re almost done...",

    "processing_text_1": "Yellowing your skin...🖌️",
    "processing_text_2": "Cartooning your facial features...",
    "processing_text_3": "You look like a Disney princess. 🤣 Kidding. Or not?..🙈",
    "processing_text_4": "This is the place where dreams come TOON 🦄",
    "processing_text_5": "Fine-TOONing your photo!",
    "processing_text_6": "Toonification is in progress!",
    "processing_text_7": "Walt Disney would have liked it! ✨",

    "collage__select_photo": "Try with your photo",
    "collage__download": "Download in HD",
    "collage__refresh": "Refresh",
    "more_styles": "More styles",

    "banner_title": "We are hiring!",
    "banner_text1": "Expert in <span>Japanese</span> app stores and app monetisation wanted",
    "banner_text2": "Would it be you?",
    "banner_text3": "Contact us at",

    "banner_ad_title": "Our YELLOW has just got totally WOOHOO!",
    "banner_ad_text": "Celebrating new season together",
    "banner_ad_title_mob": "Our YELLOW has just got<br> totally WOOHOO!",

    "download_modal__title": "Don’t stop here!",
    "download_modal__message": "Share it on socials, join the crowd!",
    "download_modal__message_wait": "Creating the result in HD…",
    "download_modal__message_ready": "HD version is ready, download started…",
    "download_modal__dismiss_button": "Got it!",
    "download_modal__download_button": "Download again",

    "error__error": "Error",

    "internal_error": "An error occurred...",
    "error_overload_webview": "We are terribly sorry, but our servers experience too much of a load currently due to skyrocketing app downloads.<br />Please, try to use <span class='apptab-feed-icon'></span>Toon Effects tab instead or come back a little later. We are working hard to fix everything ASAP.",
    "error_overload_web": "We are terribly sorry, but our servers experience too much of a load currently due to skyrocketing app downloads.<br />Please, come back a little later. We are working hard to fix everything ASAP.",

    "rateapp_dialog__title": "Love ToonMe?",
    "rateapp_dialog__message-bold": "Give stars to the app & help other users discover and love it too!",
    "rateapp_dialog__message": "PS. It’ll also help us to restore the ratings, affected by server issues after ToonMe went viral.",
    "rateapp_dialog__rate_button": "Rate!",
    "rateapp_dialog__skip_button": "Skip",

    "btn_store__title":"<span class=\"btn-store-title\">Download on the</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_store__huawei":"App Gallery",
    "btn_cancel": "Cancel & save <br />in standard quality",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Contact us",
    "btn_back": "back",

    "button_choice_gender_female": "female",
    "button_choice_gender_male": "male",
    "button_choice_gender_text": "styles",

    "vector_tab_refresh__title": "Almost there!",
    "vector_tab_refresh__subtitle": "Choose a portrait style.",
    "vector_tab_refresh__subtitle2": "Choose head style",

    "updated_at": "Updated with new effects on <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "WARNING!",
    "warning_clones_link": "BEWARE OF CHEAP CLONES!",

    "snap_dialog__title": "Before-after or just TOON?",
    "snap_dialog__message": "Choose how you snap it!",
    "snap_dialog__share_button": "Share",
    "snap_dialog__back_button": "Back",

    "title_contacts_form": "From the developers of Photo Lab, the world’s leading iOS and Android photography app with 260M downloads – and counting…",

    "toggle_watermark_tooltip__text": "Tap the logo to remove it",
    "toggle_watermark_on": "Logo on",
    "toggle_watermark_off": "Logo off",

    "remove_logo_modal__title": "To keep or not to keep… 🤔",
    "remove_logo_modal__message": "Hey, did you know that the logo is a part of this effect’s design? It’ll make us happy if you choose to keep it.",
    "remove_logo_modal__btn_cancel": "Keep the logo",
    "remove_logo_modal__btn_remove": "Remove anyway",

    "clones_dialog__title": "Join us in fighting back the clone!",
    "clones_dialog__message": "Love ToonMe? Help your peers find and use only original app and fight an evil clone that impersonates us.",
    "clones_dialog__accept_button": "I wanna help 🙌",
    "clones_dialog__skip_button": "Skip",

    "btn_photo_lab": "by Photo Lab",
    "updated_at_labs": "Freshly baked on {{date}}",

    // v2
    "landing__toonme_on_instagram": "#ToonMe on Instagram",
    "likes": "likes",
    "landing__labs_button": "ToonMe LABS",
    "btn_cancelV2": "Cancel",
    "toggle_watermark_on_desktop": "RETURN LOGO",
    "toggle_watermark_off_desktop": "Click the logo to remove it",

    "contact_us_form_show": "contact us",
    "contact_us_form_submit": "send",
    "contact_us_form_completed_title": "Thank you!",
    "contact_us_form_completed_subtitle": "Your message was sent.",
    "contact_us_form_email__error_empty": "Please enter email to get notified on your group selfie changes.",
    "contact_us_form_email__error_invalid_format": "Oops! Seems you've entered an invalid email address. Please try again.",
    "contact_us_form_name__error_empty": "Please enter name",
    "contact_us_form_background__error_empty": "Please enter background",
    "contact_us_form_title": "Greetings!",
    "contact_us_form_subtitle": "We are looking for an app store marketing expert to help us make ToonMe even more successful in Japan. Both in terms of GUI and monetisation approach. Is it you? Fill in a small form below for us to get to know you and contact for an interview.",
    "contact_us_form_label_name": "Your name:",
    "contact_us_form_label_bg": "Describe your experience and background:",
    "contact_us_form_label_email": "Your email:",
    "contact_us_form_cv": "CV in English (only if you have one)",
    "contact_us_form_close": "return to result",

    "webads_banner_title": "Sponsored",

    "feed_page_title": "For the most viral (and other) effects",
    "feed_page_subtitle": "go to the Toon Effects tab",
    "feed_page_step1": "<span>scroll</span> through the feed",
    "feed_page_step2": "<span>tap</span> any preview you like",
    "feed_page_step3": "and <span>choose</span> your photo",
    "feed_page_btn_part1": "go to",
    "feed_page_btn_part2": "TOON EFFECTS",
    "feed_page_info": "P.S. We constantly update the tab with new arrivals, so go check it today and every day 😉",

    "creative_failed_message": "Oops, something went wrong with this TOON. 🤔 Choose any other style or try again.",
    "creative_failed_retry_btn": "Retry",

    "huawei_appgallery_modal__title": "Are you using a Huawei device?",
    "huawei_appgallery_modal__message": "Are you using a Huawei device?",
    "huawei_appgallery_modal__btn_ok": "Yes",
    "huawei_appgallery_modal__btn_cancel": "No",

    "refresh_body__btn_body": "Choose body style",
    "refresh_body__btn_head": "Choose head style",

    "button_original_toggler__show": "Show original photo",
    "button_original_toggler__hide": "Hide original photo",

    "button_toon_toggler__show": "Show the toon character",
    "button_toon_toggler__hide": "Hide the toon character",

    "back_to_result": "Back to result",

    "label_new": "new",

    "error__image_file_format": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "separator__text" : "To apply toons to your photos, please <b>download ToonMe app</b> to your device",
    "banner__title": "Use these toons with your photos or create your own",
    "banner__description": "Choose any toon from our collection or create your own and share it with the world!",

    "app_button__ios": "the App store",
    "app_button__android": "google play",
    "app_button__huawei": "huawei",

    "pho_to__link_text": "Copyright © 2024 <a href='{{href}}' target='_blank' rel='noopener noreferrer'>Pho.to</a>   •   All rights reserved.",
    "privacy_policy__link": "Privacy policy",
    "main_header_tooltip__text": "{{tooltipText}} by",
    "share_page__button": "Share <span> this page</span>",
    "main_header_reposts__count": "{{count}} reposts",
    "user_page__text": "Get <b>ToonMe app</b> to <b>apply</b> these toons to <b>your photos</b>",
    "reposts_page__text": "Get <b>ToonMe app</b> to <b>apply</b> these toons to <b>your photos</b>",

    "combo_page__text": "Get <b>ToonMe app</b> to <b>apply</b> this and many more <b>toons</b> to <b>your photos</b>",
    "combo_page__redirect_button": "Other toons from <span>{{name}}</span>",
    "combo_page__title_original_preview": "Original Photo",
    "combo_page__title_result_preview": "Tooned Photo",
    "combo_page__apply_combo_btn": "Apply in free app",

    "tooltip__title": "Share link",
    "tooltip__button_copy": "Copy",
    "tooltip__button_copied": "Copied!",

    "button_load_more": "Load more",

    "not_found_page__title": "Sorry, page not found",
    "not_found_page__button": "back to homepage",
  },
};
