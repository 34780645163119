import React from "react";
import PropTypes from "prop-types";
import SimpleTab from "./SimpleTab";
import * as photolabApi from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import AppContext from "../../contexts/AppContext";
import {genders, getBodiesListByGroup} from "../../photolab/config/bodies";
import {SvgSprite} from "../../components/SvgSprite";
import ImageView from "../../components/ImageView";
import {pwAssetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import Creative from "../../photolab/Creative";
import {logEvent, userEvents} from "../../utils/log";
import {PhotolabResponseError} from "../../photolab/api";
import {creativeGroups} from "../../photolab/config/groups";

const views = {
  result: "result",
  head: "head",
  body: "body",
};

function createBodyProcessing(templateId) {
  return {
    tries: 0,
    templateId,
    isProcessing: true,
    isProcessed: false,
    isFailed: false,
  }
}

class BodyTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: true,
      view: views.result,
      gender: null,
      isShowHiddenBodies: false,
      bodySelectedTemplateId: null,
      bodies: [],
      bodiesProcessings: [],
    };

    this.bodiesContainerRef = React.createRef();
    this.headTasks = {};
  }

  init = () => {
    const groupBodies = getBodiesListByGroup(this.props.group);
    const isShowHiddenBodies = [
      creativeGroups.CARTOON_VECTOR_BODY,
    ].indexOf(this.props.group) === -1;

    this.setState({
      isWaiting: true,
      view: views.result,
      gender: null,
      isShowHiddenBodies,
      bodySelectedTemplateId: null,
      bodies: [
        ...groupBodies.filter((b) => !b.isHidden).shuffle(),
        ...groupBodies.filter((b) => b.isHidden).shuffle(),
      ],
      bodiesProcessings: [],
    }, () => {
      this.handleProcessingChange();
    });
  };

  componentDidMount() {
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);
    this.init();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.group !== this.props.group) {
      this.init();
    }

    if (this.bodiesContainerRef && this.bodiesContainerRef.current) {
      this.bodiesContainerRef.current.removeEventListener("wheel", this.handleBodiesContainterWheel);
      this.bodiesContainerRef.current.addEventListener("wheel", this.handleBodiesContainterWheel);
    }
  }

  handleProcessingChange = () => {
    const processing = window.processingManager.processing;
    const creative = processing.getSelectedCreativeInGroup(this.props.group);
    const groupCreatives = processing.creatives.filterLength((c) => c.inGroup(this.props.group));

    if (groupCreatives === 1 && creative.isProcessed) {
      if (this.state.view !== views.body) {
        this.handleRefreshBody();
      }
    } else {
      this.setState({isWaiting: false});
    }
  };

  handleBodiesContainterWheel = (e) => {
    if (this.bodiesContainerRef && this.bodiesContainerRef.current) {
      this.bodiesContainerRef.current.scrollLeft += e.deltaY;
    }
  };

  handleRefreshButtonClick = () => {
    this.handleRefreshBody();
  };

  handleRefreshBody = () => {
    const processing = window.processingManager.processing;
    const creative = processing.getSelectedCreativeInGroup(this.props.group);
    const gender = processing.getGender();
    const templateId = this.state.bodySelectedTemplateId
      || parseInt(creative.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID));

    const initialProcessing = {
      tries: 0,
      templateId,
      isProcessing: false,
      isProcessed: true,
      isFailed: false,
      result: creative.getTask("body"),
      config: this.state.bodies.find((body) => body.id === templateId),
    };

    const pos = this.state.bodies.findIndex((t) => t.id === initialProcessing.templateId);
    if (pos > 0) {
      this.state.bodies.unshift(...this.state.bodies.splice(pos, 1));
    }

    this.headTasks[creative.getExtra(Creative.EXTRA_HEAD_TEMPLATE_ID)] = Promise.resolve(creative.getTask("head"));

    this.setState({
      isWaiting: false,
      view: views.body,
      bodySelectedTemplateId: initialProcessing.templateId,
      bodiesProcessings: [initialProcessing],
      gender: this.state.gender
        || processing.getExtra("bodies_gender__" + this.props.group, gender)
        || genders.male,
    });
  };

  handleBodyTemplateSelect = (templateId) => {
    this.setState({bodySelectedTemplateId: templateId});

    let processing = this.state.bodiesProcessings.find((processing) => {
      return processing.templateId === templateId;
    });

    if (processing) {
      return;
    }

    processing = createBodyProcessing(templateId);
    processing.config = this.state.bodies.find((body) => {
      return body.id === processing.templateId;
    });

    this.state.bodiesProcessings.push(processing);

    this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()}, () => {
      this.startBodyProcessing(processing);
    });
  };

  logProcessedTask = (processing, taskName, taskStartedAt) => {
    const processingTime = Date.now() - taskStartedAt;
    const creative = window.processingManager.processing.getSelectedCreativeInGroup(this.props.group);

    if (window.appConfig.analytics.tasksIsEnabled) {
      logEvent(userEvents.PROCESSING_TASK_PROCESSED, {
        group: this.props.group,
        template_id: creative.templateId,
        creative_id: null,
        task_name: taskName,
        task_template_name: processing.addTaskResult.templateName,
        processing_time: processingTime,
        source_file_id: window.processingManager.processing.file.id,
        task_start_at: taskStartedAt,
        task_finish_at: Date.now(),
        task_request_id: processing.addTaskResult.requestId || "",
        task_enqueue_at: processing.addTaskResult.addTaskRequestAt,
        task_enqueued_at: processing.addTaskResult.addTaskResponseAt,
        task_sign_request_time: processing.addTaskResult.signRequestTime,
        task_sign_addtask_time: processing.addTaskResult.signAddTaskRequestTime,
        task_get_result_requests: processing.result.getResultRequestsAmount,
        task_total_duration: processing.result.totalDuration || "",
      });
    }
  };

  logFailedTask = (processing, taskName, taskStartedAt) => {
    const processingTime = Date.now() - taskStartedAt;
    const creative = window.processingManager.processing.getSelectedCreativeInGroup(this.props.group);

    if (window.appConfig.analytics.tasksIsEnabled) {
      logEvent(userEvents.PROCESSING_TASK_FAILED, {
        group: this.props.group,
        template_id: creative.templateId,
        creative_id: null,
        task_name: taskName,
        task_template_name: processing.addTaskResult.templateName,
        processing_time: processingTime,
        source_file_id: window.processingManager.processing.file.id,
        task_start_at: taskStartedAt,
        task_finish_at: Date.now(),
        task_request_id: processing.addTaskResult.requestId || "",
        task_enqueue_at: processing.addTaskResult.addTaskRequestAt,
        task_enqueued_at: processing.addTaskResult.addTaskResponseAt,
        task_sign_request_time: processing.addTaskResult.signRequestTime,
        task_sign_addtask_time: processing.addTaskResult.signAddTaskRequestTime,
        task_get_result_requests: processing.result.getResultRequestsAmount,
        task_error_code: processing.error.code || 0,
      });
    }
  };

  getHeadTask = (id) => {
    this.headTasks = this.headTasks || {};

    if (!this.headTasks[id]) {
      const taskConfig = new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(id))
        .addImage(new PhotolabTaskImageUrl(window.processingManager.processing.file.url))
        .setLanguage(window.clientConfig.lang);

      this.headTasks[id] = photolabApi.photolabAddTask(taskConfig.buildToJs())
        .then((taskResult) => photolabApi.photolabWaitTask(taskResult.requestId, 1000, 1000));
    }

    return this.headTasks[id];
  };

  startBodyProcessing = (bodyProcessing) => {
    this.getHeadTask(bodyProcessing.config.head)
      .then((headTaskResult) => {
        const taskStartedAt = Date.now();
        const taskConfig = new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod(bodyProcessing.templateId))
          .addImage(new PhotolabTaskImageUrl(headTaskResult.resultUrl))
          .setLanguage(window.clientConfig.lang);

        return photolabApi.photolabAddTask(taskConfig.buildToJs())
          .then((addTaskResult) => {
            bodyProcessing.addTaskResult = addTaskResult;
            return photolabApi.photolabWaitTask(addTaskResult.requestId, 3000, 300)
          })
          .then((taskResult) => {
            bodyProcessing.isProcessing = false;
            bodyProcessing.isProcessed = true;
            bodyProcessing.result = taskResult;

            this.logProcessedTask(bodyProcessing, "body", taskStartedAt);
            this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()});
          })
          .catch((err) => {
            const processing = window.processingManager.processing;
            const logError = {
              name: err.name,
              code: err.code || undefined,
              message: err.message || undefined,
              file_id: processing.file.id,
              group: this.props.group,
              group_position: this.props.groupPosition,
              photo_gender: processing.getGender(),
              template_id: bodyProcessing.templateId,
            };

            if (err instanceof PhotolabResponseError) {
              logError.task_request_id = err.requestId;
              logError.task_image_url = headTaskResult.resultUrl;
            }

            logEvent(userEvents.BODY_ERROR, logError);

            bodyProcessing.isProcessing = false;
            bodyProcessing.isFailed = true;
            bodyProcessing.error = err;

            this.logFailedTask(bodyProcessing, "body", taskStartedAt);
            this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()});
          });
      });
  };

  handleBodyConfirm = (bodyProcessing) => {
    this.setState({view: views.result}, () => {
      this.headTasks[bodyProcessing.config.head].then((headTaskResult) => {
        this.props.onTemplateSelect(this.props.group, {
          bodyTemplateId: bodyProcessing.templateId,
          bodyTaskResult: bodyProcessing.result,
          headTemplateId: bodyProcessing.config.head,
          headTaskResult,
        });
      });
    });
  };

  handleImageLoaded = () => {
    const processing = window.processingManager.processing;

    const bodyProcessing = this.state.bodiesProcessings.find((processing) => {
      return processing.templateId === this.state.bodySelectedTemplateId;
    });

    logEvent(userEvents.BODY_VIEW, {
      file_id: processing.file.id,
      group: this.props.group,
      group_position: this.props.groupPosition,
      photo_gender: processing.getGender(),
      template_id: this.state.bodySelectedTemplateId,
      creative_url: bodyProcessing.result.resultUrl,
    });
  };

  handleShowHiddenBodies = () => {
    this.setState({isShowHiddenBodies: true});
  };

  handleClick = (e) => {
    console.info("template id: " + this.state.bodySelectedTemplateId);
  };

  render() {
    if (this.state.isWaiting) {
      return this.renderWaiting();
    }

    switch (this.state.view) {
      case views.body: {
        return this.renderBodyChooser();
      }
      case views.head: {
        return this.renderHeadChooser();
      }
      default: {
        return this.renderResult();
      }
    }
  }

  handleToggleGender = () => {
    const gender = this.state.gender === genders.male
      ? genders.female
      : genders.male;

    window.processingManager.processing
      .setExtra("bodies_gender__" + this.props.group, gender);

    this.setState({gender});
  };

  renderWaiting = () => {
    return <div>
      <div className="creative-holder">
        <div className="creative-holder-placeholder" />
        <div className="loader-video">
          <span className="item-loader first-item-loader" />
          <span className="item-loader second-item-loader" />
          <span className="item-loader third-item-loader" />
        </div>
        <div className="square square-big" />
        <div className="square square-med" />
        <div className="square square-small" />
      </div>
    </div>;
  };

  renderResult = () => {
    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  };

  renderBodyChooser = () => {
    let templates = this.state.isShowHiddenBodies
      ? this.state.bodies.slice()
      : this.state.bodies.filter((template) => {
        if (template.id === this.state.bodySelectedTemplateId) {
          return true;
        } else {
          return !template.isHidden;
        }
      });

    templates = templates.filter((template) => template.gender === this.state.gender);

    const bodyProcessing = this.state.bodiesProcessings.find((processing) => {
      return processing.templateId === this.state.bodySelectedTemplateId;
    });

    return <div className={"body-chooser-container body-chooser-container--" + this.props.group}>
      {bodyProcessing && bodyProcessing.isProcessed && <div className="body-chooser-creative">
        <ImageView onClick={this.handleClick} onImageLoaded={this.handleImageLoaded} image={{url: bodyProcessing.result.resultUrl}} square />

        <button onClick={() => this.handleBodyConfirm(bodyProcessing)}>
          <SvgSprite viewBox="0 0 24 18" icon="icon-check" />
        </button>

        {/*<button*/}
        {/*  className="btn-choose-head"*/}
        {/*  onClick={this.handleRefreshHead}*/}
        {/*  hidden={!this.state.isMergedRefresh}>*/}
        {/*  <img src={this.state.headTask.resultUrl} alt="Head preview" />*/}
        {/*  <span>+5</span>*/}
        {/*</button>*/}
      </div>}

      {bodyProcessing && bodyProcessing.isFailed && <div>
        failed
      </div>}

      {(!bodyProcessing || bodyProcessing.isProcessing) && <div className="body-chooser-loader">
        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
      </div>}

      <div className="btns-template-container" ref={this.bodiesContainerRef}>
        <button className={"btn-choice-gender " + (this.state.gender === genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.handleToggleGender} key="switch-gender">
          <SvgSprite viewBox="0 0 12 12" icon="icon-choose-gender" />
          <span className="btn-choice-gender-title">
            {i18n.t(this.state.gender === genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}
            <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />
          </span>
        </button>
        {templates.map((template) => {
          const styles = {
            backgroundImage: `url(${pwAssetUrl(`templates-previews/${template.id}${template.gender[0]}.png`)})`,
          };

          let className = `body_${template.id}`;
          if (this.state.bodySelectedTemplateId === template.id) {
            className += " active";
          }

          return <button
            key={template.id}
            style={styles}
            className={className}
            dangerouslySetInnerHTML={{__html: "&nbsp;"}}
            onClick={() => this.handleBodyTemplateSelect(template.id)}
          />;
        })}
        <button
          key="toggle-hidden"
          hidden={this.state.isShowHiddenBodies}
          className={"btn-toggle-styles"}
          onClick={this.handleShowHiddenBodies}
          children={i18n.t("more_styles")} />
      </div>
    </div>;
  };

  renderHeadChooser = () => {
    return <div>
      head refresh (like vector tab)
    </div>;
  };
}

BodyTab.contextType = AppContext;
BodyTab.propTypes = {
  group: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  creatives: PropTypes.array.isRequired,
  withHD: PropTypes.bool,
  canBeRefreshed: PropTypes.bool,
  onImageLoaded: PropTypes.func.isRequired,
  onHideWatermarkButtonClick: PropTypes.func.isRequired,
  onShowWatermarkButtonClick: PropTypes.func.isRequired,
  onDownloadButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
};

export default BodyTab;