import React from "react";
import styled from "styled-components";
import i18n from "../i18n";
import * as Icons from "../ui/svg";

export class ShareButton extends React.Component {
  state = {
    isTooltipShow: false,
    isCopied: false
  }

  containerRef = React.createRef();

  componentDidMount() {
    window.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (e.target && !this.containerRef.contains(e.target)) {
      this.setState({isTooltipShow: false, isCopied: false});
    }
  }

  handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);

    this.setState({isCopied: true}, () => {
      setTimeout(() => {
        this.setState({isCopied: false});
      }, 2000);
    })
  }

  render() {
    return <SBContainerStyled ref={(ref) => this.containerRef = ref}>
      <SBButtonStyled className="btn-share" dangerouslySetInnerHTML={{__html: i18n.t("share_page__button")}} onClick={() => this.setState({isTooltipShow: !this.state.isTooltipShow})} />
      <TooltipStyled hidden={!this.state.isTooltipShow}>
        <div className="tooltip_header">
          <h5>{i18n.t("tooltip__title")}</h5>
          <button className="close-btn" onClick={() => this.setState({isTooltipShow: false, isCopied: false})}><Icons.SvgCloseIcon /></button>
        </div>
        <div className="tooltip_form">
          <div className="tooltip_input">
            <input type="text" disabled value={window.location.href} />
          </div>
          <button className="copy" onClick={this.handleCopyClick}>{this.state.isCopied ? i18n.t("tooltip__button_copied") : i18n.t("tooltip__button_copy")}</button>
        </div>
      </TooltipStyled>
    </SBContainerStyled>
  }
}

const SBContainerStyled = styled.div`
  position: relative;
`;

const SBButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 56px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: .32px;
  color: #4d5ef6;
  transition: 0.3s;

  &.btn-share {
    border: 2px solid #4d5ef6;
    padding: 0 24px;
  }

  @media all and (min-width: 992px) {
    &:hover {
      background: #0e1da6;
      border-color: #0e1da6;
      color: #fff;
    }
  }

  @media all and (max-width: 991px) {
    height: 48px;
    font-size: 14px;

    span {
      display: none;
    }
  }
`;

const TooltipStyled = styled.div`
  padding: 20px;
  background: #fff;
  position: absolute;
  // visibility: hidden;
  width: 300px;
  z-index: 10;
  box-shadow: 0 2px 10px 5px rgba(0, 0, 0, .2);
  border-radius: 20px;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);

  h5 {
    font-weight: 600;
    font-size: 16px;
  }

  .tooltip_form {
    width: 100%;
  }

  .tooltip_input {
    height: 40px;
    background: #fff;
    border-radius: 10px 0 0 10px;
    padding-left: 16px;
    padding-right: 8px;
    font-size: 16px;
    border: 1px solid #eee;
    flex-grow: 1;
  }

  div {
    display: flex;
    align-items: center;

    &.tooltip_header {
      position: relative;
      margin-bottom: 12px;

      .close-btn {
        position: absolute;
        top: -16px;
        right: 0px;
        background: transparent;
        transition: 0.3s;

        svg {
          width: 16px;
          height: 16px;
        }

        @media all and (min-width: 992px) {
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    input {
      height: 100%;
      width: 100%;
      background: none;
      border: none;
    }

    .copy {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 75px;
      flex-shrink: 0;
      border-radius: 0 10px 10px 0;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      background: #4d5ef6;
      transition: 0.3s;

      @media all and (min-width: 992px) {
        &:hover {
          background: #0e1da6;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%; 
    top: -20px;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  @media(max-width: 1295px) {
    left: auto;
    right: 0;
    transform: translateX(0);

    &::before {
      left: 75%; 
      transform: translateX(-75%);
    }
  }

  @media(max-width: 991px) {
    &::before {
      left: 87.5%; 
      transform: translateX(-87.5%);
    }
  }
`;