import ImageView from "../../components/ImageView";
import React from "react";
import PropTypes from "prop-types";
import Creative from "../../photolab/Creative";
import {creativeGroups} from "../../photolab/config/groups";
import {logEvent, userEvents} from "../../utils/log";
import {creativeImageEventProps} from "../../utils/creative";

export default class CreativeView extends React.Component {

  constructor(props) {
    super(props);

    this.loadedHandlerIsCalled = false;
    this.imageLoadingTimer = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.creative.id !== this.props.creative.id) {
      clearTimeout(this.imageLoadingTimer);
      this.loadedHandlerIsCalled = false;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.imageLoadingTimer);
    this.loadedHandlerIsCalled = false;
  }

  handleImageLoadStart = () => {
    window.creativesLog.log(this.props.creative.id, {event: "image_load_start"});
    this.imageLoadingTimer = setTimeout(this.handleLoadingMoreThanOneSecond, 1000);
    this.imageLoadingStartMs = Date.now();
  };

  handleImageLoadError = () => {
    clearTimeout(this.imageLoadingTimer);
    window.creativesLog.log(this.props.creative.id, {event: "image_load_error"});

    logEvent(userEvents.CREATIVE_IMAGE_LOAD_ERROR, creativeImageEventProps(this.props.creative, "view", {
      duration: Date.now() - this.imageLoadingStartMs
    }));
  };

  handleLoaded = () => {
    const duration = Date.now() - this.imageLoadingStartMs;

    window.creativesLog.log(this.props.creative.id, {event: "image_load_finish"});
    clearTimeout(this.imageLoadingTimer);

    // на video.onCanPlay срабатывает при каждом проигрывании
    if (this.loadedHandlerIsCalled === false) {
      this.loadedHandlerIsCalled = true;

      this.props.onLoaded(this.props.creative);

      if (duration >= 3000) {
        logEvent(userEvents.CREATIVE_IMAGE_LOAD_LONG, creativeImageEventProps(this.props.creative, "view", {duration}));
      }
    }
  };

  handleLoadingMoreThanOneSecond = () => {
    logEvent(userEvents.CREATIVE_IMAGE_LOAD_1SEC, creativeImageEventProps(this.props.creative, "view"));
  }

  handleClick = (e) => {
    const group = this.props.creative.group;
    const templateId = this.props.creative.template.id;

    switch (group) {
      case creativeGroups.CARTOON_VECTOR_BODY:
        const headId = this.props.creative._.getExtra(Creative.EXTRA_HEAD_TEMPLATE_ID);
        console.info(`template ${group}/${templateId} (head ${headId})`);
        break;
      default:
        console.info(`template ${group}/${templateId}`);
    }

    this.props.onClick && this.props.onClick(e);
  };

  renderImage = () => {
    let file = this.props.creative._.getFile("template") || this.props.creative._.getFile("result");

    if (!this.props.creative.extra.creative_layout_is_hidden && this.props.creative._.getFile("layout")) {
      file = this.props.creative._.getFile("layout");
    }

    return <ImageView
      image={{url: file}}
      onImageLoaded={this.handleLoaded}
      onImageLoadStart={this.handleImageLoadStart}
      onImageLoadError={this.handleImageLoadError}
      onClick={this.handleClick}
      square
      backgrounded
      withWatermark={this.props.creative.extra.wm_on_demand}
    />;
  };

  renderVideo = () => {
    return <video
      key={this.props.creative.file.url}
      playsInline
      muted
      loop
      autoPlay
      controls={false}
      onCanPlay={this.handleLoaded}>
      <source type="video/mp4" src={this.props.creative.file.url} />
    </video>;
  };

  render() {
    const extension = this.props.creative.file.url.split('.').pop().toLowerCase();

    return extension === "mp4"
      ? this.renderVideo()
      : this.renderImage();
  }
}

CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
  onLoaded: PropTypes.func.isRequired,
};