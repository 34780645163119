export const creativeGroups = {
  // системные группы
  CROP: "crop",
  GENDER: "gender",
  HD: "hd",
  COMMON: "common",

  // отображаемые группы
  DISNEY: "disney",
  DISNEY_WITH_5273: "disney_with_5273",
  DISNEY_2D: "disney_2d",
  VECTOR: "vector",
  ARCHER: "archer",
  SIMPSONS: "simpsons",
  SIMPSONS_2: "simpsons2",
  CARICATURE: "caricature",
  CARICATURE_2: "caricature_2",
  GLAMOUR: "glamour",
  CARLAFUENTESART: "carlafuentesart",
  BARBIE: "barbie",
  CARTOON: "cartoon",
  CARTOON2: "cartoon2",
  CARTOON_ANIM: "cartoon_anim",
  POPART: "popart",
  DISNEY_5277: "disney5277",
  CARTOON_VECTOR_BODY: "cartoon_vector_body",
  TAB_210622: "tab210622",
  DISNEY2DMIX: "disney2dmix",
  DISNEY_5361: "disney5361",
  TAB_5378: "tab5378",
  TAB_5379: "tab5379",
  TAB_5390: "tab5390",
  TAS3: "tas3", // (t)oon(a)pp(s)elllebriti
  NN_ANIME_GAN: "nn_anime_gan",
  GENDERSWAP_FEMALE: "genderswap_f",
  GENDERSWAP_MALE: "genderswap_m",
  STROKES: "strokes",
  TAB_6750: "tab_6750",
  CELEBRITY: "celebrity",
  GLOSS: "gloss",
  HALLOWEEN: "halloween",
  MIDJORNEY: "midjorney",
  CELEB: "celeb",
  AI_20230421_13: "ai_20230421_13",
  AI_20230421_22: "ai_20230421_22",
  AI_20230421_25: "ai_20230421_25",
  AI_20230427_32: "ai_20230427_32",
  AI_20230427_33: "ai_20230427_33",
  AI_20230427_34: "ai_20230427_34",
  AI_20230427_35: "ai_20230427_35",
  AI_20230427_36: "ai_20230427_36",
  AI_202304: "ai_202304",
};

export const systemGroups = [
  creativeGroups.CROP,
  creativeGroups.GENDER,
  creativeGroups.HD,
  creativeGroups.COMMON,
];