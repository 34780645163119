import React from "react";
import AppContext from "../contexts/AppContext";
import HeaderClassic from "../components/HeaderClassic";
import {assetUrl} from "../utils/etc";
import routes from "../routes";

export default class WoohooPage extends React.Component {
  render() {
    return <>
      <main className="main-page">
        <HeaderClassic hidden={window.clientConfig.isWebview} />
        <HomeButtonView hidden={window.clientConfig.isWeb} onClick={() => this.props.history.push(routes.INDEX)} />
        <div className="container">
          <div className="achieve">
            <h2 className="achieve__title">Achievements</h2>
            <p className="achieve__paragraph">
              When launching <b>ToonMe</b> in <b>2020</b> we could not imagine how
              buzzing our effects would become. Well, we were full of hopes of
              course, but all these ups (accompanied by some server downs)
              happened in a blink of AI. Let’s recall them one step at a time
              though.
            </p>
            <p className="achieve__important">
              Already in <span>January 2021</span> our <span>3D Disney-like toon</span> became
              popular in <span>over 30 countries</span> lifting the app
              to <span>#1 in Photography</span> in USA, Canada, France, Italy,
              Germany, Australia, Brazil, Thailand, and other amazing places in
              the word.
            </p>
            <div className="achieve__item">
              <h3 className="achieve__title">March 2021<br />Japan</h3>
              <p className="achieve__paragraph">
                March 2021. Japan.<br />
                <b><a href="https://app.sensortower.com/ios/rankings/top/iphone/japan/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-09-25">#1 app overall</a> for several weekends in a row, on both Google Play and App Store.</b> Besides, we were left almost speechless with the number of local TV channels that contacted us up till the middle of the Summer. What an overwhelming experience. Arigato.
                <br />
                <span className="achieve__filter">
                  <img className="achieve__image" src={assetUrl("assets/img/woohoo/achieve-after.png")} alt="" />
                  <img className="achieve__image" src={assetUrl("assets/img/woohoo/achieve-before.png")} alt="" />
                </span>
              </p>
            </div>
            <div className="achieve__item">
              <h3 className="achieve__title">April 2021<br />China</h3>
              <p className="achieve__paragraph">
                In April 2021 China took up the
                baton. This time we could only judge by <b>App Store</b> where
                we reached <a href="https://app.sensortower.com/ios/rankings/top/iphone/china/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-09-25">#1 top overall</a>.
                <br /><br />
                Interestingly enough, <b>ToonMe was the only non-Chinese app
                that got into the Chinese top 100</b> (and that makes us feel
                even more proud)! If we dare to say that the app market in
                China is ‘all Chinese’ to us, it’s not just a figure of speech
                🙂, as it has always been dominated by Chinese apps.
                <img className="achieve__image full" src={assetUrl("assets/img/woohoo/achieve-china.png")} alt="" />
              </p>
            </div>
            <div className="achieve__item">
              <h3 className="achieve__title">Overall this<br />year</h3>
              <p className="achieve__paragraph">
                Overall this year we’ve been a
                huge success in many places around the globe (unlike humankind
                ToonMe doesn’t need any QR codes to travel). <b>Reaching #1 overall positions</b> in <b>Japan</b> and <a href="https://app.sensortower.com/ios/rankings/top/iphone/madagascar/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-09-25">Madagascar</a>, <b>China</b> and <a href="https://app.sensortower.com/ios/rankings/top/iphone/kazakhstan/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-01-23">Kazakhstan</a>, <a href="https://app.sensortower.com/ios/rankings/top/iphone/italy/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-01-18">Italy</a> and <a href="https://app.sensortower.com/ios/rankings/top/iphone/singapore/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-01-19">Singapore</a>, <a href="https://app.sensortower.com/ios/rankings/top/iphone/philippines/all-categories?user_app_id=5ead69a75c6ace7ec796d41a&adaptive_colors=off&end_date=2021-12-23&tab_id&date=2021-04-02">Phillipines</a> and Iran.
                In addition to <b>Top-3 places</b> in many more
                countries, from India to Ireland, from Thailand to Argentina,
                from Mexico to France.
              </p>
            </div>
            <p className="achieve__next">
              <b>Our 2022 so far</b><br />
              In 2022 we have already gained Top #1 positions in lots of new places, including Belize, Mauritius, <a href="https://app.sensortower.com/ios/rankings/top/iphone/romania/photo-video?app_id=1508120751&app_name=toonme-cartoon-photo-editor&publisher=informe-laboratories-inc&start_date=2022-02-14&end_date=2022-02-14&countries=RO&chart_type=free&hourly=false&selected_tab=charts&date=2022-02-14">Romania</a>, and Suriname. More to come.
              <br /><br />
              <b>Stay TOONed!</b>
            </p>
          </div>
        </div>
      </main>
    </>;
  }
}

WoohooPage.contextType = AppContext;

function HomeButtonView({hidden, onClick}) {
  if (hidden) {
    return null;
  }

  return <button className="tooncoin__button_back" onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path fill="#3E4552" stroke="#3E4552" strokeWidth=".3" d="M3.926 8.551l7.333 6.875c.319.299.83.299 1.148 0 .324-.303.324-.799 0-1.102L5.662 8l6.745-6.324c.324-.303.324-.799 0-1.102-.16-.15-.368-.224-.574-.224-.206 0-.414.074-.574.224L3.926 7.449c-.324.303-.324.799 0 1.102z"/>
    </svg>
    home
  </button>;
}
