function getApiPath() {
  return window.clientConfig.queryParams["photolab_social_api"] === "test"
    ? window.appConfig.photolabSocialApi.pathTest
    : (window.clientConfig.isWeb
      ? window.appConfig.photolabSocialApi.pathWeb
      : window.appConfig.photolabSocialApi.path);
}

async function defaultHandler(res) {
  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    const error = new Error(data?.error);
    error.name = "PhotolabSocialApiError";

    throw error;
  }
}

function networkErrorHandler(err, url) {
  const error = new Error("Failed to request: " + url);
  error.name = "NetworkError";
  error.parentError = err;
  error.url = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function fetchCombo(id) {
  const url = new URL(getApiPath() + "/api/v1/doc/" + id);

  Object.keys(window.clientConfig.queryParams).forEach((key) => {
    url.searchParams.append(key, window.clientConfig.queryParams[key]);
  });

  return window.fetch(url.toString(), {method: "GET"})
    .catch((err) => networkErrorHandler(err, url))
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

function fetchUser(id) {
  const url = new URL(getApiPath() + "/api/v1/user/" + id);

  Object.keys(window.clientConfig.queryParams).forEach((key) => {
    url.searchParams.append(key, window.clientConfig.queryParams[key]);
  });

  return window.fetch(url.toString(), {method: "GET"})
    .catch((err) => networkErrorHandler(err, url))
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

function fetchUserFeed(id, offsetId) {
  const url = new URL(getApiPath() + "/api/v1/user/" + id + "/feed");

  if (offsetId) {
    url.searchParams.append("offset_id", offsetId);
  }

  Object.keys(window.clientConfig.queryParams).forEach((key) => {
    url.searchParams.append(key, window.clientConfig.queryParams[key]);
  });

  return window.fetch(url.toString(), {method: "GET"})
    .catch((err) => networkErrorHandler(err, url))
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

function fetchFeedChildren(id, offsetId) {
  const url = new URL(getApiPath() + "/api/v1/feed/children/" + id);

  if (offsetId) {
      url.searchParams.append("offset_id", offsetId);
  }

  Object.keys(window.clientConfig.queryParams).forEach((key) => {
    url.searchParams.append(key, window.clientConfig.queryParams[key]);
  });

  return window.fetch(url.toString(), {method: "GET"})
    .catch((err) => networkErrorHandler(err, url))
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

export default {
  fetchCombo,
  fetchUser,
  fetchUserFeed,
  fetchFeedChildren,
};