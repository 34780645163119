import React from "react";
import * as EPS from "./NotFoundPage.styled"
import i18n from "../../../i18n";
import { assetUrl } from "../../../utils/etc";
import routes from "../../../routes";
import HeaderClassic from "../../../components/HeaderClassic";
import {Footer} from "../../../components/Footer";

export default class ErrorHomePage extends React.Component {

  constructor(props) {
    super(props);
  }

  handleBackClick = () => {
    this.props.history.replace({
      pathname: routes.HOMEPAGE
    })
  }
  render() {
    return <div>
      <HeaderClassic hidden={window.clientConfig.isWebview} />
      <EPS.Container>
        <EPS.Content>
          <EPS.Title dangerouslySetInnerHTML={{__html: i18n.t("not_found_page__title")}} />
          <EPS.Link dangerouslySetInnerHTML={{__html: i18n.t("not_found_page__button")}}  onClick={this.handleBackClick}/>
        </EPS.Content>
        <EPS.ImageContainer>
          <EPS.Image src={assetUrl(`assets/error/img_404.webp`)} />
        </EPS.ImageContainer>
      </EPS.Container>
      <Footer />
    </div>
  }
}