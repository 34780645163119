import clientStorage from "./client-storage";
import Creative from "../photolab/Creative";

const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSED = 1;

export function creativeIsProcessed(creative) {
  return creative.status === STATUS_PROCESSED;
}

export function creativeIsFailed(creative) {
  return creative.status === STATUS_FAILED;
}

export function creativeIsProcessing(creative) {
  return creative.status === STATUS_PENDING;
}

export function creativeIsFinished(creative) {
  return creative.status === STATUS_PROCESSED || creative.status === STATUS_FAILED;
}

export function mapNewCreativesToOld(newCreatives) {
  return newCreatives.map(mapCreativeToOld);
}

/**
 * @param {Creative} creative
 * @returns {Object}
 */
export function mapCreativeToOld(creative) {
  const result = {
    id: creative.id,
    status: creative.data.status,
    template: {
      id: creative.templateId,
      id_hd: creative.getExtra(Creative.EXTRA_HD_TEMPLATE_ID) || 0,
    },
    file: {
      url: creative.resultUrl || "",
      width: 0,
      height: 0,
      extension: undefined,
    },
    group: creative.group,
    alias: creative.alias,
    extra: creative.getExtras(),
    is_selected: creative.isSelected,
    tasks: creative.data.tasks,
    files: creative.data.files,
    _: creative,
  };

  const resultTask = creative.getTask("store");

  if (resultTask && Array.isArray(resultTask.result)) {
    result.file.width = resultTask.result[0].width || 0;
    result.file.height = resultTask.result[0].height || 0;
    result.file.extension = resultTask.result[0].extension || undefined;
  } else if (resultTask && resultTask.result) {
    result.file.width = resultTask.result.width || 0;
    result.file.height = resultTask.result.height || 0;
    result.file.extension = resultTask.result.extension || undefined;
  } else {
    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      result.file.url = creative.getFile("result");
    } else {
      if (creative.getFile("result_store")) {
        result.file.url = creative.getFile("result_store");
      } else {
        result.file.url = creative.getFile("result");
        result.extra.wm_on_demand = "result_store";
      }
    }
  }

  if (clientStorage.hasBeforePhotoShouldBeRemoved()) {
    if (clientStorage.getBeforePhotoShouldBeRemoved()) {
      result.extra.creative_layout_is_hidden = true;

      if (clientStorage.getProWatermarkShouldBeRemoved()) {
        result.file.url = creative.getFile("template") || creative.getFile("result");
      } else if (creative.getFile("layout")) {
        if (creative.getFile("template_store")) {
          result.file.url = creative.getFile("template_store");
          delete result.extra.wm_on_demand;
        } else {
          result.file.url = creative.getFile("template");
          result.extra.wm_on_demand = "template_store";
        }
      }
    } else {
      result.extra.creative_layout_is_hidden = false;
    }
  } else {
    result.extra.creative_layout_is_hidden = true;

    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      result.file.url = creative.getFile("template") || creative.getFile("result");
    } else if (creative.getFile("layout")) {
      if (creative.getFile("template_store")) {
        result.file.url = creative.getFile("template_store");
        delete result.extra.wm_on_demand;
      } else {
        result.file.url = creative.getFile("template");
        result.extra.wm_on_demand = "template_store";
      }
    }
  }

  return result;
}

export function creativeImageEventProps(creative, place, data = {}) {
  let connectionEffectiveType = undefined;
  if (window.navigator.connection && window.navigator.connection.effectiveType) {
    connectionEffectiveType = window.navigator.connection.effectiveType;
  }

  return {
    place,
    group: creative.group,
    template_id: creative.template.id,
    creative_url: creative.file.url,
    connection_effective_type: connectionEffectiveType,
    ...data,
  };
}

export function reorderConfigsByWeight(configs) {
  const groups = [];

  configs.forEach((config) => {
    const key = (config.extra && config.extra.weight) || config.weight || 1;
    const group = groups.find((g) => g.key === key);
    if (group) {
      group.items.push(config);
    } else {
      groups.push({key, items: [config]});
    }
  });

  groups.sort((a, b) => a.key < b.key ? 1 : -1);

  return groups.flatMap((g) => g.items.shuffle());
}

export function transformToDownloadUrl(imageUrl) {
  const appUrl = new URL(window.appConfig.paths.proxy);
  const url = new URL(imageUrl);
  const hostname = url.hostname.toLowerCase();

  if (hostname === "temp-images.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/temp-images.ws.pho.to" + url.pathname;
  }

  if (hostname === "worker-images.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/worker-images.ws.pho.to" + url.pathname;
  }

  if (hostname.match(/worker-images-[2-9].ws.pho.to/)) {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/" + hostname + url.pathname;
  }

  if (hostname === "webview-app.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download" + url.pathname;
  }

  if (hostname === "pw.test.photo-cdn.net") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/pw.test.photo-cdn.net" + url.pathname;
  }

  if (hostname === "client-upload-proxy") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/uploads/toonme/creatives" + url.pathname;
  }

  return url.toString();
}