import {getLocationQueryObject} from "./utils/text";
import clientStorage from "./utils/client-storage";
import {detect} from "detect-browser";
import {generateHash} from "./utils/text";
import {androidRequestParams, iosRequestParams} from "./utils/constants";
import {isWebviewCountriesOf} from "./utils/config.utils";

if (!clientStorage.hasFirstVisitAt()) {
  clientStorage.setFirstVisitAt(Date.now());
}

let query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android", "android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
let isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";
let isWebviewMock = false;

if (isWebview) {
  clientStorage.setWebviewRequestParams(query);
}

const webviewHostNames = (process.env.REACT_APP_WEBVIEW_HOSTNAMES || "").split(",");

if (!isWebview && webviewHostNames.includes(window.location.hostname)) {
  let requestParams = clientStorage.getWebviewRequestParams();

  isWebview = true;
  isWebviewMock = true;

  if (Object.keys(requestParams).length === 0) {
    if (["ios"].indexOf(platformInfo.os.toLowerCase()) > -1) {
      requestParams = {...iosRequestParams};
      requestParams.vicman_unified_id = generateHash();
    } else {
      requestParams = {...androidRequestParams};
      requestParams.aid = generateHash();
    }

    clientStorage.setWebviewRequestParams(requestParams);
  }

  query = {
    ...requestParams,
    ...query,
  };
}

function detectLocale() {
  const langs = window.navigator.languages || [window.navigator.language] || ["en"];
  const chineseLike = langs.find((locale) => locale.toLowerCase().startsWith("zh"));
  return chineseLike || langs[0];
}

window.clientConfig = {
  platform: platformInfo,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebMobileAndroid: platformInfo.os.toLowerCase() === "android" || platformInfo.os.toLowerCase() === "android os",
  isWebMobileIOS: platformInfo.os.toLowerCase() === "ios",
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  isWebviewMock: isWebviewMock,
  isAnyMobile: isWebview || platformIsMobile,
  webviewClientId: isWebview ? (query["aid"] || query["vicman_unified_id"]) : null,
  queryParams: query,
  isPro: query["is_pro"] === "1",
  locale: query["locale"] || query["lang"] || detectLocale() || undefined,
  country: query["country"] || undefined,
  splitGroupId: undefined,
  splits: {},
  isChinaUser: false,
  isJapanUser: false,
  isUsaUser: false,
  isGreatBritainUser: false,
  isReturned: false, // true - если юзер вернулся в таб с "хорошим" процессингом в кеше
  features: {},
  isNew: Date.now() < (clientStorage.getFirstVisitAt() + 43_200_000), // 12h
};

window.clientConfig.isWebviewAndroidGooglePlay = window.clientConfig.isWebviewAndroid && window.clientConfig.queryParams.app_id === "18";
window.clientConfig.isWebviewAndroidHuawei = window.clientConfig.isWebviewAndroid && window.clientConfig.queryParams.app_id === "19";

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

window.clientConfig.country = window.clientConfig.isWebview
  ? window.clientConfig.queryParams.country
  : undefined;

window.clientConfig.isJapanUser = window.clientConfig.lang === "ja" || (window.clientConfig.isWebview && window.clientConfig.queryParams.country === "JP");
window.clientConfig.isChinaUser = window.clientConfig.lang === "zh" || (window.clientConfig.isWebview && window.clientConfig.queryParams.country === "CN");
window.clientConfig.isUsaUser = window.clientConfig.isWebview
  && ((window.clientConfig.queryParams.country || "").toUpperCase() === "US" || (window.clientConfig.queryParams.geoip_country || "").toUpperCase() === "US");
window.clientConfig.isGreatBritainUser = query["country"] === "gb" || (window.clientConfig.isWebview && window.clientConfig.queryParams.country === "GB");
window.clientConfig.isIndiaRegionUser = isWebviewCountriesOf(["in", "pk", "bd"]);

// ---

if (window.clientConfig.isWebview) {
  window.clientConfig.token = window.clientConfig.isWebviewAndroid
    ? `w:a:${query["aid"]}`
    : `w:i:${query["vicman_unified_id"]}`;
} else {
  const clientToken = clientStorage.getClientToken();
  if (clientToken && /b:[md]:\w{32}/i.test(clientToken)) {
    window.clientConfig.token = clientToken;
  } else {
    const type = platformIsMobile ? "m" : "d";
    const hash = generateHash();

    window.clientConfig.token = `b:${type}:${hash}`;
    clientStorage.setClientToken(window.clientConfig.token);
  }
}

// ---

if (window.clientConfig.isWebview) {
  const aid = query["aid"] || query["vicman_unified_id"];
  const num = parseInt(aid.substring(aid.length - 2), 16);
  const group = Math.floor(num / (255 / 10)) + 1;

  window.clientConfig.splitGroupId = Math.min(group, 10); // fix for ff => 11 group
  window.clientConfig.splitGroupId = window.clientConfig.splitGroupId || 1;
} else {
  const groupId = query["split_group"] || clientStorage.getSplitGroupId();
  if (groupId) {
    window.clientConfig.splitGroupId = parseInt(groupId);
  } else {
    window.clientConfig.splitGroupId = Math.floor(Math.random() * 10) + 1;
  }

  clientStorage.setSplitGroupId(window.clientConfig.splitGroupId);
}

window.clientConfig.isOld = !window.clientConfig.isNew;

window.clientConfig.features.showSubTabs = window.clientConfig.isWebview;
window.clientConfig.features.showOriginalButton = window.clientConfig.splitGroupId > 5;

// Условие для показа тунорг баннера
window.clientConfig.features.showToonorgBanner = window.clientConfig.queryParams.utm_source === "toonorg";

// ---

window.appConfig = {
  project: {
    name: process.env.REACT_APP_PROJECT_NAME || "",
    key: process.env.REACT_APP_PROJECT_KEY || "",
  },

  clientName: process.env.REACT_APP_CLIENT_NAME,
  mobileCanProcessings: envAsBoolean(process.env.REACT_APP_MOBILE_CAN_PROCESSINGS),
  isTestInstance: envAsBoolean(process.env.REACT_APP_TEST_INSTANCE),

  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || envAsBoolean(process.env.REACT_APP_DEBUG)
    || query["vicman_debug"]
    || window.localStorage.getItem("tmc:debug") !== null
    || false,

  build: process.env.REACT_APP_BUILD_INFO || {},

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    pwAssets: process.env.REACT_APP_PW_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
    proxy: process.env.REACT_APP_PW_PROXY_PATH,
    commonBackendApi: process.env.REACT_APP_COMMON_BACKEND_API_PATH,
    tempImagesUploadEndpoint: process.env.REACT_APP_TEMP_IMAGES_UPLOAD_ENDPOINT,
  },

  photolab: {
    appId: process.env.REACT_APP_PHOTOLAB_APP_ID,
    path: process.env.REACT_APP_PHOTOLAB_API_PATH,
    addTaskEndpoint: process.env.REACT_APP_PHOTOLAB_ADDTASK_ENDPOINT,
  },

  photolabSocialApi: {
    path: process.env.REACT_APP_PHOTOLAB_SOCIAL_API_PATH,
    pathTest: process.env.REACT_APP_PHOTOLAB_SOCIAL_API_TEST_PATH,
    pathWeb: process.env.REACT_APP_PHOTOLAB_SOCIAL_API_WEB_PATH,
  },

  analytics: {
    isEnabled: envAsBoolean(process.env.REACT_APP_ANALYTICS_ENABLED),
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
    overloadModeIsEnabled: envAsBoolean(process.env.REACT_APP_ANALYTICS_OVERLOAD_MODE_IS_ENABLED),
    overloadModeEvents: (process.env.REACT_APP_ANALYTICS_OVERLOAD_MODE_EVENTS || "").split(","),
    tasksIsEnabled: envAsBoolean(process.env.REACT_APP_ANALYTICS_TASKS),
  },

  processingTimings: {
    isEnabled: envAsBoolean(process.env.REACT_APP_PROCESSING_TIMINGS_ENABLED),
    endpoint: process.env.REACT_APP_PROCESSING_TIMINGS_ENDPOINT,
  },

  resultsCollect: {
    isEnabled: envAsBoolean(process.env.REACT_APP_RESULTS_COLLECT_IS_ENABLED),
    endpoint: process.env.REACT_APP_RESULTS_COLLECT_ENDPOINT,
  },

  hits: {
    isEnabled: envAsBoolean(process.env.REACT_APP_HITS_ENABLED),
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
    allowedUserGroups: envAsArrayOfNumbers(process.env.REACT_APP_HITS_ALLOWED_USER_GROUPS || ""),
    increment: parseInt(process.env.REACT_APP_HITS_INCREMENT || 1),
  },

  signals: {
    isEnabled: envAsBoolean(process.env.REACT_APP_SIGNALS_ENABLED),
    endpoint: process.env.REACT_APP_SIGNALS_ENDPOINT,
    allowedUserGroups: envAsArrayOfNumbers(process.env.REACT_APP_SIGNALS_ALLOWED_USER_GROUPS || ""),
  },

  facebook: {
    appId: 202248336556242,
  },

  sentry: {
    isEnabled: envAsBoolean(process.env.REACT_APP_SENTRY_ENABLED),
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
    sampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0.5),
  },

  googleAnalytics: {
    isEnabled: envAsBoolean(process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED) && window.clientConfig.isWeb,
    trackerId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },

  nativeAds: {
    isEnabled: envAsBoolean(process.env.REACT_APP_NATIVE_ADS_IS_ENABLED),
  },

  webAds: {
    isEnabled: window.webAdsIsEnabled,
  },

  webview: {
    uploadEnabledPercent: parseFloat(process.env.REACT_APP_WEBVIEW_UPLOAD_ENABLED),
    uploadDisabledCountries: (process.env.REACT_APP_WEBVIEW_UPLOAD_DISABLED_COUNTRIES || "")
      .split(",")
      .map(c => c.toLowerCase()),
    rateAppDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_RATEAPP_DIALOG_PERCENT),
    clonesDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_CLONES_DIALOG_PERCENT),
    testerClientsIds: [
      "BBD0D7BE-A31C-4F35-A62A-19527442F394".toLowerCase(), // alex.f ios
      "cc80644f834276afad6ef88cda87eefa", // tarahonich android
    ],
  },

  processings: {
    processSkeleton: {
      isEnabled: envAsBoolean(process.env.REACT_APP_PROCESSINGS_PROCESS_SKELETON_IS_ENABLED),
      countries: (process.env.REACT_APP_PROCESSINGS_PROCESS_SKELETON_COUNTRIES || "").split(","),
    },
  }
};

// ---

window.creativesLog = {
  _log: {},
  log: function(creativeId, data) {
    if (typeof window.creativesLog._log[creativeId] === "undefined") {
      window.creativesLog._log[creativeId] = [];
    }

    window.creativesLog._log[creativeId].push({at: Date.now(), ...data});
  },
};

// ---

function envAsBoolean(input) {
  return input === "true";
}

function envAsArrayOfNumbers(input, delimiter = ",") {
  return input
    .split(delimiter)
    .map((item) => parseInt(item))
    .filter((item) => !isNaN(item));
}

function envAsDateFromDateString(input) {
  return new Date(input);
}
