import Creative from "./Creative";
import {creativeGroups} from "./config/groups";

export default class Processing {

  static EXTRA_CREATED_AT = "created_at";
  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_VECTOR_MEDIATOR_TASK = "vector_mediator_task";
  static EXTRA_CARTOON_VECTOR_MEDIATOR_TASK = "cartoon_vector_mediator_task";
  static EXTRA_COLLAGES_TYPES = "collages_types";

  _file = null;
  _site = "";
  _groups = [];
  _creatives = [];
  _watermarkName = undefined;
  _language = "en";
  _extra = {};
  _tasks = {};

  get file() {
    return this._file;
  }

  get site() {
    return this._site;
  }

  get watermarkName() {
    return this._watermarkName;
  }

  get language() {
    return this._language;
  }

  /** @returns {Creative[]} */
  get creatives() {
    return this._creatives;
  }

  get groups() {
    return this._groups;
  }

  setFile(file) {
    this._file = file;
  }

  setSite(site) {
    this._site = site;
  }

  setWatermarkName(watermarkName) {
    this._watermarkName = watermarkName;
  }

  setGroups(groups) {
    this._groups = groups;
  }

  setLanguage(language) {
    this._language = language || "en";
  }

  setTask(key, task) {
    this._tasks[key] = task;
  }

  getTask(key) {
    return this._tasks[key] || null;
  }

  addCreative(creative) {
    creative.setFileId(this.file.id);

    this.creatives.push(creative);
  }

  removeCreative(creative) {
    const index = this.creatives.findIndex(c => c.id === creative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1);
    }
  }

  getCreativesInGroup(group) {
    return this.creatives.filter((c) => c.inGroup(group));
  }

  getSelectedCreativeInGroup(group) {
    return this.creatives.find((c) => c.inGroup(group) && c.isSelected);
  }

  getGender() {
    const genderCreative = this.getSelectedCreativeInGroup(creativeGroups.GENDER);
    if (genderCreative && genderCreative.isProcessed) {
      const genderTask = genderCreative.getTask("template");
      if (genderTask && genderTask.gender) {
        return genderTask.gender.value;
      }
    }

    return "unspecified";
  }

  getSkeleton() {
    const creative = this.getCreativesInGroup(creativeGroups.COMMON)
      .find((c) => c.templateId === "skeleton_process");

    if (creative && creative.isProcessed) {
      const task = creative.getTask("template");

      if (task) {
        return {
          humans: task.humans,
          originalSize: task.originalSize,
        }
      }
    }

    return undefined;
  }

  setExtra(key, value) {
    this._extra[key] = value;
  }

  hasExtra(key) {
    return this._extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this._extra[key] || defaultValue;
  }

  toJSON(pretty = false) {
    const output = {
      file: this._file,
      site: this._site,
      watermarkName: this._watermarkName,
      groups: this._groups,
      creatives: this._creatives.map((creative) => creative.data),
      language: this._language,
      extra: this._extra,
      tasks: this._tasks,
    };

    return pretty
      ? JSON.stringify(output, null, 2)
      : JSON.stringify(output);
  }

  fromObject(obj) {
    this._file = obj.file || null;
    this._site = obj.site || "";
    this._watermarkName = obj.watermarkName;
    this._groups = obj.groups || [];
    this._language = obj.language || "en";
    this._extra = obj.extra || {};
    this._tasks = obj.tasks || {};
    this._creatives = (obj.creatives || []).map((data) => {
      const creative = new Creative();
      creative.fromObject(data);

      return creative;
    });
  }
}