import React from "react";
import i18n from "../i18n";
import Modal from "../components/Modal";
import {logEvent, userEvents} from "../utils/log";
import { ExternalUrl } from "./IndexPage";
import { APPSTORE_LINK, HUAWEI_APPGALLERY_LINK, PLAY_MARKET_LINK } from "../utils/constants";
import { SvgSprite } from "../components/SvgSprite";

class GetAppsModal extends Modal {

  handleCancelButtonClick = () => {
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal modal-get-apps">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("landing__get_the_app_button")}} />
        <div className="btns-container">
          <div className="btns-market-container btns-market-middle-mb">
            <ExternalUrl className="btn-store btn-store-apple" url={APPSTORE_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"})}>
              <SvgSprite viewBox="0 0 66 80" icon="icon-ios-store" />
              <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__ios")}} />
            </ExternalUrl>
            <ExternalUrl className="btn-store" url={PLAY_MARKET_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"})}>
              <SvgSprite viewBox="0 0 64 72" icon="icon-android-store" />
              <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__android")}} />
            </ExternalUrl>
          </div>

          <div className="btns-market-container btns-market-small-container">
            <ExternalUrl className="btn-store-huawei" url={HUAWEI_APPGALLERY_LINK} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"})}>
              <SvgSprite viewBox="0 0 36 36" icon="icon-huawei-store" />
              <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__huawei")}} />
            </ExternalUrl>
          </div>

          <button
            className="btn-cancel"
            dangerouslySetInnerHTML={{__html: i18n.t("btn_cancelV2")}}
            onClick={this.handleCancelButtonClick} />
        </div>
      </div>
    </div>;
  }
}

export default GetAppsModal;
