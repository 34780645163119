import React from "react";
import * as IGS from "./ImageGallery.styled"
import {generatePath} from "react-router";
import ItemLink from "./ItemLink/ItemLink";

export default class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {combos, page} = this.props;

    return <IGS.Container>
      {transformCombos(combos).map((col, index) => (
        <IGS.Column key={index}>
          {col.map((item, i) => {
            return <ItemLink
              key={item.id}
              item={item}
              padding={calculatePaddingValue(index, i)}
              to={generatePath(page, {id: item.id})}
            />
          })}
        </IGS.Column>
      ))}
    </IGS.Container>
  }
}

const transformCombos = (combos) => {
  const firstColOrder = [];

  combos.forEach((_, index) => {
    if (index % 3 === 0) {
      firstColOrder.push(index);
    }
  })

  const columnBatches = [
    combos.filter((_, index) => firstColOrder.includes(index)),
    combos.filter((_, index) => firstColOrder.map((i) => i + 1).includes(index)),
    combos.filter((_, index) => firstColOrder.map((i) => i + 2).includes(index))
  ];

  return columnBatches;
}
const calculatePaddingValue = (columnIndex, index) => {
  if (columnIndex === 0) {
    return index % 3 === 1 ? "100%": "133%";
  }
  if (columnIndex === 1) {
    return index % 3 === 0 ? "100%": "133%";
  }
  if (columnIndex === 2) {
    return index % 3 === 2 ? "100%": "133%";
  }
};