import React from "react";
import i18n from "../i18n";
import AppContext from "../contexts/AppContext";
import IndexPage from "./IndexPage";
import {SvgSprite} from "../components/SvgSprite";
import FileChooseButton from "../components/FileChooseButton";
import HeaderClassic from "../components/HeaderClassic";
import {assetUrl, when} from "../utils/etc";
import {MENTIONS, INSTAGRAM_MENTIONS} from "../utils/constants";
import {ExternalUrl, LogoView} from "./IndexPage";
import Slider from "react-slick";
import {getCurrentLocale} from "../i18n";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {formatClassicUpdatedAt} from "../utils/date";
import {webviewOpenBrowser} from "../utils/webview";
import Modal from "../components/Modal";
import clientStotage from "../utils/client-storage";
import {UNITE_COM_LINK} from "../utils/constants";
import GetAppsModal from "./GetAppsModal";
import {AppButtons, themeKeys} from "../components/AppButtons";
import {Footer} from "../components/Footer";

export default class IndexPageV2 extends IndexPage {
  componentDidMount() {
    if (window.clientConfig.features.showToonorgBanner && !clientStotage.getShouldDisplayToonorgBanner()) {
      clientStotage.setShouldDisplayToonorgBanner();
    }
  }

  handleUniteComBannerButtonClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(UNITE_COM_LINK + "&utm_content=webview");
    }
  };

  renderWebMobileButton = () => {
    // разрешаем только китай-юзеру на андроиде
    let isAllow = window.clientConfig.isChinaUser && window.clientConfig.isWebMobileAndroid;

    // если в env разрешено грузить с мобильных - разрешаем для всех
    if (window.appConfig.mobileCanProcessings) {
      isAllow = true;
    }

    if (!isAllow) {
      return <button
        className="btn-upload-foto btn-get-app highlight"
        children={i18n.t("landing__get_the_app_button")}
        onClick={this.handleGetAppButtonClick} />;
    }

    // если китай-юзер на андроиде и в env запрещено грузить с мобильных
    // проверяем на хуавей девайс и показываем ему кнопку с HUAWEI стором
    if (window.clientConfig.isChinaUser && window.clientConfig.isWebMobileAndroid && !window.appConfig.mobileCanProcessings) {
      const isMayHuawei = window.navigator.userAgent.toLowerCase().indexOf("huawei") >= 0
        || /build\/honor\w{3}/i.test(window.navigator.userAgent);

      if (isMayHuawei) {
        return <button
          className="btn-upload-foto btn-get-app highlight"
          children={i18n.t("landing__get_the_app_button")}
          onClick={this.handleHuaweiGetAppButtonClick} />;
      }

      if (!clientStotage.getHuaweiDialogWasShown()) {
        return <button
          className="btn-upload-foto btn-get-app highlight"
          children={i18n.t("landing__get_the_app_button")}
          onClick={() => {
            this.context.pushModal(<HuaweiModal
              key={"IndexPage_HuaweiModal"}
              onFileSelected={this.handleFileSelected}
            />);
          }} />;
      }
    }

    return <FileChooseButton
      onFileSelected={this.handleFileSelected}
      className="btn-upload-foto"
      children={i18n.t("landing__select_photo_button")} />;
  };

  handleGetAppModal = () => {
    this.context.pushModal(<GetAppsModal
      key="IndexPage-GetAppsModal"
    />);
  }

  renderWebDesktopCircledButton = () => {
    const lang = getCurrentLocale().substring(0, 2).toLowerCase();

    return <button
      onClick={this.handleGetAppModal}
      className="btn-upload-foto-desktop">
      <SvgSprite viewBox="0 0 228 228" icon={`btn-get-app`} />
      <span className="btn-icon">
        <SvgSprite viewBox="0 0 228 228" icon={`icon-phone`} />
      </span>
    </button>;

    return <FileChooseButton
      onClick={() => hitEvent(hits.PAGE_INDEX_CIRCLE_CLICK)}
      onFileSelected={this.handleFileSelected}
      className="btn-upload-foto-desktop">
      <SvgSprite viewBox="0 0 228 228" icon={`btn-upload-lang-${lang}`} />
      <span className="btn-icon" />
      <span className="btn-upload-foto-desktop-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__select_photo_button")}} />
    </FileChooseButton>;
  };

  renderWebDesktopSquaredButton = () => {
    return <FileChooseButton
      onClick={() => hitEvent(hits.PAGE_INDEX_SQUARE_CLICK)}
      onFileSelected={this.handleFileSelected}
      className="btn-upload-v2">
      <span className="btn-icon-plus" />
      <span className="" dangerouslySetInnerHTML={{__html:i18n.t("landing__select_photo_button")}} />
    </FileChooseButton>
  };

  handleHeadingClick = (e) => {
    if (e.target && e.target.tagName && e.target.tagName.toLowerCase() === "span") {
      this.headingClicks = (this.headingClicks || 0) + 1;
      if (this.headingClicks > 10) {
        this.headingClicks = 0;

        const testUrl = process.env.REACT_APP_INDEXPAGE_REDIRECT_TESTURL;
        if (testUrl && testUrl.length > 0) {
          const url = new URL(testUrl);

          Object.keys(window.clientConfig.queryParams).forEach((key) => {
            url.searchParams.set(key, window.clientConfig.queryParams[key]);
          })

          window.location.href = url.toString();
        }
      }
    }
  };

  handleToonCoinBannerClick = (e) => {
    window.open('https://get.toon.org/', '_blank');
  }

  renderMain = () => {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    // const indexImagesSplitGroups = {
    //   "default_desktop": ["1000-desktop", 148, "1001-desktop", 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160],
    //   "default_mobile": ["1000-mobile", 148, "1001-mobile", 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160],
    // };
    //
    // const indexImages = indexImagesSplitGroups["default_mobile"];

    let indexImages = when(window.clientConfig.splitGroupId, {
      1: [5286, 5095, 5254, 4532, 5303, 5379], // коллаж + тело М
      2: [5286, 5696, 5254, 5095, 4532, 5303, 5379], // коллаж + тело Ж
      3: [5095, 5286, 5254, 4532, 5303, 5379], // тело М + коллаж
      4: [5095, 5254, 5286, 4532, 5303, 5379], // тело М + тело Ж
      5: [5254, 5286, 5095, 4532, 5303, 5379], // тело Ж + коллаж
      6: [5254, 5095, 5286, 4532, 5303, 5379], // тело Ж + тело М
      7: [4532, 5286, 5095, 5254, 5303, 5379], // картинка + коллаж
      8: [5303, 5286, 5095, 5254, 4532, 5379], // картинка + коллаж
      9: [5379, 5286, 5095, 5254, 5303, 4532], // картинка + коллаж
      10: [5286, 5095, 5254, 4532, 5303, 5379].shuffle(),
    });

    indexImages.unshift(1650966615);
    indexImages.unshift(1650966614);
    indexImages.unshift(1650966613);
    indexImages.unshift(6885);

    if (window.clientConfig.isWebDesktop) {
      indexImages = ["pic1", "pic2", "pic3", "pic4", "pic5"];
    }

    const titleContactsFormText = i18n.t("title_contacts_form")
      .replace("Photo Lab", "<a href='https://y3nay.app.goo.gl/toonmecompl' target='_blank'>Photo Lab</a>");

    const updatedAt = formatClassicUpdatedAt();

    return <>
      <div className="banner-ad" hidden={true}>
        <div className="container">
          <img className="banner-ad-image"
               src={`${assetUrl("assets/simpsons_sticker@3x.png")}`}
               alt="" />
          <h3 className="banner-ad-title"
              dangerouslySetInnerHTML={{__html:i18n.t("banner_ad_title")}} />
          <h3 className="banner-ad-title-mob"
              dangerouslySetInnerHTML={{__html:i18n.t("banner_ad_title_mob")}}
              style={{lineHeight: "20px"}} />
          {/*<p className="banner-ad-text" dangerouslySetInnerHTML={{__html:i18n.t("banner_ad_text")}} />*/}
        </div>
      </div>
      <ToonCoinBannerView 
        hidden={!window.clientConfig.features.showToonorgBanner && !clientStotage.getShouldDisplayToonorgBanner()}
        onClick={this.handleToonCoinBannerClick} 
      />
      <main className="main-page">
        <HeaderClassic hidden={window.clientConfig.isWebview} />
        <div className="container">
          <div className="main-page-content">
            <div className="main-page-title-container">
              {/*<h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__title_part_1")}} />*/}
              <h2 className="main-page-title"
                  dangerouslySetInnerHTML={{__html:i18n.t("landing__title_part_2")}}
                  onClick={this.handleHeadingClick} />

              <p className="main-page-subtitle">{i18n.t("landing__subtitle")}</p>
            </div>

            {/*{window.clientConfig.isWebDesktop && isSquareButton && this.renderWebDesktopSquaredButton()}*/}

            <section className="wr-slider-images">
              <Slider {...sliderSettings}>
                {indexImages.map((number) => <SlideView key={number} number={number} />)}
              </Slider>
              {window.clientConfig.isWebview && this.renderWebviewButton()}
              {window.clientConfig.isWebMobile && this.renderWebMobileButton()}

              {/* <div className="square" /> */}
            </section>

            {/* <div className="main-page-labs-container" hidden={window.clientConfig.isWebMobile}>
              <LabsView />
              <div className="main-page-labs-info">
                <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__labs_choice")}} />
                <Link to={routes.LABS_INDEX} className="btn-go-labs" dangerouslySetInnerHTML={{__html: i18n.t("landing__labs_button")}} />
              </div>
            </div> */}

            <p className="updated-at" hidden={!updatedAt}>
              <span className="updated-at-title" dangerouslySetInnerHTML={{__html: i18n.t("updated_at", {date: updatedAt})}} />
            </p>
          </div>

          {/*<div className="main-page-unite-container">
            <UniteView />
            <div className="main-page-unite-info">
              <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__unite_choice")}} />
              <ExternalUrl
                url={UNITE_COM_LINK + "&utm_content=web"}
                onClick={this.handleUniteComBannerButtonClick}
                className="btn-go-unite"
                dangerouslySetInnerHTML={{__html: i18n.t("landing__unite_button")}} />
            </div>
          </div>*/}

          {window.clientConfig.isWebDesktop && this.renderWebDesktopCircledButton()}

          <MentionView />

          {window.clientConfig.isWeb && <div className="instagram">
            <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("landing__toonme_on_instagram")}} />
            
            <div className="posts-list">
              {INSTAGRAM_MENTIONS.map((data, key) => {
                return <div key={key} className="post">
                  <div className="post-image">
                    <ExternalUrl url={data.url}>
                      <img src={assetUrl(data.images[0])} alt="" />
                    </ExternalUrl>
                  </div>
                  <div className="user-info">
                    <div className="user-name">
                      <img src={assetUrl(data.avatar)} alt="" />
                      <p>{data.username}</p>
                    </div>
                    <div className="user-likes">
                      <p>{data.likes}</p>
                      <SvgSprite viewBox="0 0 24 24" icon="icon-like" />
                    </div>
                  </div>
                </div>;}
              )}
            </div>
          </div>}

          {window.clientConfig.isWeb && <AppButtons theme={themeKeys.dark} center={true} mb={80} />}
        </div>
        {window.clientConfig.isWeb && <Footer />}
      </main>
      </>;
  }
}

IndexPageV2.contextType = AppContext;

export function SlideView({number}) {
  const jpeg = [
    assetUrl(`assets/img/slider2/${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider2/${number}@2x.jpg`) + " 2x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider2/${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider2/${number}@2x.webp`) + " 2x"
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpeg} alt="Demo" />
      </picture>
    </div>
  </div>;
}

export function LabsView() {
  const pngMob = [
    assetUrl(`assets/img/bg/img_labs_mob.png`) + " 1x",
    assetUrl(`assets/img/bg/img_labs_mob@2x.png`) + " 2x",
    assetUrl(`assets/img/bg/img_labs_mob@3x.png`) + " 3x"
  ].join(", ");

  const webpMob = [
    assetUrl(`assets/img/bg/img_labs_mob.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_labs_mob@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_labs_mob@3x.webp`) + " 3x"
  ].join(", ");

  const jpg = [
    assetUrl(`assets/img/bg/img_labs.jpg`) + " 1x",
    assetUrl(`assets/img/bg/img_labs@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/img_labs@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img_labs.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_labs@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_labs@3x.webp`) + " 3x"
  ].join(", ");

  return <picture>
    <source media="(max-width: 900px)" srcSet={webpMob} type="image/webp" />
    <source media="(max-width: 900px)" srcSet={pngMob} />
    <source srcSet={webp} type="image/webp" />
    <img srcSet={jpg} alt="Demo" />
  </picture>;
}

export function UniteView() {
  const pngMob = [
    assetUrl(`assets/img/bg/img_unite_mob.png`) + " 1x",
    assetUrl(`assets/img/bg/img_unite_mob@2x.png`) + " 2x",
    assetUrl(`assets/img/bg/img_unite_mob@3x.png`) + " 3x"
  ].join(", ");

  const webpMob = [
    assetUrl(`assets/img/bg/img_unite_mob.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_unite_mob@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_unite_mob@3x.webp`) + " 3x"
  ].join(", ");

  const png = [
    assetUrl(`assets/img/bg/img_unite.png`) + " 1x",
    assetUrl(`assets/img/bg/img_unite@2x.png`) + " 2x",
    assetUrl(`assets/img/bg/img_unite@3x.png`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img_unite.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_unite@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_unite@3x.webp`) + " 3x"
  ].join(", ");

  return <picture>
    <source media="(max-width: 900px)" srcSet={webpMob} type="image/webp" />
    <source media="(max-width: 900px)" srcSet={pngMob} />
    <source srcSet={webp} type="image/webp" />
    <img srcSet={png} alt="Demo" />
  </picture>;
}

export class MentionView extends React.Component {
  render() {
    if (window.clientConfig.isWebview) {
      return null;
    }

    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 10000,
          settings: 'unslick'
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 2,
          }
        }
      ],
    };

    const links = Object.keys(MENTIONS).map((mention, key) => (
      <ExternalUrl key={key} url={MENTIONS[mention]} children={LogoView("v2/" + mention)} />
    ));

    return <section className="wr-slider-logos">
      <Slider {...sliderSettings}>
        {links}
      </Slider>
    </section>;
  }
}

class HuaweiModal extends Modal {

  componentDidMount() {
    logEvent(userEvents.HUAWEI_MODAL_SHOWN);
  }

  handleOkButtonClick = () => {
    logEvent(userEvents.HUAWEI_MODAL_BUTTON_INSTALL);
    clientStotage.setHuaweiDialogWasShown(true);
    window.location.href = "https://appgallery.huawei.com/#/app/C104284935";
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.HUAWEI_MODAL_BUTTON_CANCEL);
    clientStotage.setHuaweiDialogWasShown(true);
    this.props.onFileSelected(file);
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("huawei_appgallery_modal__title")}} />
        {/*<p dangerouslySetInnerHTML={{__html: i18n.t("huawei_appgallery_modal__message")}} />*/}
        <div className="btns-container">
          <div className="btns-container_share">
            <FileChooseButton
              className="btn-paint"
              children={i18n.t("huawei_appgallery_modal__btn_cancel")}
              onFileSelected={this.handleFileSelected} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="btn-paint"
              children={i18n.t("huawei_appgallery_modal__btn_ok")}
              onClick={this.handleOkButtonClick}/>
          </div>
        </div>
      </div>
    </div>;
  }
}

function ToonCoinBannerView({hidden, onClick}) {
  if (hidden) {
    return null;
  }

  const pngMob = [
    assetUrl(`assets/img/tooncoin/crypto_mob.png`) + " 1x",
    assetUrl(`assets/img/tooncoin/crypto_mob@2x.png`) + " 2x",
    assetUrl(`assets/img/tooncoin/crypto_mob@3x.png`) + " 3x"
  ].join(", ");

  const webpMob = [
    assetUrl(`assets/img/tooncoin/crypto_mob.webp`) + " 1x",
    assetUrl(`assets/img/tooncoin/crypto_mob@2x.webp`) + " 2x",
    assetUrl(`assets/img/tooncoin/crypto_mob@3x.webp`) + " 3x"
  ].join(", ");

  const png = [
    assetUrl(`assets/img/tooncoin/crypto.png`) + " 1x",
    assetUrl(`assets/img/tooncoin/crypto@2x.png`) + " 2x",
    assetUrl(`assets/img/tooncoin/crypto@3x.png`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/tooncoin/crypto.webp`) + " 1x",
    assetUrl(`assets/img/tooncoin/crypto@2x.webp`) + " 2x",
    assetUrl(`assets/img/tooncoin/crypto@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="banner-ad banner-tooncoin" hidden={false} onClick={onClick}>
    <div className="container">
      <picture>
        <source srcSet={webpMob} media="(max-width: 900px)" />
        <source srcSet={pngMob} media="(max-width: 900px)" type="image/webp" />
        <source srcSet={webp} type="image/webp" />
        <img srcSet={png} alt="." />
      </picture>
    </div>
  </div>;
}