import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../contexts/AppContext";
import {logEvent, userEvents} from "../../utils/log";
import {creativeImageEventProps} from "../../utils/creative";

function passPreviewUrl(props, prevProps) {
  if (props.previewUrl && props.previewUrl !== prevProps.previewUrl) {
    return !/bodychooser/.test(props.previewUrl);
  }

  return false;
}

class TabItemView extends React.Component {

  componentDidMount() {
    if (passPreviewUrl(this.props, {})) {
      this.handlePreviewImageLoadStart();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (passPreviewUrl(this.props, prevProps)) {
      this.handlePreviewImageLoadStart();
    }
  }

  handlePreviewImageLoadStart = () => {
    this.props.onPreviewImageLoadStart && this.props.onPreviewImageLoadStart(this.props.group);
    this.startLoadMs = Date.now();
    clearTimeout(this.loadTimer);
    this.loadTimer = setTimeout(this.handlePreviewImageLoadingMoreThanOneSecond, 1000);
  };

  handlePreviewImageLoadingMoreThanOneSecond = () => {
    logEvent(userEvents.CREATIVE_IMAGE_LOAD_1SEC, creativeImageEventProps(this.props.creative, "tab"));
  };

  handlePreviewImageLoaded = (e) => {
    if (passPreviewUrl(this.props, {})) {
      clearTimeout(this.loadTimer);

      this.props.onPreviewImageLoaded && this.props.onPreviewImageLoaded(this.props.group);

      const duration = Date.now() - this.startLoadMs;
      if (duration >= 3000) {
        logEvent(userEvents.CREATIVE_IMAGE_LOAD_LONG, creativeImageEventProps(this.props.creative, "tab", {duration}));
      }
    }
  };

  handlePreviewImageLoadError = (e) => {
    clearTimeout(this.loadTimer);

    const duration = Date.now() - this.startLoadMs;
    logEvent(userEvents.CREATIVE_IMAGE_LOAD_ERROR, creativeImageEventProps(this.props.creative, "tab", {duration}));
  };

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e, this.props.group);
  };

  render() {
    const tabClassNames = ["collage__tab_" + this.props.group];
    if (this.props.isActive) {
      tabClassNames.push("active-tab");
    }

    const newLabel = [
      // creativeGroups.CARTOON_VECTOR_BODY,
    ].indexOf(this.props.group) > -1
      ? <span className="new-label-upd">new</span>
      : undefined;

    let preview = undefined;
    if (this.props.previewUrl) {
      preview = <img
        src={this.props.previewUrl}
        alt="Preview"
        onLoad={this.handlePreviewImageLoaded}
        onError={this.handlePreviewImageLoadError}
      />;
    }

    return <button className={tabClassNames.join(" ")} onClick={this.handleClick}>
      {newLabel}
      {preview}
    </button>;
  }
}

TabItemView.contextType = AppContext;
TabItemView.propTypes = {
  group: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onPreviewImageLoadStart: PropTypes.func,
  onPreviewImageLoaded: PropTypes.func,
}

export default TabItemView;