import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

export const Footer = () => {
  return <FooterStyled>
    <div className="wrapper">
      <p className="copyright" dangerouslySetInnerHTML={{__html: i18n.t("pho_to__link_text", {href: "https://y3nay.app.goo.gl/toonmecompl"})}} />

      <div className="info">
        <a href="https://pho.to/privacy" className="privacy" target="_blank" rel="noopener noreferrer">{i18n.t("privacy_policy__link")}</a>

        <a href="mailto:contact@toonme.com" className="btn-contacts-form" dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />
      </div>
    </div>
  </FooterStyled>
}

const FooterStyled = styled.footer`
  background: #fff;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1152px;
    box-sizing: border-box;
    padding: 24px 16px;
    margin: 0 auto;

    p, a {
      color: #999;
      font-size: 16px;
      line-height: 1.5;

      a {
        color: #4d5ef6;
      }
    }

    .copyright a  {
      transition: 0.3s;

      @media all and (min-width: 992px) {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    @media all and (max-width: 991px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 16px 20px;

      .copyright,
      .copyright a {
        font-size: 10px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 48px;

    a {
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.3s;

      @media all and (min-width: 992px) {
        &:hover {
          color: #4d5ef6;
        }
      }
    }

    @media all and (max-width: 991px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }
`;