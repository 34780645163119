import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/hi";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/pt";
import "dayjs/locale/ru";
import "dayjs/locale/zh-cn";
import "dayjs/locale/th";

import i18n from "../i18n";

export function formatClassicUpdatedAt() {
  const date = process.env.REACT_APP_UPDATED_AT_CLASSIC;

  if (!date) {
    return null;
  }

  let lang = window.clientConfig.lang.substring(0, 2).toLowerCase();
  lang = i18n.exists("updated_at", {lng: lang, fallbackLng: lang}) ? lang : "en";

  const updatedAt = dayjs(date);

  const format = {
    de: "D. MMMM YYYY",
    en: "MMMM D, YYYY",
    es: "D [de] MMMM [de] YYYY",
    fr: "D MMMM YYYY",
    hi: "D MMMM, YYYY",
    it: "D MMMM YYYY",
    ja: "YYYY年M月D日",
    ko: "YYYY년 MMMM D일",
    pt: "D [de] MMMM [de] YYYY",
    ru: "D MMMM YYYY года",
    th: "D MMM YYYY",
    zh: "YYYY年M月D日",
  };

  return updatedAt.locale(lang).format(format[lang]);
}

export function formatLabsUpdatedAt() {
  let lang = window.clientConfig.lang.substring(0, 2).toLowerCase();
  lang = i18n.exists("updated_at_labs", {lng: lang, fallbackLng: lang}) ? lang : "en";

  const updatedAt = dayjs(process.env.REACT_APP_UPDATED_AT_LABS);

  const format = {
    en: "MMM D, YYYY",
  };

  return updatedAt.locale(lang).format(format[lang]);
}
