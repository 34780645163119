import React from "react";
import i18n from "../../i18n";
import Modal from "../../components/Modal";
import PropTypes from "prop-types";
import FileChooseButton from "../../components/FileChooseButton";

export default class ReturningModal extends Modal {

  handleCloseButtonClick = (e) => {
    this.context.setIsShowReturningModal(false);
    super.handleCloseButtonClick(e);
  };

  handleFileSelected = (e) => {
    this.context.setIsShowReturningModal(false);
    this.props.onFileSelected(e);
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container modal-container-body-select">
      <div className="modal">
        <div className="btns-container">
          <button
            className="btn-select-style btn-select-head"
            children={i18n.t("back_to_result")}
            onClick={this.handleCloseButtonClick} />
          <FileChooseButton
            onFileSelected={this.handleFileSelected}
            className="btn-select-style btn-select-body"
            children={i18n.t("try_another_photo")} />
        </div>
      </div>
    </div>;
  }
}

ReturningModal.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
};