export const genders = {
  male: "male",
  female: "female",
};

export const heads = {
  head_6819: 6819,
  head_6611: 6611,
};

const bodies = [
  // vector
  {
    id: 6211,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6254,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6256,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6257,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6255,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6349,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6258,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6253,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6233,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6234,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6228,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6247,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6247,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6249,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6243,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6241,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6235,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6236,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6237,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6238,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6239,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6240,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6230,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6229,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6225,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6224,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6223,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6222,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6221,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6220,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6212,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6250,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6214,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6216,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6218,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6219,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6252,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6245,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6246,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6248,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6251,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6244,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6242,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6232,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6231,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6227,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6226,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6217,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6213,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6215,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6348,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6278,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6278,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6277,
    gender: genders.female,
    "suppressed": true,
    head: heads.head_6819,
  },
  {
    id: 6277,
    gender: genders.male,
    "suppressed": true,
    head: heads.head_6819,
  },
  {
    id: 6276,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6276,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6273,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6274,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6271,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6265,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6282,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6281,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6280,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6279,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6275,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6272,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6270,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6269,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6268,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6266,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6267,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6350,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6264,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6263,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6262,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6260,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6259,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6261,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6290,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6289,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6287,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6351,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6283,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6288,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6285,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6286,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6291,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6292,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6292,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6284,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6293,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6294,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6295,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6296,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6297,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6298,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6299,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6301,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6302,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6303,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6304,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6305,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6306,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6307,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6309,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6310,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6311,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6312,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6308,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6313,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6300,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6314,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6315,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6316,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6317,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6318,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6319,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6320,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6321,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6322,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6322,
    gender: genders.male,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6323,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6324,
    gender: genders.female,
    isHidden: true,
    head: heads.head_6819,
  },
  {
    id: 6325,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6326,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6327,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6328,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6329,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6330,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6331,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6332,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6334,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6344,
    gender: genders.male,
    head: heads.head_6819,
  },
  {
    id: 6333,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6335,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6336,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6337,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6338,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6339,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6340,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6341,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6342,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6343,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6345,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6346,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6347,
    gender: genders.female,
    head: heads.head_6819,
  },
  {
    id: 6946,
    gender: genders.male,
    head: heads.head_6611,
  },
  {
    id: 6968,
    gender: genders.male,
    head: heads.head_6611,
  },
  {
    id: 6970,
    gender: genders.male,
    head: heads.head_6611,
  },
  {
    id: 6964,
    gender: genders.female,
    head: heads.head_6611,
  },
  {
    id: 6948,
    gender: genders.female,
    head: heads.head_6611,
  },
  {
    id: 6947,
    gender: genders.female,
    head: heads.head_6611,
  },
  {
    id: 6962,
    gender: genders.female,
    head: heads.head_6611,
  },
  {
    id: 6966,
    gender: genders.female,
    head: heads.head_6611,
  },

  // toon
  {id: 6366, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6367, gender: genders.male, head: heads.head_6819},
  {id: 6367, gender: genders.female, head: heads.head_6819},
  {id: 6368, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6369, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6370, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6371, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6372, gender: genders.female, head: heads.head_6819},
  {id: 6373, gender: genders.female, isHidden: true, head: heads.head_6819},
  {id: 6374, gender: genders.male, head: heads.head_6819},
  {id: 6375, gender: genders.male, isHidden: true, head: heads.head_6819},
  {id: 6376, gender: genders.male, head: heads.head_6819},
  {id: 6377, gender: genders.female, head: heads.head_6819},
];

export function getBodiesListByGroup(group) {
  return bodies.slice();
}