/* eslint-disable no-unused-vars */

import {creativeGroups} from "./groups";
import {setCreativesGroup, comboWithLayout} from "./helpers";
import {assetUrl} from "../../utils/etc";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  const list = [
    creativeGroups.GENDER,
    creativeGroups.CROP,
    creativeGroups.CARTOON_ANIM,
    creativeGroups.SIMPSONS,
    creativeGroups.SIMPSONS_2,
    creativeGroups.TAB_6750,
    creativeGroups.DISNEY,
    creativeGroups.DISNEY_WITH_5273,
    creativeGroups.DISNEY_2D,
    creativeGroups.DISNEY2DMIX,
    creativeGroups.DISNEY_5277,
    creativeGroups.DISNEY_5361,
    creativeGroups.GLAMOUR,
    creativeGroups.TAB_5379,
    creativeGroups.STROKES,
    creativeGroups.TAB_5378,
    creativeGroups.TAB_5390,
    creativeGroups.VECTOR,
    creativeGroups.ARCHER,
    creativeGroups.CARICATURE_2,
    creativeGroups.CARICATURE,
    creativeGroups.BARBIE,
    creativeGroups.CARTOON,
    creativeGroups.CARTOON2,
    creativeGroups.POPART,
    creativeGroups.CARLAFUENTESART,
  ];

  return list;
}

function getCommonCreatives() {
  return [
    new CreativeConfig(creativeGroups.CROP, 4021, handlersNames.TEMPLATE),
    new CreativeConfig(creativeGroups.GENDER, "gender_classifier", handlersNames.TEMPLATE),
  ];
}

function getCreatives() {
  return [
    ...setCreativesGroup(creativeGroups.DISNEY_WITH_5273, [
      comboWithLayout([4427, 5273]),
    ]),

    ...setCreativesGroup(creativeGroups.DISNEY, [
      comboWithLayout([4427]).setSelected(),
      comboWithLayout([4780]),
      comboWithLayout([4246]).setHdTemplateId(4201),
      comboWithLayout([4241]).setHdTemplateId(4177),
    ]),

    ...setCreativesGroup(creativeGroups.DISNEY_2D, [
      comboWithLayout([4428]).setSelected(),
      comboWithLayout([4260]).setHdTemplateId(4259),
      comboWithLayout([4262]).setHdTemplateId(4261),
      comboWithLayout([4244]).setHdTemplateId(4197),
      comboWithLayout([4245]).setHdTemplateId(4198),
    ]),

    ...setCreativesGroup(creativeGroups.ARCHER, [
      comboWithLayout([4437]).setSelected(),
      comboWithLayout([4234]).setHdTemplateId(4134),
    ]),

    ...setCreativesGroup(creativeGroups.SIMPSONS, [
      comboWithLayout([5418]),
      comboWithLayout([5409]),
      comboWithLayout([4578]),
      comboWithLayout([4554]),
    ]),

    ...setCreativesGroup(creativeGroups.CARICATURE, [
      comboWithLayout([4373]).setSelected(),
      comboWithLayout([4415]),
    ]),

    ...setCreativesGroup(creativeGroups.CARICATURE_2, [
      comboWithLayout([4653]),
      comboWithLayout([4654]),
      comboWithLayout([4661]),
      comboWithLayout([4662]),
      comboWithLayout([4664]),
    ]),

    ...[
      new CreativeConfig(creativeGroups.VECTOR, 4252, handlersNames.VECTOR),
    ],

    ...setCreativesGroup(creativeGroups.GLAMOUR, [
      comboWithLayout([6486]).setSelected(window.clientConfig.splitGroupId < 9),
      comboWithLayout([4532]).setSelected(window.clientConfig.splitGroupId === 9),
      comboWithLayout([5469]).setSelected(window.clientConfig.splitGroupId === 10),
      comboWithLayout([5513]),
      comboWithLayout([4462]),
      comboWithLayout([4372]),
    ]),

    ...setCreativesGroup(creativeGroups.BARBIE, [
      comboWithLayout([4543]),
    ]),

    ...setCreativesGroup(creativeGroups.CARTOON, [
      comboWithLayout([4548]),
      comboWithLayout([4558]),
      comboWithLayout([4559]).setSelected(),
    ]),

    ...setCreativesGroup(creativeGroups.CARTOON2, [
      comboWithLayout([4558]),
    ]),

    ...setCreativesGroup(creativeGroups.CARTOON_ANIM, [
      new CreativeConfig("nogroup", 4530, handlersNames.CARTOON_ANIM),
    ]),

    ...setCreativesGroup(creativeGroups.POPART, [
      comboWithLayout([6209]),
      comboWithLayout([5417]),
      comboWithLayout([5408]),
      comboWithLayout([4721]),
    ]),

    ...setCreativesGroup(creativeGroups.CARLAFUENTESART, [
      comboWithLayout([5032]),
    ]),

    ...setCreativesGroup(creativeGroups.DISNEY_5277, [
      comboWithLayout([5277]).setSelected(),
      comboWithLayout([5304]),
      comboWithLayout([5279]),
    ]),

    ...setCreativesGroup(creativeGroups.DISNEY2DMIX, [
      comboWithLayout([5355]),
    ]),

    ...setCreativesGroup(creativeGroups.DISNEY_5361, [
      comboWithLayout([5365]).setSelected(),
      comboWithLayout([5367]),
      comboWithLayout([5361]),
      comboWithLayout([5357]),
    ]),

    ...setCreativesGroup(creativeGroups.TAB_5378, [
      comboWithLayout([5378]).setSelected(),
      comboWithLayout([5308]),
    ]),

    ...setCreativesGroup(creativeGroups.TAB_5379, [
      comboWithLayout([5379]).setSelected(),
      comboWithLayout([5440]),
      comboWithLayout([5441]),
      comboWithLayout([5380]),
    ]),

    ...setCreativesGroup(creativeGroups.TAB_5390, [
      comboWithLayout([5390]).setSelected(window.clientConfig.splitGroupId < 4),
      comboWithLayout([5391]).setSelected(window.clientConfig.splitGroupId < 8),
      comboWithLayout([5392]).setSelected(window.clientConfig.splitGroupId >= 8),
    ]),

    ...setCreativesGroup(creativeGroups.GENDERSWAP_MALE, [
      comboWithLayout([5505]),
    ]),

    ...setCreativesGroup(creativeGroups.GENDERSWAP_FEMALE, [
      comboWithLayout([5508]),
    ]),

    ...setCreativesGroup(creativeGroups.STROKES, [
      comboWithLayout([5605]).setHdTemplateId(5601),
    ]),

    ...setCreativesGroup(creativeGroups.SIMPSONS_2, [
      comboWithLayout([
        {id: 1978, images: [{src: "@"}, {src: assetUrl(`assets/photolab/simpsons/background_blue.jpg`)}]},
        6152
      ]).setSelected(window.clientConfig.splitGroupId <= 3),
      comboWithLayout([
        {id: 1978, images: [{src: "@"}, {src: assetUrl(`assets/photolab/simpsons/background_blue.jpg`)}]},
        5924
      ]).setSelected(window.clientConfig.splitGroupId <= 6),
      comboWithLayout([
        {id: 1978, images: [{src: "@"}, {src: assetUrl(`assets/photolab/simpsons/background_blue.jpg`)}]},
        5989
      ]).setSelected(window.clientConfig.splitGroupId <= 10),
      comboWithLayout([
        {id: 1978, images: [{src: "@"}, {src: assetUrl(`assets/photolab/simpsons/background_blue.jpg`)}]},
        5938
      ]).setSelected(window.clientConfig.splitGroupId <= 8),
    ]),

    ...setCreativesGroup(creativeGroups.TAB_6750, [
      comboWithLayout([6750]),
    ]),

  ];
}


export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function() {
    let list = [
      ...getCommonCreatives(),
      ...getCreatives(),
    ];

    return list;
  },
};
