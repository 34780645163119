import React from "react";
import i18n from "../../i18n";
import * as creativeUtils from "../../utils/creative";
import {SvgSprite} from "../../components/SvgSprite";
import {
  SvgFacebookShareIcon,
  SvgInstagramShareIcon,
  SvgSnapchatShareIcon
} from "../../ui/svg";
import DownloadModalDefault from "../result/DownloadModal";
import {creativeGroups} from "../../photolab/config/groups";
import routes from "../../routes";
import {generateRandomString} from "../../utils/text";
import {assetUrl, pwAssetUrl} from "../../utils/etc";

export default class DownloadModal extends DownloadModalDefault {

  renderSnapchatModal = () => {
    let creativeRawUrl = this.props.creative._.getFile("template");

    return <div className="modal-container modal-container-snapchat">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("snap_dialog__message")}} />
        <div className="check-foto-container">
          <div className={"check-foto " + ((this.state.classCheckFoto === false) ? 'active' : "")} onClick={() => this.shareSnapchat(false)}>
            <img src={this.props.creative.file.url} alt="Result" />
          </div>
          <div className={"check-foto " + (this.state.classCheckFoto ? 'active' : "")} onClick={() => this.shareSnapchat(true)}>
            <img src={creativeRawUrl} alt="Raw" />
          </div>
        </div>
        <div className="btns-container">
          <button
            className="btn-empty btn-download"
            onClick={() => this.setState({view: "download"})}>
              <SvgSprite viewBox="0 0 32 54" icon="icon-back" />
              <span children={i18n.t("snap_dialog__back_button")} />
          </button>
        </div>
      </div>
    </div>;
  };

  renderClonesModal = () => {
    return <div className="modal-container modal-container-clones">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("clones_dialog__message")}} />
        <div className="btns-container">
          <button
            className="btn-paint"
            children={i18n.t("clones_dialog__accept_button")}
            onClick={this.handleClonesDialogAcceptButtonClick} />
          <button
            className="btn-empty"
            children={i18n.t("clones_dialog__skip_button")}
            onClick={this.handleClonesDialogSkipButtonClick} />
          </div>
          <SvgSprite className="icon-boom1" viewBox="0 0 532 521" icon="icon-boom1" />
          <SvgSprite className="icon-boom2" viewBox="0 0 420 400" icon="icon-boom2" />
      </div>
    </div>;
  };

  renderRateAppModal = () => {
    return <div className="modal-container modal-container-rate">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__title")}} />
        <p className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message-bold")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message")}} />
        <div className="btns-container">
          <button
            className="btn-paint"
            children={i18n.t("rateapp_dialog__rate_button")}
            onClick={this.handleRateAppRateButtonClick} />
          <button
            className="btn-empty"
            children={i18n.t("rateapp_dialog__skip_button")}
            onClick={this.handleRateAppSkipButtonClick} />
        </div>
        <SvgSprite className="icon-cat2" viewBox="0 0 220 240" icon="icon-cat2" />
      </div>
    </div>;
  };

  renderDownloadModal = () => {
    const creativeIsProcessed = this.state.creative && creativeUtils.creativeIsProcessed(this.state.creative);
    const creativeIsProcessing = this.state.creative && creativeUtils.creativeIsProcessing(this.state.creative);

    const loader = <div className="loader-roller-wrapper">
      <div className="loader-roller loader-roller1">
        <div className="roller" />
        <div className="roller" />
      </div>
      <div className="loader-roller loader-roller2">
        <div className="roller" />
        <div className="roller" />
      </div>
      <div className="loader-roller loader-roller3">
        <div className="roller" />
        <div className="roller" />
      </div>
    </div>;

    const buildVersionCode = parseInt(window.clientConfig.queryParams.version_code);
    const snapchatIsAllow = this.context.installedApps.snapchat
      && this.props.tab !== creativeGroups.CARTOON_ANIM
      && (
        window.clientConfig.isWebviewIOS
        ||
        (window.clientConfig.isWebviewAndroidGooglePlay && buildVersionCode >= 103)
        ||
        (window.clientConfig.isWebviewAndroidHuawei && buildVersionCode >= 72)
      )
    ;

    const randomHash = generateRandomString(4);
    const autoAdsGotItLink = `${routes.RESULT}?t=${this.props.creative.group}&ads=1&_${randomHash}=1`;
    const isAutoAdsGotItButton = window.clientConfig.isWeb && window.appConfig.webAds.isEnabled;

    const buttons = <div className="btns-container">
      <div className="btns-container_share">
        <button
          className="btn-share btn-share-fb"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.facebook || (window.clientConfig.isWebviewIOS && this.props.tab === creativeGroups.CARTOON_ANIM)}
          onClick={this.handleShareToFacebook}>
          <SvgFacebookShareIcon />
        </button>
        <button
          className="btn-share btn-share-insta"
          hidden={window.clientConfig.isWeb || !this.context.installedApps.instagram}
          onClick={this.handleShareToInstagram}>
          <SvgInstagramShareIcon />
        </button>
        <button
          className="btn-share btn-share-snap"
          hidden={!snapchatIsAllow}
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSnapchatShareIcon />
        </button>
        <button
          hidden={isAutoAdsGotItButton}
          className="btn-paint"
          children={i18n.t("download_modal__dismiss_button")}
          onClick={this.handleGotItButtonClick} />
        <a
          hidden={!isAutoAdsGotItButton}
          href={autoAdsGotItLink}
          className="btn-paint"
          children={i18n.t("download_modal__dismiss_button")} />
      </div>
      <button
        hidden={!this.state.withHD || window.clientConfig.isWebview}
        className="btn-download"
        onClick={this.handleDownload}>
        <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
        <span children={i18n.t("download_modal__download_button")} />
      </button>
    </div>;

    return <div className="modal-container modal-container-download">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("download_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message")}} />
        {creativeIsProcessing && <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_wait")}} />}
        {creativeIsProcessed && this.state.withHD && <p className="modal-text-processing" dangerouslySetInnerHTML={{__html: i18n.t("download_modal__message_ready")}} />}
        {/*creativeIsProcessed ? buttons : loader*/}
        {creativeIsProcessed && buttons}
        <div className="modal-download-bg">
          {this.state.samplesColumns.map((col, index) => <div key={index} className="bg-column">
            {col.map((sample, i) => <SampleView
              key={sample.join("_") + `_${i}`}
              group={sample[0]}
              id={sample[1]}
              version={sample[2]}
              onLoadError={(e, duration) => this.handleSampleLoadError(index, sample, duration)}
            />)}
          </div>)}
        </div>
        <div className="animation-hearts-block">
          <img className="heart1" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart2" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart3" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart4" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart5" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart6" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart7" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart8" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart9" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart10" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart11" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart12" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart13" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart14" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart15" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart16" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart17" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart18" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart19" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart20" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart21" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart22" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart23" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart24" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart25" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart26" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart27" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart28" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart29" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart30" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart31" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart32" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart33" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart34" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart35" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart36" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart37" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart38" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart39" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
          <img className="heart40" src={assetUrl(`assets/img/icons/heart.png`)} alt='' />
        </div>
        {!this.state.creativeIsDownloaded && <div className="result-loader">
          <p dangerouslySetInnerHTML={{__html: i18n.t("result_loader__title")}} />
          {loader}
        </div>}
        <button className="btn-cancel" onClick={() => this.dismiss()}>
          <SvgSprite viewBox="0 0 16 16" icon="icon-close" />
        </button>
      </div>
    </div>;
  };
}

class SampleView extends React.Component {

  componentDidMount() {
    this.loadStartMs = Date.now();
  }

  handleLoadError = (e) => {
    const duration = Date.now() - this.loadStartMs;

    this.props.onLoadError && this.props.onLoadError(e, duration);
  };

  render() {
    const {group, id, version} = this.props;
    const webp = pwAssetUrl(`toonme/templates-samples/${group}/${id}.webp?${version}`);
    const jpeg = [
      pwAssetUrl(`toonme/templates-samples/${group}/${id}.jpeg?${version} 1x`),
      pwAssetUrl(`toonme/templates-samples/${group}/${id}@2x.jpeg?${version} 2x`)
    ].join(", ");

    return <picture>
      <source srcSet={webp} type="image/webp" />
      <img src={jpeg} alt="." onError={this.handleLoadError} />
    </picture>;
  }
}