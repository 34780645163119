import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 46px;
  padding-bottom: 76px;
  max-width: 1152px;
  margin: 0 auto;

  @media all and (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 991px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 64px;
  font-weight: 600;
  line-height: 1.38;
  margin-bottom: 80px;

  @media all and (max-width: 991px) {
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const Link = styled.a`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  text-transform: uppercase;
  padding: 0 24px;
  border-radius: 16px;
  background-color: #4d5ef6;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;

  @media all and (min-width: 992px) {
    &:hover {
      background: #0e1da6;
    }
  }

  @media all and (max-width: 991px) {
    height: 48px;
    font-size: 14px;
    letter-spacing: 0.28px;
    align-self: center;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 512px;

  @media all and (max-width: 991px) {
    width: 100%;
    max-width: 600px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;