import React from "react";
import styled from "styled-components"
import i18n from "../i18n";
import * as Icons from "../ui/svg";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {APPSTORE_LINK, HUAWEI_APPGALLERY_LINK, PLAY_MARKET_LINK} from "../utils/constants";

export const getCurrentDevice = () => {
  if (navigator.userAgent.match(/Android/i)) {
    return "android";
  }

  if (navigator.userAgent.match(/iPhone/i)) {
    return "ios";
  }

  return;
}

export const themeKeys = {
  dark: "dark",
  light: "light",
  primary: "primary"
}

const themeMap = {
  dark: {
    color: "#fff",
    background: "#222"
  },
  light: {
    color: "#222",
    background: "#fff"
  },
  primary: {
    color: "#fff",
    background: "#4d5ef6"
  }
}

const handleHuaweiGetAppButtonClick = () => {
  hitEvent(hits.GET_APP_CLICK);
  logEvent(userEvents.GET_APP_CLICK, {type: "landing_button_", is_huawei: true});
};

const appList = [
  {
    device: "ios",
    icon: <Icons.Apple />,
    name: i18n.t("app_button__ios"),
    link: APPSTORE_LINK,
    onClick: () => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"}),
    direction: "row",
  },
  {
    device: "android",
    icon: <Icons.GooglePlay />,
    name: i18n.t("app_button__android"),
    link: PLAY_MARKET_LINK,
    onClick: () => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v2"}),
    direction: "row",
  },
  {
    device: "huawei",
    icon: <Icons.Huawei />,
    name: i18n.t("app_button__huawei"),
    link: HUAWEI_APPGALLERY_LINK,
    onClick: handleHuaweiGetAppButtonClick,
    direction: "vertical",
  }
];

export const AppButtons = ({theme, center, mb}) => {
  const device = getCurrentDevice();
  return <AppLinks center={center} mb={mb}>
    {appList.filter((item) => device ? device === item.device : item).map((item) => (
      <AppLink 
        {...themeMap[theme]}
        theme={theme}
        href={item.link} 
        onClick={item.onClick}
        key={item.link}
        direction={item.direction}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.icon}
        <p>{item.name}</p>
      </AppLink>
    ))}
  </AppLinks>
}

const AppLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 495px;

  ${props => props.center && `
    margin: 0 auto;
  `}

  margin-bottom: ${props => props.mb || 0}px;

  @media all and (max-width: 991px) {
    margin-bottom: ${props => props.mb && 80}px;
  }
`;

const AppLink = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.gap || "16px"};
  height: 56px;
  min-width: 56px;
  padding: ${props => props.padding || "16px 24px"};
  background: ${props => props.background};
  color: ${props => props.color};
  border-radius: 16px;
  transition: 0.3s;

  p {
    color: ${props => props.color};
    font-weight: 700;
    font-size: ${props => props.fontSize || "16px"};
    line-height: 1.5;
    letter-spacing: 0.3px;
    white-space: nowrap;
    text-transform: uppercase;
    transition: 0.3s;
  }

  svg {
    width: 24px;
    flex-shrink: 0;
    transition: 0.3s;

    path {
      stroke: ${props => props.color};
      fill: ${props => props.fill || props.color};
    }
  }

  @media all and (max-width: 991px) {
    height: 48px;
    width: 160px;
    padding: 0 24px;
    border-radius: 10px;
    gap: 8px;

    p {
      font-size: 14px;
    }

    svg {
      width: 16px;
    }
  }
  @media all and (max-width: 560px) {
    width: 100%;
  }

  ${props => props.direction === "vertical" && `
      flex-direction: column;
      gap: 8px;
      padding: 4px;
      
      p {
        font-size: 10px;
      }

      svg path {
        fill: transparent;
      }

      @media all and (max-width: 991px) {
        width: auto;
        padding: 0 4px;

        p {
          font-size: 10px;
        }

        svg {
          width: 16px;
        }
      }
  `}

  @media all and (min-width: 992px) {
    &:hover {
      background: #0e1da6;
    
      ${props => props.theme === "light" && `
        p {
          color: #fff;
        }

        svg path {
          stroke: #fff;
          fill: #fff;
        }
      `}

      ${props => props.direction === "vertical" && `
        svg path {
            fill: transparent;
          }
        }
      `}
    }
  }
`;