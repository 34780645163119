import styled from "styled-components";
import { Link } from "react-router-dom";

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  gap: 16px;

  @media all and (max-width: 991px) {
    margin-bottom: 24px;
    gap: 8px;
  }
`;

export const OriginalPreview = styled.div`
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  will-change: transform;
  width: ${props => props.width}px;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media all and (max-width: 991px) {
    border-radius: 16px;
  }
`;

export const ResultPreview = styled(OriginalPreview)`
  width: ${props => props.width}px;
`;

export const Text = styled.p`
  text-align: center;
  margin-bottom: 16px;

  @media all and (max-width: 991px) {
    display: none;
  }
`;

export const RedirectLink = styled(Link)`
    max-width: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #222;
    min-height: 44px;
    padding: 0 24px;
    margin: 40px auto 160px;
    border-radius: 40px;
    text-transform: uppercase;
    transition: 0.3s;
    background-color: #f6f6f6;

    p {
      font-weight: 600;
      padding: 4px 0;
      text-align: center;
    }

    span {
      text-transform: none;
    }

    svg {
      flex-shrink: 0;
    }

    @media all and (min-width: 992px) {
      &:hover {
        background: #d9d7d7;
      }
    }

    @media all and (max-width: 991px) {
      font-size: 14px;
      margin: 24px auto 80px;

      p {
        font-size: 14px;
        line-height: 1.14;
      }
    }
`;

export const TitlePreview = styled.p`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 40px;
  background-color: #fff;
  padding: 8px 16px;

  @media all and (max-width: 991px) {
    top: 4px;
    left: 4px;
    font-size: 10px;
    line-height: 1.5;
    padding: 2px 6px;
  }
`

export const StatisticBlock = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 16px;

  button, a {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
    border-radius: 40px;
    height: 40px;
    background: #fff;
    font-size: 16px;
    line-height: 1.5;
    transition: color .2s;

    &:first-child {
      cursor: default;
    }

    @media all and (min-width: 992px) {
      &:hover {
        &:last-child {
          color: #4d5ef6;
        }
      }
    }
  }

  @media all and (max-width: 991px) {
    bottom: 8px;
    right: 8px;
    gap: 4px;

    svg {
      height: 8px;
      width: auto;
    }

    button, a {
      font-size: 10px;
      height: 20px;
      padding: 0 6px;
      gap: 4px;
      border-radius: 30px;
    }
  }
`;

export const ComboLink = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  height: 48px;
  margin: 0 auto 24px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background: #4d5ef6;
  border-radius: 12px;

  svg {
    position: absolute;
    right: 16px;
    width: 12px;
    height: 12px;

    path {
      fill: #fff;
    }
  }
`;