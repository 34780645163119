import React from "react";
import { MainHeader } from "../../../components/MainHeader/MainHeader";
import * as RPS from "./RepostsPage.styled"
import i18n from "../../../i18n";
import {AppButtons, themeKeys} from "../../../components/AppButtons";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import photolabSocialApiHelper from "../../../helpers/photolab-social-api.helper";
import { AnimateWrapper } from "../HomePage.styled";
import { LoadMoreButton } from "../../../components/LoadMoreButton/LoadMoreButton";
import routes from "../../../routes";
import {SkeletonHeader} from "../../../components/Skeleton/SkeletonHeader";
import {SkeletonImages} from "../../../components/Skeleton/SkeletonImages";

export default class RepostsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      feed: [],
      feedIsLoading: false,
      combo: null,
    };
  }

  componentDidMount() {
    this.loadUserData();
  }

  loadUserData = async () => {
    const comboId = this.props.match.params.id;

    this.handleLoadFeed();
    const combo = await photolabSocialApiHelper.fetchCombo(comboId);

    this.setState({
      combo,
      isReady: true
    });
  }

  handleLoadFeed = async () => {
    this.setState({
      feedIsLoading: true,
    });

    const comboId = this.props.match.params.id;
    const offsetId = this.state.feed.last()?.id
    try {
      const reposts = await photolabSocialApiHelper.fetchFeedChildren(comboId, offsetId);

      this.setState({
        feed: [...this.state.feed, ...(reposts.feed || [])],
        feedIsLoading: false,
      });
    } catch (e) {
      console.log(e)
      if(!this.state.combo) {
        window.location.href = routes.NOT_FOUND_PAGE;
      }
    }

  }

  render() {
    const {feed, feedIsLoading, combo, isReady} = this.state;

    return <AnimateWrapper className={`${isReady ? "ready" : ""}`}>
     {!isReady
        ? <React.Fragment>
            <SkeletonHeader isShowPreview={1} />
            <SkeletonImages />
          </React.Fragment>
        : <React.Fragment>
            <MainHeader user={combo.user} combo={combo} isShowPreview={1} />
            <ImageGallery combos={feed} page={routes.COMBO_PAGE} isReady={isReady} />
          </React.Fragment>}
            <LoadMoreButton
              animation={feedIsLoading ? 1: 0}
              onClick={this.handleLoadFeed}
              hidden={feed.length === 0 || (combo ? combo.amount_children === feed.length: true)}
            />
            <RPS.Text dangerouslySetInnerHTML={{__html: i18n.t("reposts_page__text")}} />
            <AppButtons theme={themeKeys.dark} center={1} mb={160} />
    </AnimateWrapper>
  }

}