import React from 'react';
import HeaderClassic from "../../components/HeaderClassic";
import HeaderLabs from "../../components/HeaderLabs";
import {SvgSprite} from "../../components/SvgSprite";
import AppContext from "../../contexts/AppContext";
import ResultPage from "../result/ResultPage";
import SimpleTab from "./SimpleTab";
import VectorTab from "./VectorTab";
import LabsTab from "./LabsTab";
import DownloadModal from "./DownloadModal";
import RemoveLogoModal from "./RemoveLogoModal";
import {when} from "../../utils/etc";
import ResultAdSlot from "../../components/ResultAdSlot";
import BodyTab from "./BodyTab";
import Creative from "../../photolab/Creative";

export default class ResultPageV2 extends ResultPage {

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPageV2-DownloadModal"
      site={this.props.site}
      file={this.state.file}
      history={this.props.history}
      tab={this.state.tab}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPageV2-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  renderSimpleTab = (props) => <SimpleTab {...props} />;
  renderVectorTab = (props) => <VectorTab {...props} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
  renderBodyTab = (props) => <BodyTab {...props} onTemplateSelect={this.handleBodyRefreshTemplateSelect} />;
  renderLabsTab = (props) => <LabsTab {...props} onButtonClick={this.handleLabsButtonClick} />;

  renderTemplateName = () => {
    const group = this.state.tab;
    const creative = window.processingManager.processing.getSelectedCreativeInGroup(group);

    if (creative && creative.hasExtra(Creative.EXTRA_TEMPLATE_NAME)) {
      const templateName = creative.getExtra(Creative.EXTRA_TEMPLATE_NAME);

      return <div className="template-name">{templateName}</div>
    }

    return null;
  };

  renderView = ({commonTabSettings, showTabs}) => {
    const Header = when(this.props.site, {
      "classic": HeaderClassic,
      "labs": HeaderLabs,
    });

    return <main
      ref={this.handlePageContainerRefChange}
      className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")} >
      <div className="collage-page-wrapper">
        <Header hidden={window.clientConfig.isWebview} />
        <div className="collage-page-content container">
          <div className="tabs-container">
            {showTabs.map((group) => this.renderTab(group))}
          </div>
          {this.renderTemplateName()}
          {this.renderTabContainer(commonTabSettings)}
        </div>
      </div>
      {window.appConfig.webAds.isEnabled && <div className="banner-footer" align="center"><ResultAdSlot /></div>}
      <SvgSprite className="line-result" viewBox="0 0 1197 734" icon="line-result" />
    </main>;
  };
}

ResultPageV2.contextType = AppContext;
