import axios from "axios";
import {createMd5Token} from "./text";
import {debounce} from "./etc";

const eventsCache = {};
let webviewParamsIsCommited = false;

const userProperties = {
  client_processings: true, // чтобы отличить процессинги на фронте от бека
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.queryParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.queryParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

export const userEvents = {
  // получили событие webiew_tab_selected от нативного приложения
  // параметры:
  // os: android, ios или other
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу результата
  // параметры:
  // photo_id (наш идентификатор фото)
  // is_owner (владелец/реферал)
  // ref (канал шаринга или unspecified)
  PAGE_RESULT: "page_result",

  // клик на кнопке "get the app"
  GET_APP_CLICK: "get_app_click",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  // юзер выбрал фото в вебвью
  // параметры: file_id, file_url
  WEBVIEW_PHOTO_RECEIVED: "webview_photo_received",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // была показана реклама
  // file_id
  PROCESSING_ADS_SHOWN: "processing_ads_shown",

  // успешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченое юзером на ожидание с момент загрузки фото, в миллисекундах)
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченое юзером на ожидание с момент загрузки фото, в миллисекундах)
  PROCESSING_FAILED: "processing_failed",

  // юзер сменил таб на результате
  // параметры:
  // group (группа)
  // prev_group (прошлая группа, с которой переключили)
  TAB_SELECT: "tab_select",

  // параметры:
  // group (название группы, для которой сделан рефреш)
  // template_id (только группа vector, идентификатор шаблона, который юзер выбрал)
  TAB_REFRESH: "tab_refresh",

  // параметры:
  // group (название группы, которой сделан рефреш)
  // photo_id (идентификатор фото)
  // creative_id (идентификатор обработки)
  // template_id (идентификатор шаблона)
  TAB_FAILED: "tab_failed",

  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // template_id (наш идентификатор шаблона)
  // group (группа креативов)
  // default_tab_group (группа креативов выбранная при загрузке страницы)
  // is_refresh (первый шаблон в группе или из под рефреша)
  CREATIVE_VIEW: "creative_view",

  // параметры:
  // file_id (наш идентификатор фото)
  // group (группа креативов)
  // photo_gender (пол)
  // template_id (наш идентификатор шаблона)
  // creative_url (урл креатива)
  BODY_VIEW: "body_view",

  // параметры как и у BODY_VIEW
  // name
  // code
  // message
  // taskRequestId
  // taskInputImageUrl
  BODY_ERROR: "body_error",

  // юзер кликнул на кнопку шаринга
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // is_video (true/false)
  // provider (название поток шаринга)
  // is_refresh (первый шаблон в группе или из под рефреша)
  SHARE: "share",

  // юзер кликнул на кнопку скачать
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // template_id (наш идентификатор шаблона)
  // group (группа креативов)
  // default_tab_group (группа креативов выбранная при загрузке страницы)
  // is_refresh (первый шаблон в группе или из под рефреша)
  DOWNLOAD: "download",

  // инициировано скачивание файла
  // параметры: см. параметры события download
  DOWNLOAD_START: "download_start",

  RATEAPP_DIALOG_SHOW: "rateapp_dialog_show",
  RATEAPP_DIALOG_RATE: "rateapp_dialog_rate",
  RATEAPP_DIALOG_SKIP: "rateapp_dialog_skip",
  CLONES_DIALOG_SHOW: "clones_dialog_show",
  CLONES_DIALOG_ACCEPT: "clones_dialog_accept",
  CLONES_DIALOG_SKIP: "clones_dialog_skip",
  CDN_HOST_WITHOUT_GET_PARAMS: "cdn_host_without_get_params",
  PAGE_FEED_BANNER: "page_feed_banner",
  GO_TO_FEED_CLICK: "go_to_feed_click",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  PROCESSING_TASK_PROCESSING: "processing_task_processing",
  PROCESSING_TASK_PROCESSED: "processing_task_processed",
  PROCESSING_TASK_FAILED: "processing_task_failed",

  HUAWEI_MODAL_SHOWN: "huawei_modal_shown",
  HUAWEI_MODAL_BUTTON_INSTALL: "huawei_modal_button_install",
  HUAWEI_MODAL_BUTTON_CANCEL: "huawei_modal_button_cancel",

  CREATIVE_IMAGE_LOAD_1SEC: "creative_image_load_1sec",
  CREATIVE_IMAGE_LOAD_LONG: "creative_image_load_long",
  CREATIVE_IMAGE_LOAD_ERROR: "creative_image_load_error",
  SAMPLE_IMAGE_LOAD_ERROR: "sample_image_load_error",

  HEAD_SELECT: "head_select",
  JS_GLOBAL_ERROR: "js_global_error",
};

export const hits = {
  WEBVIEW_TAB_FOCUS: 8227,
  GET_APP_CLICK: 8228,
  PAGE_INDEX_WEB_DESKTOP: 8229,
  PAGE_INDEX_WEB_MOBILE: 8230,
  PAGE_OFFICIAL: 8248,
  PAGE_HIRING_JAPAN: 8279,
  PHOTO_SELECT: 8231,
  PHOTO_UPLOADED: 8232,
  PROCESSING_PROCESSED: 8233,
  PROCESSING_PROCESSED_FIRST: 8256,
  PROCESSING_FAILED: 8234,
  PROCESSING_FAILED_PHOTOLAB: 8235,
  PROCESSING_FAILED_ON_RESULT_PAGE: 8241,
  INTERNAL_ERROR_VIEW: 8236,
  RATEAPP_DIALOG_SHOW: 8238,
  RATEAPP_DIALOG_RATE: 8239,
  RATEAPP_DIALOG_SKIP: 8240,
  RATEAPP_DIALOG_INAPP_RESPONSE_0: 8247,
  RATEAPP_DIALOG_INAPP_RESPONSE_1: 8244,
  RATEAPP_DIALOG_INAPP_RESPONSE_2: 8245,
  RATEAPP_DIALOG_INAPP_RESPONSE_3: 8246,
  SHARE_CLICK_SNAPCHAT: 8250,
  SHARE_CLICK_FACEBOOK: 8251,
  SHARE_CLICK_INSTAGRAM: 8252,
  CLONES_DIALOG_SHOW: 8253,
  CLONES_DIALOG_ACCEPT: 8254,
  CLONES_DIALOG_SKIP: 8255,
  ERROR_PAGE_PHOTOLAB_OVERLOAD_MESSAGE: 8243,
  REMOVE_LOGO_CANCEL: 8260,
  REMOVE_LOGO_REMOVE: 8261,
  NATIVE_ADS_SHOW_REQUEST: 8262,
  NATIVE_ADS_SHOWN: 8263,
  HIRING_EMAIL_CLICK: 8280,
  HIRING_SEND_FORM: 8288,
  PAGE_INDEX_SQUARE_VISIT: 8281,
  PAGE_INDEX_CIRCLE_VISIT: 8282,
  PAGE_INDEX_SQUARE_CLICK: 8283,
  PAGE_INDEX_CIRCLE_CLICK: 8284,
  WEBVIEW_NOT_RECOGNIZED: 8295,
  PAGE_FEED_BANNER: 8296,
  GO_TO_FEED_CLICK: 8300,
  DOWNLOAD: 8329,
  PROCESSING_TASK_GREATER_THEN_30_SEC: 8357,
  PROCESSING_TASK_GREATER_THEN_60_SEC: 8358,
  PHOTOLAB_WAIT_ATTEMPTS_15: 8374,
  PHOTOLAB_WAIT_ATTEMPTS_30: 8375,

  API_REQUEST_CREATE_FILE_FAIL: 8359,
  API_REQUEST_CREATE_FILE_ATTEMPT: 8360,
  API_REQUEST_FETCH_FILE_FAIL: 8361,
  API_REQUEST_FETCH_FILE_ATTEMPT: 8362,
  API_REQUEST_PHOTOLAB_SIGN_FAIL: 8363,
  API_REQUEST_PHOTOLAB_SIGN_ALT_FAIL: 9048,
  API_REQUEST_PHOTOLAB_SIGN_ATTEMPT: 8364,
  API_REQUEST_CREATE_TASK_FAIL: 8365,
  API_REQUEST_CREATE_TASK_ATTEMPT: 8366,
  API_REQUEST_FETCH_TASK_FAIL: 8367,
  API_REQUEST_FETCH_TASK_ATTEMPT: 8368,
  API_REQUEST_EVENTS_FAIL: 8369,
  API_REQUEST_EVENTS_ATTEMPT: 8370,

  FRONTEND_WATERMARK_STARTED: 9024,
  FRONTEND_WATERMARK_PROCESSED: 9025,
  FRONTEND_WATERMARK_FAILED: 9026,
  FRONTEND_WATERMARK_FAILED_TIMEOUT: 9043,

  ANALYTICS_ERROR: 9041,
  jsGlobalError: 9606,
};

const httpClient = axios.create({});

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", id, hitName);
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = (hitsCache[id] || 1) * config.increment;
      hitsCache[id] = 0;

      httpClient.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};

  if (window.location.pathname.indexOf("/labs") === 0) {
    eventParams.site = "labs";
  }

  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, JSON.stringify(eventParams, null, 2));
  }

  if (window.appConfig.analytics.isEnabled) {
    const isAllow = !window.appConfig.analytics.overloadModeIsEnabled
      ||
      (window.appConfig.analytics.overloadModeIsEnabled
        && window.appConfig.analytics.overloadModeEvents.includes(eventId))
    ;

    if (isAllow) {
      return _logEvent(eventId, eventParams).then(() => {
        cb && cb();
      });
    }
  }

  return Promise.resolve();
}

function _logEvent(eventId, eventParams) {
  let projectName = window.appConfig.project.name;
  if (window.location.pathname.indexOf("/labs") === 0) {
    projectName = projectName + "_labs";
  }

  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.queryParams;
  }

  const eventData = {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: projectName,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  };

  const eventDataHash = createMd5Token(JSON.stringify(eventData));
  if (eventsCache[eventDataHash] === true) {
    return Promise.resolve();
  }

  return axios.post(window.appConfig.analytics.endpoint, eventData).then(() => {
    eventsCache[eventDataHash] = true;
  }).catch((err) => {
    hitEvent(hits.ANALYTICS_ERROR);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}

export function logCreativeResult(templateId, inputData, outputData, isDownloaded, extra = {}) {
  if (!window.appConfig.resultsCollect.isEnabled) {
    return;
  }

  axios.post(window.appConfig.resultsCollect.endpoint, {
    client_token: window.clientConfig.token,
    project_name: window.appConfig.project.name,
    template_id: templateId,
    input_data: inputData,
    output_data: outputData,
    is_downloaded: isDownloaded,
    extra,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}