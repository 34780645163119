import CreativeConfig from "../CreativeConfig";
import Creative from "../Creative";
import {handlersNames} from "../handlers";
import {pwAssetUrl} from "../../utils/etc";

export function combo(steps, name) {
  steps = steps
    .filter((step) => !!step)
    .map((id) => {
      return Number.isInteger(id)
        ? {id, images: [{useAltBody: true}]}
        : id;
    });

  if (!steps.find((s) => s.setAsFile === "template")) {
    steps.last().setAsFile = "template";
  }

  const comboId = steps.map((step) => step.id).join("_");

  return new CreativeConfig("nogroup", comboId, handlersNames.COMBO)
    .setExtra(Creative.EXTRA_COMBO_STEPS, steps)
    //.setPreviewUrl(pwAssetUrl(`templates-previews/toonme20230426/${name}_${comboId}.jpg`));
    .setPreviewUrl(pwAssetUrl(`previews/256x256/1/${comboId}.jpg`));
}

export function comboWithLayout(steps) {
  const creativeConfig = combo(steps);

  creativeConfig
    .setExtra(Creative.EXTRA_COMBO_STEPS, [
      ...creativeConfig.getExtra(Creative.EXTRA_COMBO_STEPS),
      ...[
        {id: 4021, images: [{src: "@"}]},
        {id: 5510, images: [{src: "#" + (steps.length - 1)}, {src: "#"}], setAsFile: "layout"},
      ]
    ]);

  return creativeConfig;
}

export function whenSplit() {
  const items = (arguments.length > 0 && Array.isArray(arguments[0]))
    ? arguments[0]
    : arguments;

  return [].indexOf.call(items, window.clientConfig.splitGroupId) > -1;
}

export function whenSplitInRange(start, end) {
  return window.clientConfig.splitGroupId >= start
    && window.clientConfig.splitGroupId <= end;
}

export function setCreativesGroup(group, configs) {
  configs.forEach((config) => config.setGroup(group));

  return configs;
}

export function stepWithFallback(id, fallbackId, extra = {}) {
  return {
    ...{id, fallbackId, images: [{useAltBody: true}]},
    ...extra,
  };
}

export function skipOnMultifaceError(id, extra = {}) {
  return {
    ...{id, skipOnMultifaceError: true, images: [{useAltBody: true}]},
    ...extra,
  };
}