import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media all and (max-width: 991px) {
    gap: 8px;
  }
  
  @media (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
    
    >div:last-child {
      display: none;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  @media all and (max-width: 991px) {
    gap: 8px;
  }
`;