import React from "react";
import * as SHS from "./SkeletonHeader.styled";

export const SkeletonHeader = ({isShowPreview, ending}) => {

  return <SHS.Container>
    <SHS.PreviewStyle>
      {isShowPreview && <SHS.PreviewImageLink>
        <div className="line" />
      </SHS.PreviewImageLink>}

      <SHS.PreviewUserInfoContainer>
        {isShowPreview && <div className="reposts_counter">
          <div className="line" />
        </div>}

        <SHS.UserInfo>
          <SHS.TooltipText ending={ending}>
            <div className="line" />
          </SHS.TooltipText>
          <SHS.UserPreview>
            <SHS.UserAvatarContainer>
            </SHS.UserAvatarContainer>
            <SHS.UserProfileItem>
              <p />
            </SHS.UserProfileItem>
            <div className="line" />
          </SHS.UserPreview>
        </SHS.UserInfo>

      </SHS.PreviewUserInfoContainer>
    </SHS.PreviewStyle>
    <SHS.ShareButton>
      <div className="line" />
    </SHS.ShareButton>
  </SHS.Container>
}