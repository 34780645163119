import * as photolabApi from "./api";
import * as api from "../utils/api";
import * as Sentry from "@sentry/react";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import {creativeGroups} from "./config/groups";
import {PhotolabResponseError, photolabTask} from "./api";
import {PhotolabResponseParseError} from "./api";
import {ApiResponseError} from "../utils/api";
import Creative from "./Creative";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {getBodiesListByGroup} from "./config/bodies";
import {
  canvasWatermark,
  CreativeTimeoutError,
  watermarkConfigDefaultWeb,
  watermarkConfigDefaultWebview
} from "./watermark";
import {transformToDownloadUrl} from "../utils/creative";
import {promisifyImage} from "../utils/image";
import {assetUrl} from "../utils/etc";

const FETCH_TASKS_INTERVAL = 1000;
const waitTasksPool = [];
setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);

export const handlersNames = {
  TEMPLATE: "template",
  VECTOR: "vector",
  CARTOON_ANIM: "cartoon_anim",
  BODY: "body",
  COMBO: "combo",
};

export const handlersMap = {
  [handlersNames.TEMPLATE]: templateHandler,
  [handlersNames.VECTOR]: vectorHandler,
  [handlersNames.CARTOON_ANIM]: cartoonAnimHandler,
  [handlersNames.BODY]: bodyHandler,
  [handlersNames.COMBO]: comboHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}

function safePromisify(value) {
  return value ? Promise.resolve(value) : null;
}

function promisifyCreativeTask(creative, taskName) {
  return safePromisify(creative.getTask(taskName));
}

function addPhotolabTaskHelper(creative, taskName, taskConfig, withCache = true) {
  if (withCache) {
    const task = promisifyCreativeTask(creative, taskName);
    if (task != null) {
      return task;
    }
  }

  const templateId = taskConfig.methods[0].params.template_name;

  window.creativesLog.log(creative.id, {
    templateId,
    taskName,
    event: "addtask_start",
  });

  return photolabApi.photolabAddTask(taskConfig)
    .then((taskResult) => {
      taskResult.templateId = templateId;

      window.creativesLog.log(creative.id, {
        templateId,
        taskName,
        event: "addtask_finish",
        requestId: taskResult.requestId,
      });

      creative.setTask(taskName, taskResult);
      return taskResult;
    });
}

function waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval = 2000) {
  if (taskResult.resultUrl) {
    return Promise.resolve(taskResult);
  } else {
    const taskStartedAt = Date.now();
    const taskTimerId30Sec = setTimeout(() => {
      logLongProcessingTask(creative, taskName, taskResult);
      hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
    }, 30000);
    const taskTimerId60Sec = setTimeout(() => {
      hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC)
    }, 60000);

    window.creativesLog.log(creative.id, {
      templateId: taskResult.templateId,
      taskName,
      event: "waittask_start",
      requestId: taskResult.requestId,
      at: taskStartedAt,
    });

    return photolabApi.photolabWaitTask(taskResult.requestId, timeout, interval)
      .then((result) => {
        result.templateId = taskResult.templateId;

        window.creativesLog.log(creative.id, {
          templateId: taskResult.templateId,
          taskName,
          event: "waittask_finish",
          requestId: taskResult.requestId,
          msSinceAddTaskStart: Date.now() - taskResult.addTaskRequestAt,
          msSinceGetResultStart: Date.now() - taskStartedAt,
          getResultRequestsAmount: result.getResultRequestsAmount,
        });

        if (result.resultUrl) {
          result.resultUrl = result.resultUrl.replace("http://", "https://")
        }

        creative.setTask(taskName, result);

        clearTimeout(taskTimerId30Sec);
        clearTimeout(taskTimerId60Sec);
        logProcessedTask(creative, taskName, taskResult, result, taskStartedAt);

        return result;
      })
      .catch((error) => {
        clearTimeout(taskTimerId30Sec);
        clearTimeout(taskTimerId60Sec);
        logFailedTask(creative, taskName, taskResult, error, taskStartedAt);

        throw error;
      });
  }
}

function fetchTasks() {
  const ids = waitTasksPool
    .filter((t) => !t.result || t.result.status === 0)
    .map((t) => t.id);

  if (ids.length === 0) {
    setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);
    return;
  }

  api.fetchTasks(ids)
    .then((tasks) => {
      tasks.forEach((task) => {
        const taskInPool = waitTasksPool.find((t) => t.id === task.id);
        if (taskInPool) {
          taskInPool.result = task;
        }
      });
      setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);
    })
    .catch((err) => {
      console.error(err);
      setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);
    })
}

function waitTaskHelper(creative, taskName, taskResult, timeout = 0, interval = 1000) {
  const taskStartedAt = Date.now();
  const taskTimerId30Sec = setTimeout(() => {
    logLongProcessingTask(creative, taskName, taskResult);
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }, 30000);
  const taskTimerId60Sec = setTimeout(() => {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC)
  }, 60000);

  window.creativesLog.log(creative.id, {
    templateId: taskName,
    taskName,
    event: "waittask_start",
    at: taskStartedAt,
  });

  return waitTask(taskResult.id, timeout, interval)
    .then((result) => {
      creative.setTask(taskName, result);

      window.creativesLog.log(creative.id, {
        templateId: taskName,
        taskName,
        event: "waittask_finish",
        msSinceAddTaskStart: Date.now() - taskResult.createTaskRequestAt,
        msSinceGetResultStart: Date.now() - taskStartedAt,
        getResultRequestsAmount: result.getResultRequestsAmount,
      });

      clearTimeout(taskTimerId30Sec);
      clearTimeout(taskTimerId60Sec);
      logProcessedTask(creative, taskName, taskResult, result, taskStartedAt);

      return result;
    })
    .catch((error) => {
      clearTimeout(taskTimerId30Sec);
      clearTimeout(taskTimerId60Sec);
      logFailedTask(creative, taskName, taskResult, error, taskStartedAt);

      throw error;
    });
}

function defaultHandlerResolver(creative, resolve) {
  return () => {
    window.creativesLog.log(creative.id, {event: "finish"});
    resolve(creative);
  };
}

function defaultHandlerCatch(creative, reject) {
  return (err) => {
    console.error(err, err.parentError);

    if (!(err instanceof PhotolabResponseError || err instanceof HandlerCancelError)) {
      Sentry.captureException(err);
    }

    const type = "internal";
    const errorState = {
      type,
      name: err.name,
      code: err.code,
      message: err.message,
    };

    if (err instanceof PhotolabResponseError || err instanceof PhotolabResponseParseError) {
      errorState.type = "photolab";
    } else if (err instanceof ApiResponseError) {
      errorState.type = "api";
    } else if (err instanceof ApiTaskError) {
      errorState.type = "api_task";
    } else if (err instanceof HandlerCancelError) {
      if (err.parentError) {
        errorState.type = err.parentError.type;
        errorState.name = err.parentError.name;
        errorState.code = err.parentError.code;
        errorState.message = err.parentError.message;
      } else {
        errorState.type = "handler_cancel";
      }
    }

    creative.markAsFailed(errorState);

    reject(creative);
  };
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

class HandlerCancelError extends Error {
  constructor(message, parentError) {
    super();
    this.name = "HandlerCancelError";
    this.code = -1;
    this.message = message;
    this.parentError = parentError;
  }
}

function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  requestsAmount++;

  if (waitTasksPool.findIndex((t) => t.id === taskId) === -1) {
    waitTasksPool.push({id: taskId});
  }

  function _call(resolve, reject) {
    const task = waitTasksPool.find((t) => t.id === taskId);
    let keepWait = true;

    if (task && task.result) {
      task.result.getResultRequestsAmount = requestsAmount;

      if (task.result.status === 1) {
        keepWait = false;
        resolve(task.result);
      } else if (task.result.status === -1) {
        keepWait = false;
        reject(new ApiTaskError(task.result));
      }
    }

    if (keepWait) {
      setTimeout(() => {
        waitTask(taskId, 0, interval).then(resolve).catch(reject);
      }, interval || 1000);
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => _call(resolve, reject), timeout);
  });
}

/**
 * @param {Processing} processing
 * @param {string} group
 * @param {boolean} throwOnFailed
 * @returns {Promise<Creative>}
 */
function waitCrop(
  processing,
  group = creativeGroups.CROP,
  throwOnFailed = true
) {
  return waitCreative(processing.getSelectedCreativeInGroup(group))
    .then((creative) => {
      if (throwOnFailed && creative.isFailed) {
        throw new HandlerCancelError("The crop creative is failed", creative.error)
      }

      return creative;
    });
}

/**
 * @param {Processing} processing
 * @param {boolean} throwOnFailed
 * @returns {Promise<Creative>}
 */
function waitGender(processing, throwOnFailed = true) {
  return waitCreative(processing.getSelectedCreativeInGroup(creativeGroups.GENDER))
    .then((creative) => {
      if (throwOnFailed && creative.isFailed) {
        throw new HandlerCancelError("The crop creative is failed", creative.error)
      }

      return creative;
    });
}

/**
 * @param {Creative} creative
 * @returns {Promise<Creative>}
 */
function waitCreative(creative) {
  return new Promise((resolve) => {
    if (creative.isFinished) {
      resolve(creative);
    } else {
      setTimeout(() => {
        waitCreative(creative).then(resolve);
      }, 250);
    }
  });
}

function logLongProcessingTask(creative, taskName, taskResult) {
  if (window.appConfig.analytics.tasksIsEnabled) {
    logEvent(userEvents.PROCESSING_TASK_PROCESSING, {
      group: creative.group,
      template_id: creative.templateId,
      source_file_id: creative.fileId,
      task_name: taskName,
      task_request_id: taskResult.requestId || taskResult.id || "",
    });
  }
}

function logProcessedTask(creative, taskName, addTaskResult, taskResult, taskStartedAt) {
  const processingTime = Date.now() - taskStartedAt;

  if (window.appConfig.analytics.tasksIsEnabled) {
    logEvent(userEvents.PROCESSING_TASK_PROCESSED, {
      group: creative.group,
      template_id: creative.templateId,
      creative_id: creative.id,
      task_name: taskName,
      task_template_name: addTaskResult.templateName,
      processing_time: processingTime,
      source_file_id: creative.fileId,
      task_start_at: taskStartedAt,
      task_finish_at: Date.now(),
      task_request_id: addTaskResult.requestId || addTaskResult.id || "",
      task_enqueue_at: addTaskResult.addTaskRequestAt,
      task_enqueued_at: addTaskResult.addTaskResponseAt,
      task_sign_request_time: addTaskResult.signRequestTime,
      task_sign_addtask_time: addTaskResult.signAddTaskRequestTime,
      task_get_result_requests: taskResult.getResultRequestsAmount,
      task_total_duration: taskResult.totalDuration || "",
      task_result_url: taskResult.resultUrl || "",
    });
  }

  if (processingTime >= 60000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC);
  } else if (processingTime >= 30000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }
}

function logFailedTask(creative, taskName, addTaskResult, taskResult, taskStartedAt) {
  const processingTime = Date.now() - taskStartedAt;

  if (window.appConfig.analytics.tasksIsEnabled) {
    logEvent(userEvents.PROCESSING_TASK_FAILED, {
      group: creative.group,
      template_id: creative.templateId,
      creative_id: creative.id,
      task_name: taskName,
      task_template_name: addTaskResult.templateName,
      processing_time: processingTime,
      source_file_id: creative.fileId,
      task_start_at: taskStartedAt,
      task_finish_at: Date.now(),
      task_request_id: addTaskResult.requestId || addTaskResult.id || "",
      task_enqueue_at: addTaskResult.addTaskRequestAt,
      task_enqueued_at: addTaskResult.addTaskResponseAt,
      task_sign_request_time: addTaskResult.signRequestTime,
      task_sign_addtask_time: addTaskResult.signAddTaskRequestTime,
      task_get_result_requests: taskResult.getResultRequestsAmount,
      task_error_code: taskResult.code || 0,
    });
  }

  if (processingTime >= 60000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC);
  } else if (processingTime >= 30000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }
}

export function drawWatermark(sourceImageUrl) {
  hitEvent(hits.FRONTEND_WATERMARK_STARTED);

  const timeoutPromise = new Promise((resolve, reject) => {
    let timeoutTimer = setTimeout(() => {
      clearTimeout(timeoutTimer);
      reject(new CreativeTimeoutError());
    }, 5000);
  });

  const imageUrl = transformToDownloadUrl(sourceImageUrl);
  const watermarkConfig = window.clientConfig.isWeb ? watermarkConfigDefaultWeb : watermarkConfigDefaultWebview;

  return Promise.race([
    timeoutPromise,
    Promise.all([
      promisifyImage(imageUrl, true),
      promisifyImage(watermarkConfig.url, true),
    ])
  ])
  .then(([sourceImage, watermarkImage]) => {
    const canvas = canvasWatermark(sourceImage, watermarkImage, watermarkConfig);

    return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
  })
  .then((blob) => api.tempImagesUploadFile(blob, "jpeg"))
  .then((fileUrl) => {
    hitEvent(hits.FRONTEND_WATERMARK_PROCESSED);

    return transformToDownloadUrl(fileUrl);
  })
  .catch((error) => {
    hitEvent(hits.FRONTEND_WATERMARK_FAILED);

    if (error instanceof CreativeTimeoutError) {
      hitEvent(hits.FRONTEND_WATERMARK_FAILED_TIMEOUT);
    }

    throw error;
  });
}

function simplePhotolabTask(processing, creative, imageUrl, templateId, taskName, timeout = 1000) {
  const taskConfig = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(templateId))
    .addImage(new PhotolabTaskImageUrl(imageUrl))
    .buildToJs();

  return addPhotolabTaskHelper(creative, taskName, taskConfig)
    .then((taskResult) => waitPhotolabTaskHelper(creative, taskName, taskResult, timeout))
    .then((taskResult) => {
      creative.setFile(taskName, taskResult.resultUrl);
      return taskResult;
    });
}

// -----------------------------------------------------------------------------

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function templateHandler(processing, creative) {
  function createTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .buildToJs();
  }

  return new Promise((resolve, reject) => {
    addPhotolabTaskHelper(creative, "template", createTask())
      .then((taskResult) => waitPhotolabTaskHelper(creative, "template", taskResult, 0, 250))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.resultUrl);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function vectorHandler(processing, creative) {
  function createTemplateTask() {
    const cropCreative = processing.getSelectedCreativeInGroup(creativeGroups.CROP);

    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(cropCreative.resultUrl))
      .buildToJs();
  }

  function createBackgroundTask(imageUrl) {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(4081))
      .addImage(new PhotolabTaskImageUrl(imageUrl))
      .buildToJs();
  }

  return new Promise((resolve, reject) => {
    waitCrop(processing)
      .then(() => addPhotolabTaskHelper(creative, "template", createTemplateTask()))
      .then((taskResult) => waitPhotolabTaskHelper(creative, "template", taskResult, 5000))
      .then((taskResult) => addPhotolabTaskHelper(creative, "background", createBackgroundTask(taskResult.resultUrl)))
      .then((taskResult) => waitPhotolabTaskHelper(creative, "background", taskResult, 3000))
      .then((taskResult) => {
        creative.setFile("result", taskResult.resultUrl);
        creative.markAsProcessed(taskResult.resultUrl);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject))
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function cartoonAnimHandler(processing, creative) {
  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .buildToJs();
  }

  return new Promise((resolve, reject) => {
    addPhotolabTaskHelper(creative, "template", createTemplateTask())
      .then((taskResult) => waitPhotolabTaskHelper(creative, "template", taskResult, 5000))
      .then((taskResult) => {
        creative.setFile("template", taskResult.resultUrl);

        const watermarkConfig = window.clientConfig.isWeb
          ? watermarkConfigDefaultWeb
          : watermarkConfigDefaultWebview;

        return api.createTask(creative, "store", "pw_watermark_video", {
          video: {
            url: taskResult.resultUrl,
          },
          watermark: {
            url: assetUrl(watermarkConfig.url),
            size: watermarkConfig.percentage / 100,
            edge: watermarkConfig.position,
            x: watermarkConfig.x / 100,
            y: watermarkConfig.y / 100,
            is_relative: true,
          },
        });
      })
      .then((taskResult) => waitTaskHelper(creative, "store", taskResult, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.result.url);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function bodyHandler(processing, creative) {
  return new Promise((resolve, reject) => {
    waitGender(processing)
      .then(() => {
        let body;

        if (creative.hasExtra(Creative.EXTRA_BODY_TEMPLATE_ID)) {
          const bid = parseInt(creative.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID, 0));
          body = getBodiesListByGroup(creative.group).find((b) => b.id === bid);
        } else {
          const gender = processing.getGender();
          body = getBodiesListByGroup(creative.group)
            .shuffle()
            .find((b) => {
              return b.gender === gender && !b.isHidden;
            });
        }

        creative.setTemplateId(body.id);
        creative.setExtra(Creative.EXTRA_BODY_TEMPLATE_ID, body.id);
        creative.setExtra(Creative.EXTRA_HEAD_TEMPLATE_ID, body.head);

        return simplePhotolabTask(processing, creative, processing.file.url, body.head, "head")
          .then((taskResult) => {
            return simplePhotolabTask(processing, creative, taskResult.resultUrl, body.id, "body");
          });
      })
      .then((taskResult) => {
        creative.setFile("result", taskResult.resultUrl);
        creative.markAsProcessed(taskResult.resultUrl);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
const comboTasksCache = {};
function comboHandler(processing, creative) {
  const steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS);

  function getResultUrlFromTask(task) {
    if (task.requestId) {
      return task.resultUrl
    } else {
      return task.result.url;
    }
  }

  function templateStep(processing, creative, stepIndex, stepConfig) {
    if (!stepConfig.id) {
      return Promise.reject("No template ID parameter.");
    }

    const templateParams = stepConfig.templateParams || {};

    if (templateParams.gender && templateParams.gender[0] === "#") {
      const genderTask = creative.getTask("s" + templateParams.gender.substring(1));
      if (genderTask) {
        templateParams.gender = genderTask.gender === "male" ? "man" : "woman";
      } else {
        delete templateParams.gender;
      }
    }

    const taskConfigBuilder = new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(stepConfig.id, templateParams));

    // @ - original photo
    // # - prev step result
    // #N - N step result, todo
    (stepConfig.images || [{src: "#"}]).forEach((image) => {
      const source = image.src || "#";
      let imageUrl;

      if (source === "@") {
        imageUrl = processing.file.url;
      } else if (source === "#") {
        if (stepIndex === 0) {
          imageUrl = processing.file.url;
        } else {
          imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));
        }
      } else if (/^#\d+$/.test(source)) {
        imageUrl = getResultUrlFromTask(creative.getTask("s" + source.substring(1)));
      } else {
        imageUrl = source;
      }

      const altBody = (image.useAltBody && processing.maskFile) ? processing.maskFile.url : "";
      const fileName = altBody.split("/").pop();
      const hash = fileName.substring(0, fileName.lastIndexOf("."));

      taskConfigBuilder.addImage(new PhotolabTaskImageUrl(
        imageUrl + (hash.length ? "?" + hash : ""),
        "",
        0,
        0,
        altBody
      ));
    });

    const taskConfig = taskConfigBuilder.buildToJs()
    const taskCacheKey = JSON.stringify(taskConfig);

    if (!comboTasksCache[taskCacheKey]) {
      comboTasksCache[taskCacheKey] = photolabTask(
        taskConfig,
        stepConfig.getResultTimeout || 1000,
        stepConfig.getResultInterval || 1000,
      )
    }

    return comboTasksCache[taskCacheKey].then((taskResult) => {
      creative.setTask("s" + stepIndex, taskResult);

      if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
        creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
      }

      return taskResult;
    }).catch((err) => {
      if (err.name === "PhotolabResponseError" && err.code === -1028) {
        if (stepConfig.skipOnMultifaceError) {
          const imageUrl = stepIndex === 0
            ? processing.file.url
            : getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));

          const result = {
            skipped: "skipOnMultifaceError",
            result: {url: imageUrl}
          };

          creative.setTask("s" + stepIndex, result);
          return result;
        }

        if (stepConfig.fallbackId) {
          const newStepConfig = JSON.parse(JSON.stringify(stepConfig));
          newStepConfig.id = newStepConfig.fallbackId;
          delete newStepConfig.fallbackId;
          return templateStep(processing, creative, stepIndex, newStepConfig);
        }
      }

      delete comboTasksCache[taskCacheKey];
      throw err;
    });
  }

  function waitChain(index) {
    return new Promise((resolve, reject) => {
      const step = steps[index];
      let stepHandler = null;

      switch (step.type || "template") {
        case "template": {
          stepHandler = templateStep(processing, creative, index, step);
          break;
        }
        default: {
          throw new Error(`Unrecognized combo step: '${step.type}'.`);
        }
      }

      return stepHandler
        .then((res) => steps[index + 1] ? waitChain(index + 1) : res)
        .then(resolve)
        .catch(reject);
    });
  }

  return new Promise((resolve, reject) => {
    waitChain(0)
      .then(() => {
        const file = creative.getFile("layout") || creative.getFile("template");

        creative.setFile("result", file);
        creative.markAsProcessed(file);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}